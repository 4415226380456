import React, { createContext, useReducer } from 'react';
import { settingsReducer } from './settingsReducer';
import { Region, Venue } from '../API';

export interface Filters {
  artistsIDs: string[];
  venuesIDs: string[];
  regionsIDs: string[];
  typesIDs: string[];
}

export interface Period {
  from: Date;
  to: Date;
  period: 'day' | 'week' | 'month' | 'work_week' | 'agenda';
}

export interface SettingsState {
  filters: Filters;
  selectedPeriod: Period;
  listRegions: Region[];
  selectedVenue?: Venue;
}

export const settingsInitialState: SettingsState = {
  filters: {
    artistsIDs: [],
    venuesIDs: [],
    regionsIDs: [],
    typesIDs: [],
  },
  selectedPeriod: { from: new Date(), to: new Date(), period: 'day' },
  listRegions: [],
};

export interface SettingsContextProps {
  settingsState: SettingsState;
  onFiltersUpdate: (filters: Filters) => void;
  onUpdatePeriod: (period: Period) => void;
  onRegionsUpdate: (items: Region[]) => void;
  onSelectVenue: (venue?: Venue) => void;
}

export const SettingsContext = createContext({} as SettingsContextProps);
SettingsContext.displayName = 'SettingsContext';

export const SettingsProvider = ({ children }: any) => {
  const [settingsState, dispatch] = useReducer(settingsReducer, settingsInitialState);

  const onFiltersUpdate = (filters: Filters) => {
    dispatch({ type: 'UPDATE_FILTERS', payload: filters });
  };

  const onUpdatePeriod = (period: Period) => {
    dispatch({ type: 'UPDATE_PERIOD', payload: period });
  };

  const onRegionsUpdate = (items: Region[]) => {
    dispatch({ type: 'UPDATE_REGIONS', payload: items });
  };

  const onSelectVenue = (venue?: Venue) => {
    dispatch({ type: 'SELECT_VENUE', payload: venue });
  };

  return (
    <SettingsContext.Provider value={{ settingsState, onFiltersUpdate, onRegionsUpdate, onUpdatePeriod, onSelectVenue }}>{children}</SettingsContext.Provider>
  );
};
