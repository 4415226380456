import { MenuIcons } from '@venuedriver/ui-driver';
import { useRouter } from 'next/router';
import { useAuthenticate } from '../../../hooks/contextHooks/useAuthenticate';

export const useMenuItems = () => {
  const {
    permissionsChecks: {
      hasAccessToBookings,
      hasAccessToEvents,
      hasAccessToTickets,
      hasAccessToRolesManagement,
      hasAccessToTheVenueSettings
    }
  } = useAuthenticate();
  const router = useRouter();

  let menuItems = [
    {
      text: 'Home',
      tabIndex: 1,
      path: {
        pathname: '/home',
        handleClick: () => {
          router.push('/home');
        },
      },
      id: 'home',
      icon: MenuIcons.HomeMenuIcon(),
    },
  ];

  menuItems.push({
    text: 'Checkin',
    tabIndex: menuItems.length + 1,
    path: {
      pathname: '/checkin',
      handleClick: () => {
        router.push('/checkin');
      },
    },
    id: 'checkin',
    icon: MenuIcons.CheckinMenuIcon(),
  }, {
    text: "Orders",
    tabIndex: menuItems.length + 2,
    path: {
      pathname: "/orders",
      handleClick: () => {
        router.push("/orders");
      },
    },
    id: 'orders',
    icon: MenuIcons.OrdersMenuIcon(),
  });

  if (hasAccessToEvents()) {
    menuItems.push({
      text: 'Events',
      tabIndex: menuItems.length + 1,
      path: {
        pathname: '/events',
        handleClick: () => {
          router.push('/events');
        },
      },
      id: 'events',
      icon: MenuIcons.EventsMenuIcon(),
    });
  }

  if (hasAccessToTickets()) {
    menuItems.push({
      text: 'Tickets',
      tabIndex: menuItems.length + 1,
      path: {
        pathname: '/tickets',
        handleClick: () => {
          router.push('/tickets');
        },
      },
      id: 'tickets',
      icon: MenuIcons.TicketsMenuIcon(),
    });
  }

  if (hasAccessToBookings()) {
    menuItems.push({
      text: 'Bookings',
      tabIndex: menuItems.length + 1,
      path: {
        pathname: '/bookings',
        handleClick: () => {
          router.push('/bookings');
        },
      },
      id: 'bookings',
      icon: MenuIcons.BookingsMenuIcon(),
    });
  }

  if (hasAccessToRolesManagement()) {
    menuItems.push({
      text: 'Roles',
      tabIndex: menuItems.length + 1,
      path: {
        pathname: '/rolesManagement',
        handleClick: () => {
          router.push('/rolesManagement');
        },
      },
      id: 'roles-management',
      icon: MenuIcons.SettingsMenuIcon(),
    });
  }

  if (hasAccessToTheVenueSettings()) {
    menuItems.push({
      text: 'Venues',
      tabIndex: menuItems.length + 1,
      path: {
        pathname: '/venuesSettings',
        handleClick: () => {
          router.push('/venuesSettings');
        },
      },
      id: 'venues-settings',
      icon: MenuIcons.VenuesMenuIcon(),
    });
  }

  menuItems.push({
    text: "Sales",
    tabIndex: menuItems.length + 1,
    path: {
      pathname: "/ticket-sales",
      handleClick: () => {
        router.push("/ticket-sales");
      },
    },
    id: 'sales-search',
    icon: MenuIcons.SalesSearchMenuIcon(),
  },
  {
    text: 'Regions',
    tabIndex: menuItems.length + 1,
    path: {
      pathname: '/settings/regions',
      handleClick: () => {
        router.push('/settings/regions');
      }
    },
    id: 'regions',
    icon: MenuIcons.RegionsIcon(),
  }
  );


  const supportItems = [
    {
      text: "Support",
      tabIndex: menuItems.length + 1,
      path: {
        pathname: "/support",
        handleClick: () => {
          router.push("/support");
        },
      },
      id: 'support',
      icon: MenuIcons.SupportMenuIcon(),
    },
    {
      text: "Settings",
      tabIndex: menuItems.length + 2,
      path: {
        pathname: "/settings",
        handleClick: () => {
          router.push("/settings");
        },
      },
      id: 'settings',
      icon: MenuIcons.SettingsMenuIcon(),
    },
  ];

  return {
    menuItems,
    supportItems,
  };
};