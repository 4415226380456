import { showNotification } from '@mantine/notifications';

const defaultMessage = 'Something went wrong. Please try again later.';

export const mapErrorsToShowRequestFailedToast = (errors: any) => {
  errors.map((error: any) => parseEndShowRequestFailedToast(error));
};

export const parseEndShowRequestFailedToast = (error: any) => {
  const message = error.message ? error.message : defaultMessage;
  showRequestFailedToast(message);
};

export const showSuccessfulRequestToast = (message: string) => {
  showNotification({
    loading: false,
    color: 'green',
    title: 'Successful',
    message: message,
    autoClose: 5000,
    disallowClose: false,
  });
};

export const showRequestFailedRequestToast = (model: string) => {
  showNotification({
    loading: false,
    color: 'red',
    title: 'Request is failed',
    message: `Something went wrong in ${model} get all request`,
    autoClose: 10000,
    disallowClose: false,
  });
};

export const showRequestEmptyDataInResponseToast = (model: string) => {
  showNotification({
    loading: false,
    color: 'yellow',
    title: 'No data received',
    message: `The data is empty or undefined in ${model} "get all" request`,
    autoClose: 10000,
    disallowClose: false,
  });
};

export const showRequestFailedToast = (message: string) => {
  showNotification({
    loading: false,
    color: 'red',
    title: 'Request is failed',
    message: message,
    autoClose: 10000,
    disallowClose: false,
  });
};

export const showCustomErrorToast = (title: string, message: string) => {
  showNotification({
    loading: false,
    color: 'red',
    title: title,
    message: message,
    autoClose: 10000,
    disallowClose: false,
  });
};
