import {Filters, Period, SettingsState} from './SettingsContext';
import { Region, Venue } from '../API';

type SettingsAction =
  | {type: 'UPDATE_FILTERS', payload: Filters}
  | {type: 'UPDATE_PERIOD', payload: Period}
  | {type: 'UPDATE_REGIONS', payload: Region[]}
  | {type: 'SELECT_VENUE', payload?: Venue}

export const settingsReducer = (state: SettingsState, action: SettingsAction): SettingsState => {

  switch (action.type) {
    case 'UPDATE_FILTERS':
      return {
        ...state,
        filters: { ...action.payload }
      };
    case 'UPDATE_PERIOD':
      return {
        ...state,
        selectedPeriod: { ...action.payload }
      };
    case 'UPDATE_REGIONS':
      return {
        ...state,
        listRegions: [ ...action.payload ]
      };
    case 'SELECT_VENUE':
      return {
        ...state,
        selectedVenue: action.payload
      };

    default:
      return state;
  }
};

