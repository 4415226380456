import React from 'react';
import { HeaderProvider } from "@venuedriver/ui-driver";

import AdminTemplate from './AdminTemplate/AdminTemplate';

import { useAuthenticate } from '../../hooks/contextHooks/useAuthenticate';
import { useAuth } from '../../hooks/contextHooks/useAuth';
import { CheckinTemplate } from './CheckinTemplate/CheckinTemplate';

export interface TicketDriverProps {
  children: React.ReactNode,
}

export const TicketDriver = ({ children }: TicketDriverProps) => {
  const { authState: { isAuthenticated }, permissionsChecks: { isStaff, isAdmin } } = useAuthenticate();
  const { isLoading, user } = useAuth();

  if (isAuthenticated && isAdmin() && !isLoading) {
    return (
      <HeaderProvider>
        <AdminTemplate user={user}>
          {children}
        </AdminTemplate>
      </HeaderProvider>
    );
  }
  if (isAuthenticated && isStaff() && !isLoading) {
    return (
      <HeaderProvider>
        <CheckinTemplate>
          {children}
        </CheckinTemplate>
      </HeaderProvider>
    );
  }

  return (
    <>{children}</>
  )
}