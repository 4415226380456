import { Auth } from 'aws-amplify';

type Variables = {
  input?: any;
  filter?: any;
  limit?: number;
};

export const getFormattedGraphqlRequest = async (query: string, variables?: Variables) => {
  const authToken = await getUserJWTToken();
  const authMode: 'AWS_LAMBDA' | 'API_KEY' | 'AWS_IAM' | 'OPENID_CONNECT' | 'AMAZON_COGNITO_USER_POOLS' | undefined =
    'API_KEY';
  return { query, authMode, variables, authToken: `Pref ${authToken}` };
};

const getUserJWTToken = async () => {
  const session = await Auth.currentSession();
  return session.getAccessToken().getJwtToken();
};
