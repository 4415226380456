/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCurrentUser = /* GraphQL */ `
  query GetCurrentUser {
    getCurrentUser {
      id
      cognitoID
      name
      email
      active
      settings
      roleGrants {
        items {
          id
          userID
          roleID
          eventsGroups
          venues
          regions
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ticketSaleRedemptions {
        items {
          id
          ticketID
          ticketSaleID
          eventID
          venueDriverTicketSaleRedemptionID
          venueDriverTicketID
          venueDriverTicketSaleID
          venueDriverEventID
          void
          redeemedAt
          sourceSystem
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userTicketSaleRedemptionsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const getBookingFee = /* GraphQL */ `
  query GetBookingFee($id: ID!) {
    getBookingFee(id: $id) {
      id
      guaranteedFee
      bonusFee
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listBookingFees = /* GraphQL */ `
  query ListBookingFees(
    $filter: ModelBookingFeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBookingFees(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        guaranteedFee
        bonusFee
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncBookingFees = /* GraphQL */ `
  query SyncBookingFees(
    $filter: ModelBookingFeeFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBookingFees(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        guaranteedFee
        bonusFee
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getBookingBudget = /* GraphQL */ `
  query GetBookingBudget($id: ID!) {
    getBookingBudget(id: $id) {
      id
      venueID
      calendarMonth
      cost
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listBookingBudgets = /* GraphQL */ `
  query ListBookingBudgets(
    $filter: ModelBookingBudgetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBookingBudgets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        venueID
        calendarMonth
        cost
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncBookingBudgets = /* GraphQL */ `
  query SyncBookingBudgets(
    $filter: ModelBookingBudgetFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBookingBudgets(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        venueID
        calendarMonth
        cost
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getArtistRate = /* GraphQL */ `
  query GetArtistRate($id: ID!) {
    getArtistRate(id: $id) {
      id
      artistID
      name
      fee
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listArtistRates = /* GraphQL */ `
  query ListArtistRates(
    $filter: ModelArtistRateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listArtistRates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        artistID
        name
        fee
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncArtistRates = /* GraphQL */ `
  query SyncArtistRates(
    $filter: ModelArtistRateFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncArtistRates(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        artistID
        name
        fee
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getArtistTier = /* GraphQL */ `
  query GetArtistTier($id: ID!) {
    getArtistTier(id: $id) {
      id
      name
      minimumFee
      venueID
      Dates {
        items {
          id
          venueID
          calendarDate
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          artistTierDatesId
          dateTypeOptionDatesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listArtistTiers = /* GraphQL */ `
  query ListArtistTiers(
    $filter: ModelArtistTierFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listArtistTiers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        minimumFee
        venueID
        Dates {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncArtistTiers = /* GraphQL */ `
  query SyncArtistTiers(
    $filter: ModelArtistTierFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncArtistTiers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        minimumFee
        venueID
        Dates {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getBookingStatusOption = /* GraphQL */ `
  query GetBookingStatusOption($id: ID!) {
    getBookingStatusOption(id: $id) {
      id
      name
      default
      Booking {
        items {
          id
          dateID
          artistID
          eventID
          startTime
          endTime
          public
          announcementDate
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          bookingStatusOptionBookingId
          bookingBookingFeeId
          bookingAreaId
          bookingArtistTierId
          bookingArtistRateId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listBookingStatusOptions = /* GraphQL */ `
  query ListBookingStatusOptions(
    $filter: ModelBookingStatusOptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBookingStatusOptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        default
        Booking {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncBookingStatusOptions = /* GraphQL */ `
  query SyncBookingStatusOptions(
    $filter: ModelBookingStatusOptionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBookingStatusOptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        default
        Booking {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getBooking = /* GraphQL */ `
  query GetBooking($id: ID!) {
    getBooking(id: $id) {
      id
      dateID
      artistID
      eventID
      startTime
      endTime
      public
      announcementDate
      notes
      BookingFee {
        id
        guaranteedFee
        bonusFee
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Area {
        id
        venueID
        name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      ArtistTier {
        id
        name
        minimumFee
        venueID
        Dates {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      ArtistRate {
        id
        artistID
        name
        fee
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      status {
        id
        name
        default
        Booking {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      bookingStatusOptionBookingId
      bookingBookingFeeId
      bookingAreaId
      bookingArtistTierId
      bookingArtistRateId
      __typename
    }
  }
`;
export const listBookings = /* GraphQL */ `
  query ListBookings(
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBookings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        dateID
        artistID
        eventID
        startTime
        endTime
        public
        announcementDate
        notes
        BookingFee {
          id
          guaranteedFee
          bonusFee
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Area {
          id
          venueID
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        ArtistTier {
          id
          name
          minimumFee
          venueID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        ArtistRate {
          id
          artistID
          name
          fee
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        status {
          id
          name
          default
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        bookingStatusOptionBookingId
        bookingBookingFeeId
        bookingAreaId
        bookingArtistTierId
        bookingArtistRateId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncBookings = /* GraphQL */ `
  query SyncBookings(
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBookings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        dateID
        artistID
        eventID
        startTime
        endTime
        public
        announcementDate
        notes
        BookingFee {
          id
          guaranteedFee
          bonusFee
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Area {
          id
          venueID
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        ArtistTier {
          id
          name
          minimumFee
          venueID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        ArtistRate {
          id
          artistID
          name
          fee
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        status {
          id
          name
          default
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        bookingStatusOptionBookingId
        bookingBookingFeeId
        bookingAreaId
        bookingArtistTierId
        bookingArtistRateId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDateTypeOption = /* GraphQL */ `
  query GetDateTypeOption($id: ID!) {
    getDateTypeOption(id: $id) {
      id
      name
      default
      Dates {
        items {
          id
          venueID
          calendarDate
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          artistTierDatesId
          dateTypeOptionDatesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listDateTypeOptions = /* GraphQL */ `
  query ListDateTypeOptions(
    $filter: ModelDateTypeOptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDateTypeOptions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        default
        Dates {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDateTypeOptions = /* GraphQL */ `
  query SyncDateTypeOptions(
    $filter: ModelDateTypeOptionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDateTypeOptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        default
        Dates {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDate = /* GraphQL */ `
  query GetDate($id: ID!) {
    getDate(id: $id) {
      id
      venueID
      calendarDate
      notes
      Bookings {
        items {
          id
          dateID
          artistID
          eventID
          startTime
          endTime
          public
          announcementDate
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          bookingStatusOptionBookingId
          bookingBookingFeeId
          bookingAreaId
          bookingArtistTierId
          bookingArtistRateId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      DateTypeOption {
        id
        name
        default
        Dates {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      artistTier {
        id
        name
        minimumFee
        venueID
        Dates {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      artistTierDatesId
      dateTypeOptionDatesId
      __typename
    }
  }
`;
export const listDates = /* GraphQL */ `
  query ListDates(
    $filter: ModelDateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        venueID
        calendarDate
        notes
        Bookings {
          nextToken
          startedAt
          __typename
        }
        DateTypeOption {
          id
          name
          default
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        artistTier {
          id
          name
          minimumFee
          venueID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        artistTierDatesId
        dateTypeOptionDatesId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDates = /* GraphQL */ `
  query SyncDates(
    $filter: ModelDateFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDates(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        venueID
        calendarDate
        notes
        Bookings {
          nextToken
          startedAt
          __typename
        }
        DateTypeOption {
          id
          name
          default
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        artistTier {
          id
          name
          minimumFee
          venueID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        artistTierDatesId
        dateTypeOptionDatesId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getArtist = /* GraphQL */ `
  query GetArtist($id: ID!) {
    getArtist(id: $id) {
      id
      venueDriverArtistID
      name
      friendlyID
      Bookings {
        items {
          id
          dateID
          artistID
          eventID
          startTime
          endTime
          public
          announcementDate
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          bookingStatusOptionBookingId
          bookingBookingFeeId
          bookingAreaId
          bookingArtistTierId
          bookingArtistRateId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ArtistRates {
        items {
          id
          artistID
          name
          fee
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      eventVenueDriverSupportingArtistsId
      __typename
    }
  }
`;
export const listArtists = /* GraphQL */ `
  query ListArtists(
    $filter: ModelArtistFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listArtists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        venueDriverArtistID
        name
        friendlyID
        Bookings {
          nextToken
          startedAt
          __typename
        }
        ArtistRates {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        eventVenueDriverSupportingArtistsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncArtists = /* GraphQL */ `
  query SyncArtists(
    $filter: ModelArtistFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncArtists(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        venueDriverArtistID
        name
        friendlyID
        Bookings {
          nextToken
          startedAt
          __typename
        }
        ArtistRates {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        eventVenueDriverSupportingArtistsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const searchArtists = /* GraphQL */ `
  query SearchArtists(
    $filter: SearchableArtistFilterInput
    $sort: [SearchableArtistSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableArtistAggregationInput]
  ) {
    searchArtists(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        venueDriverArtistID
        name
        friendlyID
        Bookings {
          nextToken
          startedAt
          __typename
        }
        ArtistRates {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        eventVenueDriverSupportingArtistsId
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getArea = /* GraphQL */ `
  query GetArea($id: ID!) {
    getArea(id: $id) {
      id
      venueID
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAreas = /* GraphQL */ `
  query ListAreas(
    $filter: ModelAreaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAreas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        venueID
        name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAreas = /* GraphQL */ `
  query SyncAreas(
    $filter: ModelAreaFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAreas(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        venueID
        name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getRegion = /* GraphQL */ `
  query GetRegion($id: ID!) {
    getRegion(id: $id) {
      id
      venueDriverRegionID
      Venues {
        items {
          id
          regionID
          venueDriverVenueID
          venueDriverRegionID
          name
          image_URL
          address
          city
          friendlyID
          position
          type
          time_zone
          color
          hide_party_passes
          timeRanges
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      name
      slug
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listRegions = /* GraphQL */ `
  query ListRegions(
    $filter: ModelRegionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRegions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        venueDriverRegionID
        Venues {
          nextToken
          startedAt
          __typename
        }
        name
        slug
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncRegions = /* GraphQL */ `
  query SyncRegions(
    $filter: ModelRegionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRegions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        venueDriverRegionID
        Venues {
          nextToken
          startedAt
          __typename
        }
        name
        slug
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getVenue = /* GraphQL */ `
  query GetVenue($id: ID!) {
    getVenue(id: $id) {
      id
      regionID
      venueDriverVenueID
      venueDriverRegionID
      Dates {
        items {
          id
          venueID
          calendarDate
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          artistTierDatesId
          dateTypeOptionDatesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Areas {
        items {
          id
          venueID
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      BookingBudgets {
        items {
          id
          venueID
          calendarMonth
          cost
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ArtistTiers {
        items {
          id
          name
          minimumFee
          venueID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Events {
        items {
          id
          venueID
          venueDriverEventID
          venueDriverVenueID
          title
          description
          time_zone
          date
          date_string
          open
          open_string
          close
          close_string
          closed_message
          VIP_URL
          currentTicketTierID
          ticketTierIDs
          stopSellingTicketsDate
          stopSellingTicketsTime
          tag
          website
          isEnableLoyaltyProgram
          isAllowedDollarsReward
          isAllowedPointsReward
          categories
          supportEmail
          organizerEmail
          servicePhone
          teaser
          isHideEndTime
          isHideStartTime
          template
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          eventsGroupEventsId
          eventVenueDriverHeadlinerArtistId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      name
      image_URL
      address
      city
      friendlyID
      position
      type
      time_zone
      color
      hide_party_passes
      timeRanges
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listVenues = /* GraphQL */ `
  query ListVenues(
    $filter: ModelVenueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVenues(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        regionID
        venueDriverVenueID
        venueDriverRegionID
        Dates {
          nextToken
          startedAt
          __typename
        }
        Areas {
          nextToken
          startedAt
          __typename
        }
        BookingBudgets {
          nextToken
          startedAt
          __typename
        }
        ArtistTiers {
          nextToken
          startedAt
          __typename
        }
        Events {
          nextToken
          startedAt
          __typename
        }
        name
        image_URL
        address
        city
        friendlyID
        position
        type
        time_zone
        color
        hide_party_passes
        timeRanges
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncVenues = /* GraphQL */ `
  query SyncVenues(
    $filter: ModelVenueFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncVenues(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        regionID
        venueDriverVenueID
        venueDriverRegionID
        Dates {
          nextToken
          startedAt
          __typename
        }
        Areas {
          nextToken
          startedAt
          __typename
        }
        BookingBudgets {
          nextToken
          startedAt
          __typename
        }
        ArtistTiers {
          nextToken
          startedAt
          __typename
        }
        Events {
          nextToken
          startedAt
          __typename
        }
        name
        image_URL
        address
        city
        friendlyID
        position
        type
        time_zone
        color
        hide_party_passes
        timeRanges
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listVenuesByVenueDriverVenue = /* GraphQL */ `
  query ListVenuesByVenueDriverVenue(
    $venueDriverVenueID: Int!
    $sortDirection: ModelSortDirection
    $filter: ModelVenueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVenuesByVenueDriverVenue(
      venueDriverVenueID: $venueDriverVenueID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        regionID
        venueDriverVenueID
        venueDriverRegionID
        Dates {
          nextToken
          startedAt
          __typename
        }
        Areas {
          nextToken
          startedAt
          __typename
        }
        BookingBudgets {
          nextToken
          startedAt
          __typename
        }
        ArtistTiers {
          nextToken
          startedAt
          __typename
        }
        Events {
          nextToken
          startedAt
          __typename
        }
        name
        image_URL
        address
        city
        friendlyID
        position
        type
        time_zone
        color
        hide_party_passes
        timeRanges
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getTicketCategoryOptions = /* GraphQL */ `
  query GetTicketCategoryOptions($id: ID!) {
    getTicketCategoryOptions(id: $id) {
      id
      title
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listTicketCategoryOptions = /* GraphQL */ `
  query ListTicketCategoryOptions(
    $filter: ModelTicketCategoryOptionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTicketCategoryOptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncTicketCategoryOptions = /* GraphQL */ `
  query SyncTicketCategoryOptions(
    $filter: ModelTicketCategoryOptionsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTicketCategoryOptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        title
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const searchTicketCategoryOptions = /* GraphQL */ `
  query SearchTicketCategoryOptions(
    $filter: SearchableTicketCategoryOptionsFilterInput
    $sort: [SearchableTicketCategoryOptionsSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableTicketCategoryOptionsAggregationInput]
  ) {
    searchTicketCategoryOptions(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        title
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getEventCategoryOptions = /* GraphQL */ `
  query GetEventCategoryOptions($id: ID!) {
    getEventCategoryOptions(id: $id) {
      id
      title
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listEventCategoryOptions = /* GraphQL */ `
  query ListEventCategoryOptions(
    $filter: ModelEventCategoryOptionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventCategoryOptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncEventCategoryOptions = /* GraphQL */ `
  query SyncEventCategoryOptions(
    $filter: ModelEventCategoryOptionsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncEventCategoryOptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        title
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const searchEventCategoryOptions = /* GraphQL */ `
  query SearchEventCategoryOptions(
    $filter: SearchableEventCategoryOptionsFilterInput
    $sort: [SearchableEventCategoryOptionsSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableEventCategoryOptionsAggregationInput]
  ) {
    searchEventCategoryOptions(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        title
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      venueID
      venueDriverEventID
      venueDriverVenueID
      title
      description
      time_zone
      date
      date_string
      open
      open_string
      close
      close_string
      closed_message
      VIP_URL
      eventsGroup {
        id
        name
        events {
          nextToken
          startedAt
          __typename
        }
        venueID
        venueDriverPackageTicketID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      currentTicketTierID
      ticketTierIDs
      stopSellingTicketsDate
      stopSellingTicketsTime
      tag
      website
      isEnableLoyaltyProgram
      isAllowedDollarsReward
      isAllowedPointsReward
      categories
      supportEmail
      organizerEmail
      servicePhone
      teaser
      isHideEndTime
      isHideStartTime
      images {
        social
        calendar
        __typename
      }
      template
      Bookings {
        items {
          id
          dateID
          artistID
          eventID
          startTime
          endTime
          public
          announcementDate
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          bookingStatusOptionBookingId
          bookingBookingFeeId
          bookingAreaId
          bookingArtistTierId
          bookingArtistRateId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      VenueDriverTickets {
        items {
          id
          eventID
          ticketPackageEventIDs
          regionID
          venueDriverTicketID
          venueDriverParentTicketID
          venueDriverEventID
          title
          activeForSales
          wasActivatedForSales
          type
          description
          price
          priceID
          number_available
          price_description
          category
          is_wristband_ticket
          is_template
          is_reusable
          onSaleStartDate
          onSaleStartTime
          onSaleEndDate
          onSaleEndTime
          ticketTiers
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      VenueDriverTicketSales {
        items {
          id
          ticketID
          eventID
          venueDriverTicketSaleID
          venueDriverTicketID
          venueDriverEventID
          venueDriverOrderID
          final
          email
          quantity
          first
          last
          code
          order_id
          access_token
          category
          subtotal_currency
          let_tax_amount_currency
          sales_tax_currency
          surcharge_currency
          total_currency
          service_charge_currency
          ticket_title
          event_date
          event_time
          event_name
          venue_title
          price
          is_package_ticket
          let_tax_disclaimer_msg
          ticket_type_label
          is_wristband_sale
          type
          venueDriverParentTicketSaleID
          venueDriverParentTicketSaleCode
          wristband_pickup_at
          wristband_pickup_venue_id
          wristband_pickup_event_id
          original_code
          isAssigned
          void
          voidedAt
          voidedReason
          refunded
          refundedSurcharge
          refundedAt
          refundedReason
          eventGroupId
          sourceSystem
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      VenueDriverTicketSaleRedemption {
        items {
          id
          ticketID
          ticketSaleID
          eventID
          venueDriverTicketSaleRedemptionID
          venueDriverTicketID
          venueDriverTicketSaleID
          venueDriverEventID
          void
          redeemedAt
          sourceSystem
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userTicketSaleRedemptionsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      venueDriverHeadlinerArtist {
        id
        venueDriverArtistID
        name
        friendlyID
        Bookings {
          nextToken
          startedAt
          __typename
        }
        ArtistRates {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        eventVenueDriverSupportingArtistsId
        __typename
      }
      venueDriverSupportingArtists {
        items {
          id
          venueDriverArtistID
          name
          friendlyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          eventVenueDriverSupportingArtistsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      eventsGroupEventsId
      eventVenueDriverHeadlinerArtistId
      __typename
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        venueID
        venueDriverEventID
        venueDriverVenueID
        title
        description
        time_zone
        date
        date_string
        open
        open_string
        close
        close_string
        closed_message
        VIP_URL
        eventsGroup {
          id
          name
          venueID
          venueDriverPackageTicketID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        currentTicketTierID
        ticketTierIDs
        stopSellingTicketsDate
        stopSellingTicketsTime
        tag
        website
        isEnableLoyaltyProgram
        isAllowedDollarsReward
        isAllowedPointsReward
        categories
        supportEmail
        organizerEmail
        servicePhone
        teaser
        isHideEndTime
        isHideStartTime
        images {
          social
          calendar
          __typename
        }
        template
        Bookings {
          nextToken
          startedAt
          __typename
        }
        VenueDriverTickets {
          nextToken
          startedAt
          __typename
        }
        VenueDriverTicketSales {
          nextToken
          startedAt
          __typename
        }
        VenueDriverTicketSaleRedemption {
          nextToken
          startedAt
          __typename
        }
        venueDriverHeadlinerArtist {
          id
          venueDriverArtistID
          name
          friendlyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          eventVenueDriverSupportingArtistsId
          __typename
        }
        venueDriverSupportingArtists {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        eventsGroupEventsId
        eventVenueDriverHeadlinerArtistId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncEvents = /* GraphQL */ `
  query SyncEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncEvents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        venueID
        venueDriverEventID
        venueDriverVenueID
        title
        description
        time_zone
        date
        date_string
        open
        open_string
        close
        close_string
        closed_message
        VIP_URL
        eventsGroup {
          id
          name
          venueID
          venueDriverPackageTicketID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        currentTicketTierID
        ticketTierIDs
        stopSellingTicketsDate
        stopSellingTicketsTime
        tag
        website
        isEnableLoyaltyProgram
        isAllowedDollarsReward
        isAllowedPointsReward
        categories
        supportEmail
        organizerEmail
        servicePhone
        teaser
        isHideEndTime
        isHideStartTime
        images {
          social
          calendar
          __typename
        }
        template
        Bookings {
          nextToken
          startedAt
          __typename
        }
        VenueDriverTickets {
          nextToken
          startedAt
          __typename
        }
        VenueDriverTicketSales {
          nextToken
          startedAt
          __typename
        }
        VenueDriverTicketSaleRedemption {
          nextToken
          startedAt
          __typename
        }
        venueDriverHeadlinerArtist {
          id
          venueDriverArtistID
          name
          friendlyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          eventVenueDriverSupportingArtistsId
          __typename
        }
        venueDriverSupportingArtists {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        eventsGroupEventsId
        eventVenueDriverHeadlinerArtistId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listEventsByVenueDriverEvent = /* GraphQL */ `
  query ListEventsByVenueDriverEvent(
    $venueDriverEventID: Int!
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventsByVenueDriverEvent(
      venueDriverEventID: $venueDriverEventID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        venueID
        venueDriverEventID
        venueDriverVenueID
        title
        description
        time_zone
        date
        date_string
        open
        open_string
        close
        close_string
        closed_message
        VIP_URL
        eventsGroup {
          id
          name
          venueID
          venueDriverPackageTicketID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        currentTicketTierID
        ticketTierIDs
        stopSellingTicketsDate
        stopSellingTicketsTime
        tag
        website
        isEnableLoyaltyProgram
        isAllowedDollarsReward
        isAllowedPointsReward
        categories
        supportEmail
        organizerEmail
        servicePhone
        teaser
        isHideEndTime
        isHideStartTime
        images {
          social
          calendar
          __typename
        }
        template
        Bookings {
          nextToken
          startedAt
          __typename
        }
        VenueDriverTickets {
          nextToken
          startedAt
          __typename
        }
        VenueDriverTicketSales {
          nextToken
          startedAt
          __typename
        }
        VenueDriverTicketSaleRedemption {
          nextToken
          startedAt
          __typename
        }
        venueDriverHeadlinerArtist {
          id
          venueDriverArtistID
          name
          friendlyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          eventVenueDriverSupportingArtistsId
          __typename
        }
        venueDriverSupportingArtists {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        eventsGroupEventsId
        eventVenueDriverHeadlinerArtistId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listEventsByVenueDriverVenue = /* GraphQL */ `
  query ListEventsByVenueDriverVenue(
    $venueDriverVenueID: Int!
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventsByVenueDriverVenue(
      venueDriverVenueID: $venueDriverVenueID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        venueID
        venueDriverEventID
        venueDriverVenueID
        title
        description
        time_zone
        date
        date_string
        open
        open_string
        close
        close_string
        closed_message
        VIP_URL
        eventsGroup {
          id
          name
          venueID
          venueDriverPackageTicketID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        currentTicketTierID
        ticketTierIDs
        stopSellingTicketsDate
        stopSellingTicketsTime
        tag
        website
        isEnableLoyaltyProgram
        isAllowedDollarsReward
        isAllowedPointsReward
        categories
        supportEmail
        organizerEmail
        servicePhone
        teaser
        isHideEndTime
        isHideStartTime
        images {
          social
          calendar
          __typename
        }
        template
        Bookings {
          nextToken
          startedAt
          __typename
        }
        VenueDriverTickets {
          nextToken
          startedAt
          __typename
        }
        VenueDriverTicketSales {
          nextToken
          startedAt
          __typename
        }
        VenueDriverTicketSaleRedemption {
          nextToken
          startedAt
          __typename
        }
        venueDriverHeadlinerArtist {
          id
          venueDriverArtistID
          name
          friendlyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          eventVenueDriverSupportingArtistsId
          __typename
        }
        venueDriverSupportingArtists {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        eventsGroupEventsId
        eventVenueDriverHeadlinerArtistId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const searchEvents = /* GraphQL */ `
  query SearchEvents(
    $filter: SearchableEventFilterInput
    $sort: [SearchableEventSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableEventAggregationInput]
  ) {
    searchEvents(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        venueID
        venueDriverEventID
        venueDriverVenueID
        title
        description
        time_zone
        date
        date_string
        open
        open_string
        close
        close_string
        closed_message
        VIP_URL
        eventsGroup {
          id
          name
          venueID
          venueDriverPackageTicketID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        currentTicketTierID
        ticketTierIDs
        stopSellingTicketsDate
        stopSellingTicketsTime
        tag
        website
        isEnableLoyaltyProgram
        isAllowedDollarsReward
        isAllowedPointsReward
        categories
        supportEmail
        organizerEmail
        servicePhone
        teaser
        isHideEndTime
        isHideStartTime
        images {
          social
          calendar
          __typename
        }
        template
        Bookings {
          nextToken
          startedAt
          __typename
        }
        VenueDriverTickets {
          nextToken
          startedAt
          __typename
        }
        VenueDriverTicketSales {
          nextToken
          startedAt
          __typename
        }
        VenueDriverTicketSaleRedemption {
          nextToken
          startedAt
          __typename
        }
        venueDriverHeadlinerArtist {
          id
          venueDriverArtistID
          name
          friendlyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          eventVenueDriverSupportingArtistsId
          __typename
        }
        venueDriverSupportingArtists {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        eventsGroupEventsId
        eventVenueDriverHeadlinerArtistId
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getTicket = /* GraphQL */ `
  query GetTicket($id: ID!) {
    getTicket(id: $id) {
      id
      eventID
      ticketPackageEventIDs
      regionID
      venueDriverTicketID
      venueDriverParentTicketID
      venueDriverEventID
      title
      activeForSales
      wasActivatedForSales
      type
      description
      price
      priceID
      number_available
      price_description
      category
      is_wristband_ticket
      is_template
      is_reusable
      onSaleStartDate
      onSaleStartTime
      onSaleEndDate
      onSaleEndTime
      ticketTiers
      VenueDriverTicketSales {
        items {
          id
          ticketID
          eventID
          venueDriverTicketSaleID
          venueDriverTicketID
          venueDriverEventID
          venueDriverOrderID
          final
          email
          quantity
          first
          last
          code
          order_id
          access_token
          category
          subtotal_currency
          let_tax_amount_currency
          sales_tax_currency
          surcharge_currency
          total_currency
          service_charge_currency
          ticket_title
          event_date
          event_time
          event_name
          venue_title
          price
          is_package_ticket
          let_tax_disclaimer_msg
          ticket_type_label
          is_wristband_sale
          type
          venueDriverParentTicketSaleID
          venueDriverParentTicketSaleCode
          wristband_pickup_at
          wristband_pickup_venue_id
          wristband_pickup_event_id
          original_code
          isAssigned
          void
          voidedAt
          voidedReason
          refunded
          refundedSurcharge
          refundedAt
          refundedReason
          eventGroupId
          sourceSystem
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      VenueDriverTicketSaleRedemption {
        items {
          id
          ticketID
          ticketSaleID
          eventID
          venueDriverTicketSaleRedemptionID
          venueDriverTicketID
          venueDriverTicketSaleID
          venueDriverEventID
          void
          redeemedAt
          sourceSystem
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userTicketSaleRedemptionsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listTickets = /* GraphQL */ `
  query ListTickets(
    $filter: ModelTicketFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTickets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        eventID
        ticketPackageEventIDs
        regionID
        venueDriverTicketID
        venueDriverParentTicketID
        venueDriverEventID
        title
        activeForSales
        wasActivatedForSales
        type
        description
        price
        priceID
        number_available
        price_description
        category
        is_wristband_ticket
        is_template
        is_reusable
        onSaleStartDate
        onSaleStartTime
        onSaleEndDate
        onSaleEndTime
        ticketTiers
        VenueDriverTicketSales {
          nextToken
          startedAt
          __typename
        }
        VenueDriverTicketSaleRedemption {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncTickets = /* GraphQL */ `
  query SyncTickets(
    $filter: ModelTicketFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTickets(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        eventID
        ticketPackageEventIDs
        regionID
        venueDriverTicketID
        venueDriverParentTicketID
        venueDriverEventID
        title
        activeForSales
        wasActivatedForSales
        type
        description
        price
        priceID
        number_available
        price_description
        category
        is_wristband_ticket
        is_template
        is_reusable
        onSaleStartDate
        onSaleStartTime
        onSaleEndDate
        onSaleEndTime
        ticketTiers
        VenueDriverTicketSales {
          nextToken
          startedAt
          __typename
        }
        VenueDriverTicketSaleRedemption {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listTicketsByVenueDriverTicket = /* GraphQL */ `
  query ListTicketsByVenueDriverTicket(
    $venueDriverTicketID: Int!
    $sortDirection: ModelSortDirection
    $filter: ModelTicketFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTicketsByVenueDriverTicket(
      venueDriverTicketID: $venueDriverTicketID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventID
        ticketPackageEventIDs
        regionID
        venueDriverTicketID
        venueDriverParentTicketID
        venueDriverEventID
        title
        activeForSales
        wasActivatedForSales
        type
        description
        price
        priceID
        number_available
        price_description
        category
        is_wristband_ticket
        is_template
        is_reusable
        onSaleStartDate
        onSaleStartTime
        onSaleEndDate
        onSaleEndTime
        ticketTiers
        VenueDriverTicketSales {
          nextToken
          startedAt
          __typename
        }
        VenueDriverTicketSaleRedemption {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listTicketsByVenueDriverParentTicket = /* GraphQL */ `
  query ListTicketsByVenueDriverParentTicket(
    $venueDriverParentTicketID: Int!
    $sortDirection: ModelSortDirection
    $filter: ModelTicketFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTicketsByVenueDriverParentTicket(
      venueDriverParentTicketID: $venueDriverParentTicketID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventID
        ticketPackageEventIDs
        regionID
        venueDriverTicketID
        venueDriverParentTicketID
        venueDriverEventID
        title
        activeForSales
        wasActivatedForSales
        type
        description
        price
        priceID
        number_available
        price_description
        category
        is_wristband_ticket
        is_template
        is_reusable
        onSaleStartDate
        onSaleStartTime
        onSaleEndDate
        onSaleEndTime
        ticketTiers
        VenueDriverTicketSales {
          nextToken
          startedAt
          __typename
        }
        VenueDriverTicketSaleRedemption {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listTicketsByVenueDriverEvent = /* GraphQL */ `
  query ListTicketsByVenueDriverEvent(
    $venueDriverEventID: Int!
    $sortDirection: ModelSortDirection
    $filter: ModelTicketFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTicketsByVenueDriverEvent(
      venueDriverEventID: $venueDriverEventID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventID
        ticketPackageEventIDs
        regionID
        venueDriverTicketID
        venueDriverParentTicketID
        venueDriverEventID
        title
        activeForSales
        wasActivatedForSales
        type
        description
        price
        priceID
        number_available
        price_description
        category
        is_wristband_ticket
        is_template
        is_reusable
        onSaleStartDate
        onSaleStartTime
        onSaleEndDate
        onSaleEndTime
        ticketTiers
        VenueDriverTicketSales {
          nextToken
          startedAt
          __typename
        }
        VenueDriverTicketSaleRedemption {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const searchTickets = /* GraphQL */ `
  query SearchTickets(
    $filter: SearchableTicketFilterInput
    $sort: [SearchableTicketSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableTicketAggregationInput]
  ) {
    searchTickets(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        eventID
        ticketPackageEventIDs
        regionID
        venueDriverTicketID
        venueDriverParentTicketID
        venueDriverEventID
        title
        activeForSales
        wasActivatedForSales
        type
        description
        price
        priceID
        number_available
        price_description
        category
        is_wristband_ticket
        is_template
        is_reusable
        onSaleStartDate
        onSaleStartTime
        onSaleEndDate
        onSaleEndTime
        ticketTiers
        VenueDriverTicketSales {
          nextToken
          startedAt
          __typename
        }
        VenueDriverTicketSaleRedemption {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getTicketSale = /* GraphQL */ `
  query GetTicketSale($id: ID!) {
    getTicketSale(id: $id) {
      id
      ticketID
      eventID
      venueDriverTicketSaleID
      venueDriverTicketID
      venueDriverEventID
      venueDriverOrderID
      final
      email
      quantity
      first
      last
      code
      order_id
      access_token
      category
      subtotal_currency
      let_tax_amount_currency
      sales_tax_currency
      surcharge_currency
      total_currency
      service_charge_currency
      ticket_title
      event_date
      event_time
      event_name
      venue_title
      price
      is_package_ticket
      let_tax_disclaimer_msg
      ticket_type_label
      is_wristband_sale
      type
      venueDriverParentTicketSaleID
      venueDriverParentTicketSaleCode
      VenueDriverTicketSales {
        items {
          id
          ticketID
          eventID
          venueDriverTicketSaleID
          venueDriverTicketID
          venueDriverEventID
          venueDriverOrderID
          final
          email
          quantity
          first
          last
          code
          order_id
          access_token
          category
          subtotal_currency
          let_tax_amount_currency
          sales_tax_currency
          surcharge_currency
          total_currency
          service_charge_currency
          ticket_title
          event_date
          event_time
          event_name
          venue_title
          price
          is_package_ticket
          let_tax_disclaimer_msg
          ticket_type_label
          is_wristband_sale
          type
          venueDriverParentTicketSaleID
          venueDriverParentTicketSaleCode
          wristband_pickup_at
          wristband_pickup_venue_id
          wristband_pickup_event_id
          original_code
          isAssigned
          void
          voidedAt
          voidedReason
          refunded
          refundedSurcharge
          refundedAt
          refundedReason
          eventGroupId
          sourceSystem
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      VenueDriverTicketSaleRedemption {
        items {
          id
          ticketID
          ticketSaleID
          eventID
          venueDriverTicketSaleRedemptionID
          venueDriverTicketID
          venueDriverTicketSaleID
          venueDriverEventID
          void
          redeemedAt
          sourceSystem
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userTicketSaleRedemptionsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      wristband_pickup_at
      wristband_pickup_venue_id
      wristband_pickup_event_id
      original_code
      isAssigned
      void
      voidedAt
      voidedReason
      refunded
      refundedSurcharge
      refundedAt
      refundedReason
      eventGroupId
      sourceSystem
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listTicketSales = /* GraphQL */ `
  query ListTicketSales(
    $filter: ModelTicketSaleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTicketSales(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ticketID
        eventID
        venueDriverTicketSaleID
        venueDriverTicketID
        venueDriverEventID
        venueDriverOrderID
        final
        email
        quantity
        first
        last
        code
        order_id
        access_token
        category
        subtotal_currency
        let_tax_amount_currency
        sales_tax_currency
        surcharge_currency
        total_currency
        service_charge_currency
        ticket_title
        event_date
        event_time
        event_name
        venue_title
        price
        is_package_ticket
        let_tax_disclaimer_msg
        ticket_type_label
        is_wristband_sale
        type
        venueDriverParentTicketSaleID
        venueDriverParentTicketSaleCode
        VenueDriverTicketSales {
          nextToken
          startedAt
          __typename
        }
        VenueDriverTicketSaleRedemption {
          nextToken
          startedAt
          __typename
        }
        wristband_pickup_at
        wristband_pickup_venue_id
        wristband_pickup_event_id
        original_code
        isAssigned
        void
        voidedAt
        voidedReason
        refunded
        refundedSurcharge
        refundedAt
        refundedReason
        eventGroupId
        sourceSystem
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncTicketSales = /* GraphQL */ `
  query SyncTicketSales(
    $filter: ModelTicketSaleFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTicketSales(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        ticketID
        eventID
        venueDriverTicketSaleID
        venueDriverTicketID
        venueDriverEventID
        venueDriverOrderID
        final
        email
        quantity
        first
        last
        code
        order_id
        access_token
        category
        subtotal_currency
        let_tax_amount_currency
        sales_tax_currency
        surcharge_currency
        total_currency
        service_charge_currency
        ticket_title
        event_date
        event_time
        event_name
        venue_title
        price
        is_package_ticket
        let_tax_disclaimer_msg
        ticket_type_label
        is_wristband_sale
        type
        venueDriverParentTicketSaleID
        venueDriverParentTicketSaleCode
        VenueDriverTicketSales {
          nextToken
          startedAt
          __typename
        }
        VenueDriverTicketSaleRedemption {
          nextToken
          startedAt
          __typename
        }
        wristband_pickup_at
        wristband_pickup_venue_id
        wristband_pickup_event_id
        original_code
        isAssigned
        void
        voidedAt
        voidedReason
        refunded
        refundedSurcharge
        refundedAt
        refundedReason
        eventGroupId
        sourceSystem
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listTicketSalesByVenueDriverTicketSale = /* GraphQL */ `
  query ListTicketSalesByVenueDriverTicketSale(
    $venueDriverTicketSaleID: Int!
    $sortDirection: ModelSortDirection
    $filter: ModelTicketSaleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTicketSalesByVenueDriverTicketSale(
      venueDriverTicketSaleID: $venueDriverTicketSaleID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ticketID
        eventID
        venueDriverTicketSaleID
        venueDriverTicketID
        venueDriverEventID
        venueDriverOrderID
        final
        email
        quantity
        first
        last
        code
        order_id
        access_token
        category
        subtotal_currency
        let_tax_amount_currency
        sales_tax_currency
        surcharge_currency
        total_currency
        service_charge_currency
        ticket_title
        event_date
        event_time
        event_name
        venue_title
        price
        is_package_ticket
        let_tax_disclaimer_msg
        ticket_type_label
        is_wristband_sale
        type
        venueDriverParentTicketSaleID
        venueDriverParentTicketSaleCode
        VenueDriverTicketSales {
          nextToken
          startedAt
          __typename
        }
        VenueDriverTicketSaleRedemption {
          nextToken
          startedAt
          __typename
        }
        wristband_pickup_at
        wristband_pickup_venue_id
        wristband_pickup_event_id
        original_code
        isAssigned
        void
        voidedAt
        voidedReason
        refunded
        refundedSurcharge
        refundedAt
        refundedReason
        eventGroupId
        sourceSystem
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listTicketSalesByVenueDriverTicket = /* GraphQL */ `
  query ListTicketSalesByVenueDriverTicket(
    $venueDriverTicketID: Int!
    $sortDirection: ModelSortDirection
    $filter: ModelTicketSaleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTicketSalesByVenueDriverTicket(
      venueDriverTicketID: $venueDriverTicketID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ticketID
        eventID
        venueDriverTicketSaleID
        venueDriverTicketID
        venueDriverEventID
        venueDriverOrderID
        final
        email
        quantity
        first
        last
        code
        order_id
        access_token
        category
        subtotal_currency
        let_tax_amount_currency
        sales_tax_currency
        surcharge_currency
        total_currency
        service_charge_currency
        ticket_title
        event_date
        event_time
        event_name
        venue_title
        price
        is_package_ticket
        let_tax_disclaimer_msg
        ticket_type_label
        is_wristband_sale
        type
        venueDriverParentTicketSaleID
        venueDriverParentTicketSaleCode
        VenueDriverTicketSales {
          nextToken
          startedAt
          __typename
        }
        VenueDriverTicketSaleRedemption {
          nextToken
          startedAt
          __typename
        }
        wristband_pickup_at
        wristband_pickup_venue_id
        wristband_pickup_event_id
        original_code
        isAssigned
        void
        voidedAt
        voidedReason
        refunded
        refundedSurcharge
        refundedAt
        refundedReason
        eventGroupId
        sourceSystem
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listTicketSalesByVenueDriverEvent = /* GraphQL */ `
  query ListTicketSalesByVenueDriverEvent(
    $venueDriverEventID: Int!
    $sortDirection: ModelSortDirection
    $filter: ModelTicketSaleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTicketSalesByVenueDriverEvent(
      venueDriverEventID: $venueDriverEventID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ticketID
        eventID
        venueDriverTicketSaleID
        venueDriverTicketID
        venueDriverEventID
        venueDriverOrderID
        final
        email
        quantity
        first
        last
        code
        order_id
        access_token
        category
        subtotal_currency
        let_tax_amount_currency
        sales_tax_currency
        surcharge_currency
        total_currency
        service_charge_currency
        ticket_title
        event_date
        event_time
        event_name
        venue_title
        price
        is_package_ticket
        let_tax_disclaimer_msg
        ticket_type_label
        is_wristband_sale
        type
        venueDriverParentTicketSaleID
        venueDriverParentTicketSaleCode
        VenueDriverTicketSales {
          nextToken
          startedAt
          __typename
        }
        VenueDriverTicketSaleRedemption {
          nextToken
          startedAt
          __typename
        }
        wristband_pickup_at
        wristband_pickup_venue_id
        wristband_pickup_event_id
        original_code
        isAssigned
        void
        voidedAt
        voidedReason
        refunded
        refundedSurcharge
        refundedAt
        refundedReason
        eventGroupId
        sourceSystem
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listTicketSalesByVenueDriverOrder = /* GraphQL */ `
  query ListTicketSalesByVenueDriverOrder(
    $venueDriverOrderID: Int!
    $sortDirection: ModelSortDirection
    $filter: ModelTicketSaleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTicketSalesByVenueDriverOrder(
      venueDriverOrderID: $venueDriverOrderID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ticketID
        eventID
        venueDriverTicketSaleID
        venueDriverTicketID
        venueDriverEventID
        venueDriverOrderID
        final
        email
        quantity
        first
        last
        code
        order_id
        access_token
        category
        subtotal_currency
        let_tax_amount_currency
        sales_tax_currency
        surcharge_currency
        total_currency
        service_charge_currency
        ticket_title
        event_date
        event_time
        event_name
        venue_title
        price
        is_package_ticket
        let_tax_disclaimer_msg
        ticket_type_label
        is_wristband_sale
        type
        venueDriverParentTicketSaleID
        venueDriverParentTicketSaleCode
        VenueDriverTicketSales {
          nextToken
          startedAt
          __typename
        }
        VenueDriverTicketSaleRedemption {
          nextToken
          startedAt
          __typename
        }
        wristband_pickup_at
        wristband_pickup_venue_id
        wristband_pickup_event_id
        original_code
        isAssigned
        void
        voidedAt
        voidedReason
        refunded
        refundedSurcharge
        refundedAt
        refundedReason
        eventGroupId
        sourceSystem
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listTicketSalesByEventGroup = /* GraphQL */ `
  query ListTicketSalesByEventGroup(
    $eventGroupId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTicketSaleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTicketSalesByEventGroup(
      eventGroupId: $eventGroupId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ticketID
        eventID
        venueDriverTicketSaleID
        venueDriverTicketID
        venueDriverEventID
        venueDriverOrderID
        final
        email
        quantity
        first
        last
        code
        order_id
        access_token
        category
        subtotal_currency
        let_tax_amount_currency
        sales_tax_currency
        surcharge_currency
        total_currency
        service_charge_currency
        ticket_title
        event_date
        event_time
        event_name
        venue_title
        price
        is_package_ticket
        let_tax_disclaimer_msg
        ticket_type_label
        is_wristband_sale
        type
        venueDriverParentTicketSaleID
        venueDriverParentTicketSaleCode
        VenueDriverTicketSales {
          nextToken
          startedAt
          __typename
        }
        VenueDriverTicketSaleRedemption {
          nextToken
          startedAt
          __typename
        }
        wristband_pickup_at
        wristband_pickup_venue_id
        wristband_pickup_event_id
        original_code
        isAssigned
        void
        voidedAt
        voidedReason
        refunded
        refundedSurcharge
        refundedAt
        refundedReason
        eventGroupId
        sourceSystem
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const searchTicketSales = /* GraphQL */ `
  query SearchTicketSales(
    $filter: SearchableTicketSaleFilterInput
    $sort: [SearchableTicketSaleSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableTicketSaleAggregationInput]
  ) {
    searchTicketSales(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        ticketID
        eventID
        venueDriverTicketSaleID
        venueDriverTicketID
        venueDriverEventID
        venueDriverOrderID
        final
        email
        quantity
        first
        last
        code
        order_id
        access_token
        category
        subtotal_currency
        let_tax_amount_currency
        sales_tax_currency
        surcharge_currency
        total_currency
        service_charge_currency
        ticket_title
        event_date
        event_time
        event_name
        venue_title
        price
        is_package_ticket
        let_tax_disclaimer_msg
        ticket_type_label
        is_wristband_sale
        type
        venueDriverParentTicketSaleID
        venueDriverParentTicketSaleCode
        VenueDriverTicketSales {
          nextToken
          startedAt
          __typename
        }
        VenueDriverTicketSaleRedemption {
          nextToken
          startedAt
          __typename
        }
        wristband_pickup_at
        wristband_pickup_venue_id
        wristband_pickup_event_id
        original_code
        isAssigned
        void
        voidedAt
        voidedReason
        refunded
        refundedSurcharge
        refundedAt
        refundedReason
        eventGroupId
        sourceSystem
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getTicketSaleRedemption = /* GraphQL */ `
  query GetTicketSaleRedemption($id: ID!) {
    getTicketSaleRedemption(id: $id) {
      id
      ticketID
      ticketSaleID
      eventID
      user {
        id
        cognitoID
        name
        email
        active
        settings
        roleGrants {
          nextToken
          startedAt
          __typename
        }
        ticketSaleRedemptions {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      venueDriverTicketSaleRedemptionID
      venueDriverTicketID
      venueDriverTicketSaleID
      venueDriverEventID
      void
      redeemedAt
      sourceSystem
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userTicketSaleRedemptionsId
      __typename
    }
  }
`;
export const listTicketSaleRedemptions = /* GraphQL */ `
  query ListTicketSaleRedemptions(
    $filter: ModelTicketSaleRedemptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTicketSaleRedemptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ticketID
        ticketSaleID
        eventID
        user {
          id
          cognitoID
          name
          email
          active
          settings
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        venueDriverTicketSaleRedemptionID
        venueDriverTicketID
        venueDriverTicketSaleID
        venueDriverEventID
        void
        redeemedAt
        sourceSystem
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userTicketSaleRedemptionsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncTicketSaleRedemptions = /* GraphQL */ `
  query SyncTicketSaleRedemptions(
    $filter: ModelTicketSaleRedemptionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTicketSaleRedemptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        ticketID
        ticketSaleID
        eventID
        user {
          id
          cognitoID
          name
          email
          active
          settings
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        venueDriverTicketSaleRedemptionID
        venueDriverTicketID
        venueDriverTicketSaleID
        venueDriverEventID
        void
        redeemedAt
        sourceSystem
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userTicketSaleRedemptionsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listTicketSaleRedemptionsByVenueDriverTicketRedemption = /* GraphQL */ `
  query ListTicketSaleRedemptionsByVenueDriverTicketRedemption(
    $venueDriverTicketSaleRedemptionID: Int!
    $sortDirection: ModelSortDirection
    $filter: ModelTicketSaleRedemptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTicketSaleRedemptionsByVenueDriverTicketRedemption(
      venueDriverTicketSaleRedemptionID: $venueDriverTicketSaleRedemptionID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ticketID
        ticketSaleID
        eventID
        user {
          id
          cognitoID
          name
          email
          active
          settings
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        venueDriverTicketSaleRedemptionID
        venueDriverTicketID
        venueDriverTicketSaleID
        venueDriverEventID
        void
        redeemedAt
        sourceSystem
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userTicketSaleRedemptionsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listTicketSaleRedemptionsByVenueDriverTicketSale = /* GraphQL */ `
  query ListTicketSaleRedemptionsByVenueDriverTicketSale(
    $venueDriverTicketSaleID: Int!
    $sortDirection: ModelSortDirection
    $filter: ModelTicketSaleRedemptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTicketSaleRedemptionsByVenueDriverTicketSale(
      venueDriverTicketSaleID: $venueDriverTicketSaleID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ticketID
        ticketSaleID
        eventID
        user {
          id
          cognitoID
          name
          email
          active
          settings
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        venueDriverTicketSaleRedemptionID
        venueDriverTicketID
        venueDriverTicketSaleID
        venueDriverEventID
        void
        redeemedAt
        sourceSystem
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userTicketSaleRedemptionsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listTicketSaleRedemptionsByVenueDriverEvent = /* GraphQL */ `
  query ListTicketSaleRedemptionsByVenueDriverEvent(
    $venueDriverEventID: Int!
    $sortDirection: ModelSortDirection
    $filter: ModelTicketSaleRedemptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTicketSaleRedemptionsByVenueDriverEvent(
      venueDriverEventID: $venueDriverEventID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ticketID
        ticketSaleID
        eventID
        user {
          id
          cognitoID
          name
          email
          active
          settings
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        venueDriverTicketSaleRedemptionID
        venueDriverTicketID
        venueDriverTicketSaleID
        venueDriverEventID
        void
        redeemedAt
        sourceSystem
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userTicketSaleRedemptionsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const searchTicketSaleRedemptions = /* GraphQL */ `
  query SearchTicketSaleRedemptions(
    $filter: SearchableTicketSaleRedemptionFilterInput
    $sort: [SearchableTicketSaleRedemptionSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableTicketSaleRedemptionAggregationInput]
  ) {
    searchTicketSaleRedemptions(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        ticketID
        ticketSaleID
        eventID
        user {
          id
          cognitoID
          name
          email
          active
          settings
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        venueDriverTicketSaleRedemptionID
        venueDriverTicketID
        venueDriverTicketSaleID
        venueDriverEventID
        void
        redeemedAt
        sourceSystem
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userTicketSaleRedemptionsId
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getTicketTier = /* GraphQL */ `
  query GetTicketTier($id: ID!) {
    getTicketTier(id: $id) {
      id
      name
      tickets
      eventID
      venueID
      ticketSoldCountCondition
      ticketSoldInOneDayCondition
      ticketCategoryCondition
      daysBeforeEvent
      escalationTime
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listTicketTiers = /* GraphQL */ `
  query ListTicketTiers(
    $filter: ModelTicketTierFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTicketTiers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        tickets
        eventID
        venueID
        ticketSoldCountCondition
        ticketSoldInOneDayCondition
        ticketCategoryCondition
        daysBeforeEvent
        escalationTime
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncTicketTiers = /* GraphQL */ `
  query SyncTicketTiers(
    $filter: ModelTicketTierFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTicketTiers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        tickets
        eventID
        venueID
        ticketSoldCountCondition
        ticketSoldInOneDayCondition
        ticketCategoryCondition
        daysBeforeEvent
        escalationTime
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const searchTicketTiers = /* GraphQL */ `
  query SearchTicketTiers(
    $filter: SearchableTicketTierFilterInput
    $sort: [SearchableTicketTierSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableTicketTierAggregationInput]
  ) {
    searchTicketTiers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        name
        tickets
        eventID
        venueID
        ticketSoldCountCondition
        ticketSoldInOneDayCondition
        ticketCategoryCondition
        daysBeforeEvent
        escalationTime
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getEventsGroup = /* GraphQL */ `
  query GetEventsGroup($id: ID!) {
    getEventsGroup(id: $id) {
      id
      name
      events {
        items {
          id
          venueID
          venueDriverEventID
          venueDriverVenueID
          title
          description
          time_zone
          date
          date_string
          open
          open_string
          close
          close_string
          closed_message
          VIP_URL
          currentTicketTierID
          ticketTierIDs
          stopSellingTicketsDate
          stopSellingTicketsTime
          tag
          website
          isEnableLoyaltyProgram
          isAllowedDollarsReward
          isAllowedPointsReward
          categories
          supportEmail
          organizerEmail
          servicePhone
          teaser
          isHideEndTime
          isHideStartTime
          template
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          eventsGroupEventsId
          eventVenueDriverHeadlinerArtistId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      venueID
      venueDriverPackageTicketID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listEventsGroups = /* GraphQL */ `
  query ListEventsGroups(
    $filter: ModelEventsGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventsGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        events {
          nextToken
          startedAt
          __typename
        }
        venueID
        venueDriverPackageTicketID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncEventsGroups = /* GraphQL */ `
  query SyncEventsGroups(
    $filter: ModelEventsGroupFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncEventsGroups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        events {
          nextToken
          startedAt
          __typename
        }
        venueID
        venueDriverPackageTicketID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listEventsGroupsByVenueDriverPackageTicket = /* GraphQL */ `
  query ListEventsGroupsByVenueDriverPackageTicket(
    $venueDriverPackageTicketID: Int!
    $sortDirection: ModelSortDirection
    $filter: ModelEventsGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventsGroupsByVenueDriverPackageTicket(
      venueDriverPackageTicketID: $venueDriverPackageTicketID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        events {
          nextToken
          startedAt
          __typename
        }
        venueID
        venueDriverPackageTicketID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const searchEventsGroups = /* GraphQL */ `
  query SearchEventsGroups(
    $filter: SearchableEventsGroupFilterInput
    $sort: [SearchableEventsGroupSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableEventsGroupAggregationInput]
  ) {
    searchEventsGroups(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        name
        events {
          nextToken
          startedAt
          __typename
        }
        venueID
        venueDriverPackageTicketID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      cognitoID
      name
      email
      active
      settings
      roleGrants {
        items {
          id
          userID
          roleID
          eventsGroups
          venues
          regions
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ticketSaleRedemptions {
        items {
          id
          ticketID
          ticketSaleID
          eventID
          venueDriverTicketSaleRedemptionID
          venueDriverTicketID
          venueDriverTicketSaleID
          venueDriverEventID
          void
          redeemedAt
          sourceSystem
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userTicketSaleRedemptionsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cognitoID
        name
        email
        active
        settings
        roleGrants {
          nextToken
          startedAt
          __typename
        }
        ticketSaleRedemptions {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUsers = /* GraphQL */ `
  query SyncUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        cognitoID
        name
        email
        active
        settings
        roleGrants {
          nextToken
          startedAt
          __typename
        }
        ticketSaleRedemptions {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listUsersByCognitoID = /* GraphQL */ `
  query ListUsersByCognitoID(
    $cognitoID: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsersByCognitoID(
      cognitoID: $cognitoID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cognitoID
        name
        email
        active
        settings
        roleGrants {
          nextToken
          startedAt
          __typename
        }
        ticketSaleRedemptions {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const searchUsers = /* GraphQL */ `
  query SearchUsers(
    $filter: SearchableUserFilterInput
    $sort: [SearchableUserSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableUserAggregationInput]
  ) {
    searchUsers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        cognitoID
        name
        email
        active
        settings
        roleGrants {
          nextToken
          startedAt
          __typename
        }
        ticketSaleRedemptions {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getRoleGrant = /* GraphQL */ `
  query GetRoleGrant($id: ID!) {
    getRoleGrant(id: $id) {
      id
      userID
      roleID
      role {
        id
        name
        perRegions
        perVenues
        perEventsGroups
        hasAllPermissions
        hasAccessToUsersManagement
        hasAccessToBookingsManagement
        canViewBookingFinancial
        canManageBookingStatusOption
        hasAccessToEventsManagement
        canManageEventOnEventsPage
        hasAccessToTicketsManagement
        canActivateEvent
        canActivateTicketSales
        hasAccessToVenueSettings
        hasAccessToRegionSettings
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      eventsGroups
      venues
      regions
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listRoleGrants = /* GraphQL */ `
  query ListRoleGrants(
    $filter: ModelRoleGrantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRoleGrants(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        roleID
        role {
          id
          name
          perRegions
          perVenues
          perEventsGroups
          hasAllPermissions
          hasAccessToUsersManagement
          hasAccessToBookingsManagement
          canViewBookingFinancial
          canManageBookingStatusOption
          hasAccessToEventsManagement
          canManageEventOnEventsPage
          hasAccessToTicketsManagement
          canActivateEvent
          canActivateTicketSales
          hasAccessToVenueSettings
          hasAccessToRegionSettings
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        eventsGroups
        venues
        regions
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncRoleGrants = /* GraphQL */ `
  query SyncRoleGrants(
    $filter: ModelRoleGrantFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRoleGrants(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userID
        roleID
        role {
          id
          name
          perRegions
          perVenues
          perEventsGroups
          hasAllPermissions
          hasAccessToUsersManagement
          hasAccessToBookingsManagement
          canViewBookingFinancial
          canManageBookingStatusOption
          hasAccessToEventsManagement
          canManageEventOnEventsPage
          hasAccessToTicketsManagement
          canActivateEvent
          canActivateTicketSales
          hasAccessToVenueSettings
          hasAccessToRegionSettings
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        eventsGroups
        venues
        regions
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const searchRoleGrants = /* GraphQL */ `
  query SearchRoleGrants(
    $filter: SearchableRoleGrantFilterInput
    $sort: [SearchableRoleGrantSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableRoleGrantAggregationInput]
  ) {
    searchRoleGrants(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        userID
        roleID
        role {
          id
          name
          perRegions
          perVenues
          perEventsGroups
          hasAllPermissions
          hasAccessToUsersManagement
          hasAccessToBookingsManagement
          canViewBookingFinancial
          canManageBookingStatusOption
          hasAccessToEventsManagement
          canManageEventOnEventsPage
          hasAccessToTicketsManagement
          canActivateEvent
          canActivateTicketSales
          hasAccessToVenueSettings
          hasAccessToRegionSettings
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        eventsGroups
        venues
        regions
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getRole = /* GraphQL */ `
  query GetRole($id: ID!) {
    getRole(id: $id) {
      id
      name
      perRegions
      perVenues
      perEventsGroups
      hasAllPermissions
      hasAccessToUsersManagement
      hasAccessToBookingsManagement
      canViewBookingFinancial
      canManageBookingStatusOption
      hasAccessToEventsManagement
      canManageEventOnEventsPage
      hasAccessToTicketsManagement
      canActivateEvent
      canActivateTicketSales
      hasAccessToVenueSettings
      hasAccessToRegionSettings
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listRoles = /* GraphQL */ `
  query ListRoles(
    $filter: ModelRoleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        perRegions
        perVenues
        perEventsGroups
        hasAllPermissions
        hasAccessToUsersManagement
        hasAccessToBookingsManagement
        canViewBookingFinancial
        canManageBookingStatusOption
        hasAccessToEventsManagement
        canManageEventOnEventsPage
        hasAccessToTicketsManagement
        canActivateEvent
        canActivateTicketSales
        hasAccessToVenueSettings
        hasAccessToRegionSettings
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncRoles = /* GraphQL */ `
  query SyncRoles(
    $filter: ModelRoleFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRoles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        perRegions
        perVenues
        perEventsGroups
        hasAllPermissions
        hasAccessToUsersManagement
        hasAccessToBookingsManagement
        canViewBookingFinancial
        canManageBookingStatusOption
        hasAccessToEventsManagement
        canManageEventOnEventsPage
        hasAccessToTicketsManagement
        canActivateEvent
        canActivateTicketSales
        hasAccessToVenueSettings
        hasAccessToRegionSettings
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const searchRoles = /* GraphQL */ `
  query SearchRoles(
    $filter: SearchableRoleFilterInput
    $sort: [SearchableRoleSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableRoleAggregationInput]
  ) {
    searchRoles(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        name
        perRegions
        perVenues
        perEventsGroups
        hasAllPermissions
        hasAccessToUsersManagement
        hasAccessToBookingsManagement
        canViewBookingFinancial
        canManageBookingStatusOption
        hasAccessToEventsManagement
        canManageEventOnEventsPage
        hasAccessToTicketsManagement
        canActivateEvent
        canActivateTicketSales
        hasAccessToVenueSettings
        hasAccessToRegionSettings
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getPrice = /* GraphQL */ `
  query GetPrice($id: ID!) {
    getPrice(id: $id) {
      id
      name
      regionID
      price
      letTaxable
      letTaxAmount
      surcharge
      serviceCharge
      venueFee
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listPrices = /* GraphQL */ `
  query ListPrices(
    $filter: ModelPriceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPrices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        regionID
        price
        letTaxable
        letTaxAmount
        surcharge
        serviceCharge
        venueFee
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncPrices = /* GraphQL */ `
  query SyncPrices(
    $filter: ModelPriceFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPrices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        regionID
        price
        letTaxable
        letTaxAmount
        surcharge
        serviceCharge
        venueFee
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const searchPrices = /* GraphQL */ `
  query SearchPrices(
    $filter: SearchablePriceFilterInput
    $sort: [SearchablePriceSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchablePriceAggregationInput]
  ) {
    searchPrices(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        name
        regionID
        price
        letTaxable
        letTaxAmount
        surcharge
        serviceCharge
        venueFee
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getOrder = /* GraphQL */ `
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
      id
      venueDriverOrderID
      VenueDriverTicketSales {
        items {
          id
          ticketID
          eventID
          venueDriverTicketSaleID
          venueDriverTicketID
          venueDriverEventID
          venueDriverOrderID
          final
          email
          quantity
          first
          last
          code
          order_id
          access_token
          category
          subtotal_currency
          let_tax_amount_currency
          sales_tax_currency
          surcharge_currency
          total_currency
          service_charge_currency
          ticket_title
          event_date
          event_time
          event_name
          venue_title
          price
          is_package_ticket
          let_tax_disclaimer_msg
          ticket_type_label
          is_wristband_sale
          type
          venueDriverParentTicketSaleID
          venueDriverParentTicketSaleCode
          wristband_pickup_at
          wristband_pickup_venue_id
          wristband_pickup_event_id
          original_code
          isAssigned
          void
          voidedAt
          voidedReason
          refunded
          refundedSurcharge
          refundedAt
          refundedReason
          eventGroupId
          sourceSystem
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ticketSales {
        items {
          id
          ticketID
          eventID
          venueDriverTicketSaleID
          venueDriverTicketID
          venueDriverEventID
          venueDriverOrderID
          final
          email
          quantity
          first
          last
          code
          order_id
          access_token
          category
          subtotal_currency
          let_tax_amount_currency
          sales_tax_currency
          surcharge_currency
          total_currency
          service_charge_currency
          ticket_title
          event_date
          event_time
          event_name
          venue_title
          price
          is_package_ticket
          let_tax_disclaimer_msg
          ticket_type_label
          is_wristband_sale
          type
          venueDriverParentTicketSaleID
          venueDriverParentTicketSaleCode
          wristband_pickup_at
          wristband_pickup_venue_id
          wristband_pickup_event_id
          original_code
          isAssigned
          void
          voidedAt
          voidedReason
          refunded
          refundedSurcharge
          refundedAt
          refundedReason
          eventGroupId
          sourceSystem
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      total
      order_id
      final
      final_at
      call_notes
      first
      last
      phone
      email
      call_clasification
      learned_from
      card_last_four_digits
      staff_id
      lastChangeStatusAt
      status
      address
      surcharge_currency
      sales_tax_currency
      service_charge_currency
      let_tax_amount_currency
      subtotal_currency
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listOrders = /* GraphQL */ `
  query ListOrders(
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        venueDriverOrderID
        VenueDriverTicketSales {
          nextToken
          startedAt
          __typename
        }
        ticketSales {
          nextToken
          startedAt
          __typename
        }
        total
        order_id
        final
        final_at
        call_notes
        first
        last
        phone
        email
        call_clasification
        learned_from
        card_last_four_digits
        staff_id
        lastChangeStatusAt
        status
        address
        surcharge_currency
        sales_tax_currency
        service_charge_currency
        let_tax_amount_currency
        subtotal_currency
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncOrders = /* GraphQL */ `
  query SyncOrders(
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncOrders(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        venueDriverOrderID
        VenueDriverTicketSales {
          nextToken
          startedAt
          __typename
        }
        ticketSales {
          nextToken
          startedAt
          __typename
        }
        total
        order_id
        final
        final_at
        call_notes
        first
        last
        phone
        email
        call_clasification
        learned_from
        card_last_four_digits
        staff_id
        lastChangeStatusAt
        status
        address
        surcharge_currency
        sales_tax_currency
        service_charge_currency
        let_tax_amount_currency
        subtotal_currency
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getOrderByVenueDriverOrder = /* GraphQL */ `
  query GetOrderByVenueDriverOrder(
    $venueDriverOrderID: Int!
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrderByVenueDriverOrder(
      venueDriverOrderID: $venueDriverOrderID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        venueDriverOrderID
        VenueDriverTicketSales {
          nextToken
          startedAt
          __typename
        }
        ticketSales {
          nextToken
          startedAt
          __typename
        }
        total
        order_id
        final
        final_at
        call_notes
        first
        last
        phone
        email
        call_clasification
        learned_from
        card_last_four_digits
        staff_id
        lastChangeStatusAt
        status
        address
        surcharge_currency
        sales_tax_currency
        service_charge_currency
        let_tax_amount_currency
        subtotal_currency
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const searchOrders = /* GraphQL */ `
  query SearchOrders(
    $filter: SearchableOrderFilterInput
    $sort: [SearchableOrderSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableOrderAggregationInput]
  ) {
    searchOrders(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        venueDriverOrderID
        VenueDriverTicketSales {
          nextToken
          startedAt
          __typename
        }
        ticketSales {
          nextToken
          startedAt
          __typename
        }
        total
        order_id
        final
        final_at
        call_notes
        first
        last
        phone
        email
        call_clasification
        learned_from
        card_last_four_digits
        staff_id
        lastChangeStatusAt
        status
        address
        surcharge_currency
        sales_tax_currency
        service_charge_currency
        let_tax_amount_currency
        subtotal_currency
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
