import moment from "moment";

export const isItemExistInArray = (item: string, array: string[] | number[]) => array.some(i => i === item);

// use this if you need to check if two arrays of string are equal
export const isArraysEqual = (a: string[], b: string[]) => a.length === b.length && a.every(item => b.indexOf(item) > -1);

export const removeDuplicatesFromArray = (array: any[]) => array.filter((value, index, arr) => arr.indexOf(value) === index);

export const convertStringWithCommasToArray = (data: string) => data.split(',');

export const filterById = (array: any[], id: string) => array.filter(i => i.id !== id);

export const findItemsByKey = (array: any[], key: any, item: any) => array.filter(i => i[key] === item && !i._deleted);

export const findItemById = (array: any[], id: string) => array.find(i => i.id === id);

export const findItemBySameDate = (array: any[], dateKey: string, date: Date) => array.find(i => moment(i[dateKey]).isSame(date));

export const getArrayItemsCount = (array: any[]): number => array.reduce((prev, curr) => Number(prev || 0) + Number(curr || 0), 0) || 0;

export const checkIncludes = (a: string, b: string) => a.toLowerCase().includes(b.toLowerCase());

export const sortByASC = (array: any[], item?: string) => array.sort((a, b) => compareTwoObjects(item ? a[item] : a, item ? b[item] : b));

export const compareTwoObjects = (a: any, b: any) => getComparisonValue(a).localeCompare(getComparisonValue(b));

export const getComparisonValue = (v: any) => (v?.length ? v : v?.name || v?.title || 'z');

export const addZeroToInteger = (number: number | string) => !isNaN(+number) && +number > 9 ? number : `0${number}`;

export const removeTextFromString = (text: string | RegExp, fullText: string) => fullText.replace(text, '');

export const removeLastCharacter = (str: string) => str.slice(0, -1);

export const getStringWithFirstCharacterInLowerCase = (str: string) => str.charAt(0).toLowerCase() + str.slice(1);

export const getTrimmedString = (str: string) => str.split(' ').join('');

export const getSubstringByPosition = (start: number, end?: number, string: string | null | undefined = '') => string?.substring(start, end) || '';

export const getTrimmedNumber = (number?: number | null) => number ? Number(number.toString().trim()) : 0;

export const isSameDays = (firstDate: Date, secondDate: Date) => moment(firstDate).isSame(secondDate, 'day');

export const isDateInPast = (date: Date | string) => moment(date).isBefore(moment());

export const isDateInFuture = (date: Date | string) => moment(date).isAfter(moment());

export const dateIsBeforeDate = (firstDate: Date, secondDate: Date) => moment(firstDate).isBefore(moment(secondDate).add(1, 'd'));

export const dateIsAfterDate = (firstDate: Date, secondDate: Date) => moment(firstDate).isAfter(moment(secondDate).add(1, 'd'));

export const searchItemsByKey = (array: any[], key: string, searchText: string) => array.filter(i => i[key].toLowerCase().trim().match(searchText.toLowerCase().trim()));

// Validators
export const emailValidator = (email: string) => email.length > 0 ? /\S+@\S+\.\S+/.test(email) : true;
export const phoneValidator = (phone: string) => phone.length > 0 ? /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(phone) : true;
export const numberValidator = (value: string) => value.match(/^[0-9]*$/);

// Regexps
export const digitsRegex = /\(\d\)$/;
export const bracketsRegex = /[(){}\[\]]/gi;
export const bracketsWithTextRegex = /\s*\(.*?\)\s*/g;
export const HTMLTagsRegex = /<\/?[^>]+(>|$)/g;
export const symbolsRegex = /[^a-zA-Zа-яА-Я\d\s]/g;