import 'moment/locale/en-gb';
import { API } from 'aws-amplify';

import { texts } from '../localization/texts';

import { getFormattedGraphqlRequest } from '../hooks/GraphQLHooks/fetchRequests/requestHelper';
import { convertStringWithCommasToArray, removeDuplicatesFromArray } from '../shared/GlobalHelper';
import { Permissions } from '../shared/Interfaces';

import { getUser, listRoleGrants, listRoles, listUsers } from '../graphql/queries';
import { defaultPermissions } from '../context/AuthContext';
import { Role, RoleGrant } from '../API';

const { errors: { smthWentWrong } } = texts();

export const getUserRecord = async (id: string) => {
  try {
    const request = await getFormattedGraphqlRequest(getUser, { input: id });
    return await API.graphql(request);
  } catch (error) {
    return error;
  }
};

export const getRoleGrantsRecords = async () => {
  try {
    const request = await getFormattedGraphqlRequest(listRoleGrants);
    const responseData: any = await API.graphql(request);
    if (responseData.data.listRoleGrants.items && responseData.data.listRoleGrants.items.length > 0)
      return responseData.data.listRoleGrants.items;
  } catch (error) {
    return error;
  }
};

export const getRolesRecords = async () => {
  try {
    const request = await getFormattedGraphqlRequest(listRoles);
    const responseData: any = await API.graphql(request);
    if (responseData.data.listRoles.items && responseData.data.listRoles.items.length > 0)
      return responseData.data.listRoles.items;
  } catch (error) {
    return error;
  }
};

const getPerValuesForBookingsPage = (prevItems?: string | null, roleGrantValues?: string | null) => {
  if (prevItems) {
    const values = roleGrantValues ? convertStringWithCommasToArray(roleGrantValues) : [];
    const allValues = [...convertStringWithCommasToArray(prevItems), ...values];
    return removeDuplicatesFromArray(allValues).toString();
  } else {
    return roleGrantValues ? roleGrantValues : null;
  }
};

export const mapPermissionsAccordingToRoles = (userRoles: Role[], permissions: Permissions, userRoleGrantsItems: RoleGrant[]) => {
  userRoles.forEach((role: Role) => {
    if (role.hasAllPermissions) permissions.hasAllPermissions = true;

    if (role.hasAccessToUsersManagement) permissions.hasAccessToUsersManagement = true;

    if (role.hasAccessToVenueSettings) permissions.hasAccessToVenueSettings = true;
    if (role.hasAccessToRegionSettings) permissions.hasAccessToRegionSettings = true;

    if (role.hasAccessToBookingsManagement) {
      permissions.hasAccessToBookingsManagement = true;
      if (role.perEventsGroups || role.perRegions || role.perVenues) {
        const roleGrant = userRoleGrantsItems.find((rg: RoleGrant) => rg.roleID === role.id);

        if (role.perRegions)
          permissions.perRegions.bookings = getPerValuesForBookingsPage(permissions.perRegions.bookings, roleGrant?.regions);

        if (role.perVenues)
          permissions.perVenues.bookings = getPerValuesForBookingsPage(permissions.perVenues.bookings, roleGrant?.venues);

        if (role.perEventsGroups)
          permissions.perEventsGroups.bookings = getPerValuesForBookingsPage(permissions.perEventsGroups.bookings, roleGrant?.eventsGroups);
      }
    }
    if (role.canViewBookingFinancial) permissions.canViewBookingFinancial = true;
    if (role.canManageBookingStatusOption) permissions.canManageBookingStatusOption = true;

    if (role.hasAccessToEventsManagement) {
      permissions.hasAccessToEventsManagement = true;

      if (role.perEventsGroups || role.perRegions || role.perVenues) {
        const roleGrant = userRoleGrantsItems.find((rg: RoleGrant) => rg.roleID === role.id);

        if (role.perRegions)
          permissions.perRegions.events = getPerValuesForBookingsPage(permissions.perRegions.events, roleGrant?.regions);

        if (role.perVenues)
          permissions.perVenues.events = getPerValuesForBookingsPage(permissions.perVenues.events, roleGrant?.venues);

        if (role.perEventsGroups)
          permissions.perEventsGroups.events = getPerValuesForBookingsPage(permissions.perEventsGroups.events, roleGrant?.eventsGroups);
      }
    }
    if (role.canManageEventOnEventsPage) permissions.canManageEventOnEventsPage = true;

    if (role.hasAccessToTicketsManagement) {
      permissions.hasAccessToTicketsManagement = true;

      if (role.perEventsGroups || role.perRegions || role.perVenues) {
        const roleGrant = userRoleGrantsItems.find((rg: RoleGrant) => rg.roleID === role.id);

        if (role.perRegions)
          permissions.perRegions.tickets = getPerValuesForBookingsPage(permissions.perRegions.tickets, roleGrant?.regions);

        if (role.perVenues)
          permissions.perVenues.tickets = getPerValuesForBookingsPage(permissions.perVenues.tickets, roleGrant?.venues);

        if (role.perEventsGroups)
          permissions.perEventsGroups.tickets = getPerValuesForBookingsPage(permissions.perEventsGroups.tickets, roleGrant?.eventsGroups);
      }
    }

    if (role.canActivateEvent) permissions.canActivateEvent = true;
    if (role.canActivateTicketSales) permissions.canActivateTicketSales = true;
  });
  return permissions;
};

export const getUpdatedUserPermissions = async (userID: string) => {
  if (userID) {
    return await getPermissionsForUserID(userID);
  } else {
    return { errors: [smthWentWrong] };
  }
};

const getPermissionsForUserID = async (userID: string) => {
  const searchRoleGrantsItems: RoleGrant[] = (await getRoleGrantsRecords()) || [];

  if (searchRoleGrantsItems.length === 0) return defaultPermissions();

  const userRoleGrantsItems: RoleGrant[] = searchRoleGrantsItems.filter(rg => rg.userID === userID && !rg._deleted);

  const roleIDs = userRoleGrantsItems.map((rg: RoleGrant) => rg.roleID);
  const roles: Role[] = (await getRolesRecords()) || [];
  const userRoles = roles.filter((role: Role) => roleIDs.includes(role.id) && !role._deleted);

  let permissions: Permissions = defaultPermissions();

  return mapPermissionsAccordingToRoles(userRoles, permissions, userRoleGrantsItems);
};

export const getUserRecordByCognitoID = async (cognitoID: string) => {
  try {
    const request = await getFormattedGraphqlRequest(listUsers, { filter: { cognitoID: { eq: cognitoID } } });
    const responseData: any = await API.graphql(request);
    if (responseData.data.listUsers.items && responseData.data.listUsers.items.length > 0) {
      return responseData.data.listUsers.items[0];
    }
    return { errors: [{ message: smthWentWrong }] };
  } catch (error) {
    return error;
  }
};
