import React, { useContext, useEffect, useState } from 'react';
import { Header, useMediaQuery } from "@venuedriver/ui-driver";
import styles from './CheckinTemplate.module.css'
import { useRouter } from 'next/router';
import { Auth } from 'aws-amplify';
import classNames from 'classnames';
import { VenuesModal } from '../../../components/VenuesFilterModal/VenuesModal';
import { useAuthenticate } from '../../../hooks/contextHooks/useAuthenticate';
import { Context } from '../../../context/GlobalContext';

export const CheckinTemplate = ({ children }: any) => {
  const router = useRouter();
  const { permissionsChecks: { hasAccessGlobalPerVenues } } = useAuthenticate();

  const { venues: listVenues } = useContext(Context);

  const [isMenuCollapsed, setIsMenuCollapsed] = useState<boolean>(false);
  const [isMenuShowed, setIsMenuShowed] = useState<boolean>(true);
  const [selectedPage, setSelectedPage] = useState<string>("Ticket Driver");
  const [selectedPath, setSelectedPath] = useState<string>("/");

  // Venues Filter button
  const [selectedVenuesFilter, setSelectedVenuesFilter] =
    useState<string>("All Venues");
  const [showModal, setShowModal] = useState<boolean>(false);

  const { pathname } = router;

  const isTablet = useMediaQuery("(min-width: 576px) and (max-width: 1200px)");
  const isMobile = useMediaQuery("(max-width: 575px)");

  useEffect(() => {
    if (isMobile) {
      setIsMenuShowed(false);
    } else {
      setIsMenuShowed(true);
    }
  }, [isMobile]);

  const handleCollapseMenu = (): void => {
    setIsMenuCollapsed(!isMenuCollapsed);
  };

  const handleShowMenu = (): void => {
    console.log('There is no menu on staff template');
  };

  const handleModal = (): void => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    setSelectedPath(pathname);
  }, [pathname]);

  const appContainerClasses = classNames({
    [styles.appContainer]: true,
    [styles["with-menu-expanded"]]: isMenuShowed && !isMenuCollapsed,
    [styles["with-menu-collapsed"]]: isMenuShowed && isMenuCollapsed,
    [styles["without-menu"]]: !isMenuShowed,
    [styles["header-mobile"]]: isMobile,
    [styles["header-tablet"]]: isTablet,
    [styles["header-desktop"]]: !isMobile && !isTablet,
  });

  return (
    <div className={styles.container}>
      <div className={styles.menuAndAppContainer}>
        <div className={styles.headerAndAppContainer}>
          <div className={styles.header}>
            <Header
              isMenuShowed={isMenuShowed}
              title={selectedPage}
              setIsMenuShowed={handleShowMenu}
              selectedVenuesFilter={selectedVenuesFilter}
              onVenuesFilterClick={handleModal}
              onLogOutClick={() => Auth.signOut()}
            />
          </div>
          <div id="app-container" className={appContainerClasses}>
            {children}
          </div>
        </div>
      </div>
      <div className={showModal ? styles.modal : styles["modal-not-shown"]}>
        <VenuesModal
          title="Choose a Venue"
          description="You will see the events of the selected venue"
          cards={hasAccessGlobalPerVenues(listVenues)}
          setOpened={handleModal}
          opened={showModal}
        />
      </div>
    </div>
  );
}
