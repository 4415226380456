import { API } from 'aws-amplify';

import { getFormattedGraphqlRequest } from './requestHelper';

import { User } from '../../../API';
import { getCurrentUser } from '../../../graphql/customQueries';

export const getCurrentUserWithPermissions = (): Promise<User[]> => {
  const getData = async () => {
    try {
      const request = await getFormattedGraphqlRequest(getCurrentUser);
      const responseData: any = await API.graphql(request);
      if (responseData.data.getCurrentUser) {
        return responseData.data.getCurrentUser;
      } else {
        return [];
      }
    } catch (error: any) {
      console.log('getCurrentUser error', error);
      return [];
    }
  };

  return getData();
};
