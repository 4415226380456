import { useContext } from "react";
import { AuthContext, AuthContextProps } from "../../context/AuthContext";

export const useAuthenticate = (): AuthContextProps => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuthenticate must be used within an AuthContext Provider");
  }
  return context;
}
