import { Order } from '../models';
import { OrderContextProps } from './OrderContext';

type OrdersAction =
  | {type: 'ADD_ORDERS', payload: Order[]}
  | {type: 'ADD_CURRENT_ORDER', payload: Order | undefined}
  | {type: 'CHANGE_PAGE', payload: number}

export const orderReducer = (state: OrderContextProps, action: OrdersAction): OrderContextProps => {
  switch (action.type) {
    case 'ADD_ORDERS':
      return {
        ...state,
        orders: action.payload
      };
    case 'ADD_CURRENT_ORDER':
      return {
        ...state,
        currentOrder: action.payload
      };

    case 'ADD_CURRENT_ORDER':
      return {
        ...state,
        currentOrder: action.payload
      };

    default:
      return state;
  }
};
