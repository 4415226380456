import React, { useContext, useEffect, useState } from 'react'
import { Venue } from '../../API';
import { Context } from '../../context/GlobalContext';
import { useRegion } from './useRegion';
import VenuesSubscription from './VenuesSubscription';

export const useVenueByRegion = () => {
  const region = useRegion();
  const {updateVenues, venue,  updateVenue} = useContext(Context);


  const filterVenues = (venue: any) => {
    if (region.venueDriverRegionID !== null && region.venueDriverRegionID <= 0 ) {
      return venue
    }else {
      return region.venueDriverRegionID === venue.venueDriverRegionID
    }
  }

  const listVenuesRes: Venue[] | undefined = VenuesSubscription();
  const [listVenues, setListVenues] = useState<Venue[]>([]);

  useEffect(() => {
    if(listVenuesRes){
      const venues = listVenuesRes.filter(filterVenues);
      const venueDriverVenueIDs = venues.map(venue => venue.venueDriverVenueID);
      updateVenues(venueDriverVenueIDs);
      if (venue.venueDriverVenueID !== 0 && !venueDriverVenueIDs.length){
        updateVenue({name: '', id: '', venueDriverVenueID: 0});
      }
      setListVenues(venues);
    }
  }, [region, listVenuesRes])

  return listVenues
}
