import React, { createContext, ReactElement, useEffect, useMemo, useReducer, useState } from 'react'
import { Order } from '../models';
import { orderReducer } from './OrderReducer';

export interface OrderContextProps {
  orders: Order[];
  currentOrder: Order | undefined;
}

export interface OrderDataProps {
  ordersData: OrderContextProps;
  onOrdersUpdate: (orders: Order[]) => void;
  onCurrentOrderUpdate: (order?: Order) => void;
}

export const ordersInitialState: OrderContextProps = {
  orders: [],
  currentOrder: undefined,
};

const initializeState = () => {
  let fromLocalStorage;
  if (typeof sessionStorage !== 'undefined') {
    fromLocalStorage = JSON.parse(sessionStorage.getItem('orders') as string);
  }
  return fromLocalStorage || ordersInitialState;
};

export const OrdersData = createContext({} as OrderDataProps);

function OrderContext({ children }: { children: React.ReactNode }): ReactElement {
  const [mounted, setMounted] = useState(false);
  const [ordersData, dispatch] = useReducer(orderReducer, initializeState())
  
  
  useEffect(() => {
    setMounted(true);
  }, []);
  
  
  useEffect(() => {
    sessionStorage.setItem('orders', JSON.stringify(ordersData));
  }, [ordersData]);
  
  
  const onOrdersUpdate = (orders: Order[]) => {
    dispatch({ type: 'ADD_ORDERS', payload: orders });
  };

  const onCurrentOrderUpdate = (order?: Order | undefined) => {
    dispatch({ type: 'ADD_CURRENT_ORDER', payload: order });
  };
  
  const value = useMemo(() => ({ordersData, onCurrentOrderUpdate, onOrdersUpdate}), [ordersData]);
  
  if (!mounted) return <></>;
  return <OrdersData.Provider value={value}>{children}</OrdersData.Provider>;
};

export default OrderContext;
