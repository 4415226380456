import { useContext, useEffect, useState } from "react"

import { HeaderContext } from "@venuedriver/ui-driver";

import { API, graphqlOperation } from 'aws-amplify'
import { GraphQLResult } from '@aws-amplify/api-graphql'
import { listRegionsAvatar } from "../../graphql/customQueries"
import { Region } from "../../API";

export const useRegion = () => {

  const {headerState ,setRegions} = useContext(HeaderContext)
  const { region } = headerState

  useEffect(() => {
    const getRegions = async () => {
      try {
        const responseData: GraphQLResult<any> = await API.graphql(graphqlOperation(listRegionsAvatar, {}));

          if (responseData.data.listRegions.items && responseData.data.listRegions.items.length > 0) {
            const response = responseData.data.listRegions.items.filter((region: Region) =>
              region._deleted === null);
            setRegions(response);
        }
      } catch (error) {
        console.log('events error', error);
      }
    }
    getRegions()
  }, [])

  return region
}
