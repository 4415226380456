const texts = (value?: string | boolean, secondValue?: string | boolean) => ({
  color: 'Color',
  open: 'opened',
  closed: 'closed',
  messages: {
    impossibleDeleteEventDuePermissionsLack: 'You don\'t have enough rights to delete this event, contact the ticketing team.',
    venueColorIsSuccessfulUpdated: 'Venue color is successful updated',
    venueHidePartyPassesValueIsSuccessfulUpdated: 'Venue hide party passes is successful updated',
    roleIsSuccessfulCreated: 'Role is successful created',
    roleIsSuccessfulUpdated: 'Role is successful updated',
    roleGrantIsSuccessfulCreated: 'Role grant is successful created',
    roleGrantIsSuccessfulUpdated: 'Role grant is successful updated',
    roleGrantIsSuccessfulDeleted: 'Role grant is successful deleted',
    itemSuccessfulCreated: `${value || 'Item'} is successful created`,
    itemSuccessfulUpdated: `${value || 'Item'} is successful updated`,
    itemSuccessfulDeleted: `${value || 'Item'} is successful deleted`,
    areYouSureToDeleteRole: `The "${value}" role is already in use. Are you sure you want to delete the "${value}" role?`,
    roleDeleted: `${value} is deleted`,
    areYouSureToDeleteRoleGrant: `Are you sure you want to delete the "${value}" role grant?`,
    areYouSureToUpdateAccessLevel: `The selected "${value}" values will be deleted for all users with this role. Are you sure you want to change the access level to "${secondValue}"?`,
    areYouSureToClosePopupWithoutSaving: 'One of the roles is being edited. Are you sure you want to close the popup without saving data?',
    events: {
      closePopupWithChangedData: 'The changes will be lost after closing the popup. Are you sure you want to close the popup?',
      enableActiveEventMessage: 'This event will become available to the public. Tickets will be available for purchase. Are you sure?',
      disableActiveEventMessage: 'This event is already available for sales. Are you sure you want to deactivate it?',
      disabledActiveEventTooltip: 'To activate for sales this event you should select ticket tier as active.',
      disabledActiveTicketTooltip: 'Ticket activation is currently unavailable as the price has not been specified.',
      disableEventOnTicketTierRemove: 'After removing the current ticket tier, this event will be deactivated from sales.',
      ticketTierWithActiveTicketRemove: 'At least one active or previously activated ticket for this ticket tier is available.',
      deletingTicketThatWasActivatedForSales: `You cannot delete the "${value}", because it was activated for sale`,
      validationTimestampTooltip: `The ${value} timestamp value is later or the same as the timestamp value of one of the next Ticket Tier.`,
      ticketSoldZeroValueTooltip: 'The condition value in one of the ticket tiers is equal to zero',
      changeEventActiveStatus: `This Ticket Tier will be ${
        value ? 'activated' : 'deactivated'
      } for sale along with the Event. Are you sure you want to ${value ? 'activate' : 'deactivate'} them all?`,
      changeActiveTicketTier: `The ${value} is already activated. Are you sure you want to activate the ${secondValue}`,
      changeVenue: 'All selected ticket tiers will be lost. Are you sure you want to change the venue?',
    },
    tickets: {
      areYouSureToDeleteTicket: `Are you sure you want to remove the 
      "${value || 'Ticket'}" from the 
      "${secondValue || 'Ticket Tier'}"?`,
      areYouSureToDeleteTicketTier: `Are you sure you want to remove the "${value || 'Ticket Tier'}"?`,
      changingTierWithChangedTicketsData: 'The updates in Tickets will be lost. Are you sure you want to change the Ticket Tier?',
      disableTierWithChangedTicketsData: 'The updates in Tickets will be lost. Are you sure you want to disable the Ticket Tier?',
      disableTicketsForEvent : 'All changes in Tickets and Ticket Tiers will be lost. Are you sure you want to disable Tickets for this Event?',
      saveEventWithEscalatingTiers: `The conditions of the activated Ticket Tier have been reached. After saving the event, the "${value}" will be escalated to the "${secondValue}". Are you sure you want to continue?`,
      saveEventWithoutNotEsclatingTiers: `The conditions of all Ticket Tiers have been reached. Ticket sales for the "${value}" event will be deactivated. Are you sure you want to continue?`,
      saveEventWithoutDeletedPrices: 'This event contains tickets with deleted price. Deleted prices will be cleared from tickets after saving the event. Are you sure you want to continue?',
    },
    prices: {
      cannotSave: `This price cannot be saved because ${value ? 'required fields are empty' : ''}${value && secondValue ? ' and' : ''}${secondValue ? ' a price with that name already exists' : ''}.`,
      cannotBeRemoved: 'This price cannot be removed because it is used in the following tickets',
    }
  },
  errors: {
    permissionDenied: 'Access denied',
    failedSavingData: 'There is a problem saving the data. If the problem appears again contact the IT Support team.',
    futureBookingsPreventArtistArchiving: 'You cannot archive this artist. The artist is selected for future bookings.',
    cannotCreate: 'Cannot create',
    cannotSave: 'Cannot save',
    cannotUpdate: 'Cannot update',
    cannotArchive: 'Cannot archive',
    cannotDelete: 'Cannot delete',
    cannotCreateItem: `${value} is not created. Please try again later`,
    cannotUpdateItem: `${value} is not updated. Please try again later`,
    cannotDeleteItem: `${value} is not deleted. Please try again later`,
    cannotFindValue: `Cannot find ${value}. Please try again.`,
    smthWentWrong: 'Something went wrong. Please try again later',
    searchUsersError: 'Something went wrong during searchUsers',
    valueDoesNotExist: `${value} does not exist`,
    accessDenied: 'Access denied',
    sameTimeError:`You cannot create the ${value} that starts and ends at the same time`,
    booking: {
      bookingBeforNoonError: 'You cannot create an booking spanning midnight to noon',
      noVenue: 'No venues available for the selected day. Please create a Date for Venue first.',
      guaranteedFeeWarning: 'Warning! Guaranteed fee mismatches the selected tier.',
      confirmedBookingWarning: 'You cannot delete confirmed or ready to announce booking',
      disabledAnnounceTooltip: `You cannot announce booking because it's not confirmed or in the past`,
      noTimeRangesInVenue: `No time ranges data is available for the ${value} venue.`,
      noTimeRangeForBooking: `Unable to find a time range in the venue record for the booking${value ? ` with the following time range - ${value}` : ''}`,
    },
    events: {
      eventBeforeNoonError: 'You cannot create an event that starts after the midnight and ends before the noon',
    },
    tickets: {
      noTicketsOnSaveTierTooltip: 'You cannot save the ticket tier template because no ticket is selected. Please select at least one ticket template or ticket package.',
    },
  },
  emptyEntities: {
    empty: '[empty]',
    emptyName: '[ Empty name ]',
    noResultFound: 'No Result Found',
  },
  tableColumnTitles: {
    name: 'Name',
    title: 'Title',
    accessLevel: 'Access level',
    roleGrantsSelectedValues: 'Role Grants selected values',
    users: 'Users',
    email: 'Email',
    active: 'Active',
    roleGrants: 'Role Grants',
    roles: 'Roles',
    selectedValues: 'Selected values',
    ticket: 'Ticket',
    numberOfTickets: 'Number Of Tickets',
    count: 'Count',
    price: 'Price',
    sold: 'Sold',
    ticketPriceTitle: 'Ticket price title',
    taxableLET: 'LET taxable',
    amountTaxLET: 'LET tax amount',
    surcharge: 'Surcharge',
    serviceCharge: 'Service charge',
    venueFee: 'Venue fee',
    timePeriod: 'Time Period',
  },
  status: {
    active: 'Active',
    inactive: 'Inactive',
    required: 'Required',
    confirmed: 'Confirmed',
    archived: 'Archived',
    canceled: 'Canceled',
  },
  entities: {
    users: 'Users',
    venues: 'Venues',
    bookings: 'Bookings',
    events: 'Events',
    tickets: 'Tickets',
    ticketTemplates: 'Ticket Templates',
    ticketTiers: 'Ticket Tiers',
    ticketTiersTemplates: 'Ticket Tiers Templates',
    ticketPackages: 'Ticket Packages',
    ticketSales: 'Ticket Sales',
    roles: 'Roles',
    allArtists: 'All Artists',
    allVenues: 'All Venues',
    allTypes: 'All Types',
    allRegions: 'All Regions',
    notes: 'Notes',
    eventsGroups: 'Events Groups',
    eventTemplates: 'Event Templates',
    settings: 'Settings'
  },
  entity: {
    role: 'Role',
    roleGrant: 'Role Grant',
    artist: 'Artist',
    artistTier: 'Artist Tier',
    artistRate: 'Artist Rate',
    ticketTier: 'Ticket Tier',
    booking: 'Booking',
    bookingFee: 'Booking Fee',
    venue: 'Venue',
    area: 'Area',
    dateForVenue: 'Date for Venue',
    dateType: 'Date Type',
    event: 'Event',
    ticket: 'Ticket',
    template: 'Template',
    templateLabel: '(template)',
    deletedLabel: '(deleted)',
    category: 'Category',
    revenue: 'Revenue',
    type: 'Type',
    eventsGroup: 'Events Group',
    packageText: 'Package',
  },
  crud: {
    create: 'Create',
    update: 'Update',
    edit: 'Edit',
    add: 'Add',
    save: 'Save',
    clone: 'Clone',
    ok: 'Ok',
    confirm: 'Confirm',
    cancel: 'Cancel',
    delete: 'Delete',
    archive: 'Archive',
    close: 'Close',
    remove: 'Remove',
  },
  calendarTabs: {
    day: 'day',
    week: 'week',
    month: 'month',
    workWeek: 'work_week',
    agenda: 'agenda',
  },
  modals: {
    region: {
      searchByTitle: 'Search by title',
    },
    venue: {
      ticketSoldCountLabel: 'If the total number of tickets sold for any type of Ticket for Event exceeds',
      ticketSoldInOneDayLabel: 'If the number of tickets sold for any type of Ticket for Event in the last 24 hours exceeds',
      ticketSoldWarning: `The specified value is greater than ${value} as the least number available of selected tickets. This condition will not affect the Ticket Tier.`,
      ticketSoldValidationError: `The specified value is less or equal to the total number of tickets sold (${value}).`,
      ticketSoldZeroValueError: 'The value cannot be equal to zero',
      endOfSaleDateLabelFirstPart: 'At',
      endOfSaleDateLabelLastPart: 'days before the event',
      ticketCategoryLabel: 'If the number of tickets of category - has been exceeded',
      ticketCategoryValidationError: 'All tickets with the selected category are sold out.',
      categoryWarningWithValues: `The category is not specified in ${value}. This condition will not affect the Ticket Tier.`,
      categoryWarning: 'The category is not specified in all selected ticket templates. Choose another category.',
      timestampWarning: 'The timestamp value is later or the same as the timestamp value of the next Ticket Tier.',
      timestampInvalidTimeWarning: 'The current timestamp value is later than the start of the Event',
      hidePartyPasses: 'Hide Party Passes',
    },
  },
  booking: {
    year: 'year',
    date: 'date',
    today: 'Today',
    previous: 'Previous',
    next: 'Next',
    noBookings: 'No bookings',
    variance: `(variance: ${value})`,
    type: {
      idea: 'Idea',
    },
    wrapper: {
      status: `Status: ${value}`,
      area: `Area: ${value}`,
      bookingFee: `Booking fee: ${value}`,
    },
    agendaTab: {
      day: 'Day',
      date: 'Date',
      artist: 'Artist',
      bookingFee: 'Booking Fee',
      venue: 'Venue',
      status: 'Status',
      dateNotes: 'Date Notes',
      bookingNotes: 'Booking Notes',
      guaranteed: `Guaranteed: $${value}`,
      bonus: `Bonus: $${value}`,
      bookingFileName: value && secondValue ? `Booking - ${value} - ${secondValue}.csv` : 'Booking.csv',
      downloadCSV: 'Download CSV',
    },
    modals: {
      artistName: 'Artist name',
      artistRates: 'Artist rates',
      title: 'Title',
      fee: 'Fee',
      minimumFee: 'Minimum fee',
      month: 'Month',
      quarter: 'Quarter',
      budgetFor: `Budget for ${value}`,
      setBookingBudgetsFor: `Set Booking Budgets for ${value}`,
      selectPeriod: 'Select period',
      select: 'Select',
      from: 'From',
      to: 'To',
      status: 'Status',
      readyToAnnounce: 'Ready to Announce',
      guaranteedFee: 'Guaranteed Fee',
      bonusFee: 'Bonus Fee',
      planned: 'Planned',
      typeNotesHere: 'Type notes here...',
      sceduledAnnounceDate: 'Scheduled announce date',
    },
  },
  events: {
    previewImage: 'Preview image',
    dragAndDrop: 'Drag & drop or click to upload',
    calendar: 'Calendar',
    social: 'Social',
    activeForSales: 'Active For Sales',
    title: 'Title',
    from: 'From',
    to: 'To',
    hideStartTime: 'Hide Start Time',
    hideEndTime: 'Hide End Time',
    date: 'Date',
    time: 'Time',
    eventURLTag: 'Event URL Tag',
    eventDetailsWebsite: 'Alternative Event Details Website URL',
    enableLoyaltyProgram: 'Enable the Rewards for this experience',
    allowRewardDollars: 'Allow redeeming Rewards dollars',
    allowRewardPoints: 'Allow earning Rewards points',
    select: 'Select',
    selectPeriodForEvents: 'Select Period For Events',
    supportResources: 'Support Resources',
    technicalSupportEmail: 'Technical Support Email',
    organizerEmail: 'Event Organizer Email',
    customerServicePhone: 'Customer Service Phone #',
    description: 'Description',
    teaser: 'Teaser',
    characters: 'characters',
    endOfWorkingDay: 'end of working day',
    workingDay: 'working day',
    stopSellingTicketsDateAndTime: 'Select date and time when tickets should stop selling',
    numberOfEvents: 'Number of Events',
    agendaTab: {
      day: 'Day',
      date: 'Date',
      time: 'Time',
      venue: 'Venue',
      title: 'Title',
      eventFileName: value && secondValue ? `Event - ${value} - ${secondValue}.csv` : 'Event.csv',
      downloadCSV: 'Download CSV',
    },
  },
  tickets: {
    tierName: 'Tier Name',
    listOfSelectedTicketTemplates: 'The List Of Selected Ticket Templates',
    listOfSelectedTicketPackages: 'The List Of Selected Ticket Packages',
    endOfWorkingDay: 'end of working day',
    workingDay: 'working day',
    ticketName: 'Ticket Name',
    numberAvailable: 'Number Available',
    price: 'Price',
    packageTicket: 'Package Ticket',
    active: 'Active',
    hard: 'Hard',
    wristbandTicket: 'Wristband Ticket',
    vipTablePackage: 'VIP Table Package',
    useStartDateAndTime: 'Use Start Date And Time',
    onSaleStart: 'On-Sale Start',
    useEndDateAndTime: 'Use End Date And Time',
    end: 'End',
    from: 'From',
    until: 'Until',
    description: 'Description',
    enableTicketsForThisEvent: 'Enable Tickets For This Event',
    addNext: 'Add next',
    escalationConditions: 'Escalation conditions',
    agendaTab: {
      date: 'Date',
      time: 'Time',
      venue: 'Venue',
      event: 'Event',
      category: 'Category',
      type: 'Type',
      price: 'Price',
      ticketSaleFileName: value && secondValue ? `Ticket Sale - ${value} - ${secondValue}.csv` : 'Ticket Sale.csv',
      downloadCSV: 'Download CSV',
    },
    ticketPackages: {
      ticketPackages: 'Ticket packages',
      title: 'Title',
      active: 'Active',
      price: 'Price',
      usedInEvents: 'Used in events',
    }
  },
  roleManagement: {
    filters: {
      labels: {
        status: 'Select Status',
      },
      placeholders: {
        searchByTitle: 'Search By Title',
      },
    },
    tabs: {
      usersRoles: 'Users Roles',
      roles: 'Roles',
    },
    accessLevels: {
      global: 'Global',
      perRegions: 'Per Regions',
      perVenues: 'Per Venues',
      perEvents: 'Per Events',
      perEventsGroups: 'Per Events Groups',
    },
    crudRoleGrantsPopup: {
      email: 'Email',
      status: 'Status',
      crudRoleLabel: 'All Roles',
      select: 'Select',
      venues: 'Venues',
      eventsGroups: 'Events Groups',
      regions: 'Regions',
      global: 'Global',
      events: 'Events',
    },
    crudRolePopup: {
      title: 'Title',
      accessLevel: 'Select access level',
      hasAllPermissions: 'Has all permissions',
      hasAccessToUsersManagement: 'Can manage users roles',
      hasAccessToVenueSettings: 'Can manage venue settings',
      hasAccessToRegionSettings: 'Can manage region settings',
      hasAccessToBookingsManagement: 'Can access Bookings page',
      canViewBookingFinancial: 'Can view booking financial',
      canManageBookingStatusOption: 'Can manage booking status option',
      hasAccessToEventsManagement: 'Can access Events page',
      canManageEventOnEventsPage: 'Can edit events on Events page',
      hasAccessToTicketsManagement: 'Can access Tickets page',
      canActivateEvent: 'Can activate event',
      canActivateTicketSales: 'Can activate ticket sales',
      error: "Can't find any role to update.",
      thereIsNothingToUpdate: 'There are nothing to update.',
    },
  },
  venuesSettings: {
    placeHolders: {
      searchByTitle: 'Search by title',
    },
  },
  tabLabels: {
    calendar: 'Calendar',
    social: 'Social',
    description: 'Description',
    teaser: 'Teaser',
  },
});

export { texts };
