import { DataStoreState } from './DataStoreContext';

export type DataStoreAction =
  | { type: 'SET_EVENTS_BASE_DAYS'; payload: number }
  | { type: 'SET_EVENTS_DAYS_FROM_NOW'; payload: number }
  | { type: 'SET_ACTIVE_EVENT_ID'; payload: number | null }
  | { type: 'SET_IS_SEARCHING_TICKET_SALES'; payload: boolean }
  | { type: 'SET_IS_SHOWING_ALL_EVENTS'; payload: boolean }
  | { type: 'SET_IS_CHECKIN_EVENTS_PAGE_ACTIVE'; venueDriverEventIDs: number[] }
  | { type: 'SET_IS_READY_TO_CLEAR_DATA_STORE'; payload: boolean }

export const dataStoreReducer = (state: DataStoreState, action: any) => {
  switch (action.type) {
    case 'SET_EVENTS_BASE_DAYS':
      return {
        ...state,
        eventsBaseDays: action.payload,
      };
    case 'SET_EVENTS_DAYS_FROM_NOW':
      return {
        ...state,
        eventsDaysFromNow: action.payload,
      };
    case 'SET_ACTIVE_EVENT_ID':
      return {
        ...state,
        activeEventID: action.payload,
      };
    case 'SET_ACTIVE_EVENT_GROUP_ID':
      return {
        ...state,
        activeEventGroupID: action.payload,
      };
    case 'SET_IS_SEARCHING_TICKET_SALES':
      return {
        ...state,
        isSearchingTicketSales: action.payload,
        isShowingAllEvents: false,
        isCheckinEventsPageActive: [],
      };
    case 'SET_IS_SHOWING_ALL_EVENTS':
      return {
        ...state,
        isSearchingTicketSales: false,
        isShowingAllEvents: action.payload,
        isCheckinEventsPageActive: [],
      };
    case 'SET_IS_CHECKIN_EVENTS_PAGE_ACTIVE':
      return {
        ...state,
        isSearchingTicketSales: false,
        isShowingAllEvents: false,
        isCheckinEventsPageActive: action.payload,
      };
    case 'SET_IS_READY_TO_CLEAR_DATA_STORE':
      return {
        ...state,
        isReadyToClearDataStore: action.payload,
      };

    default:
      return state;
  }
}
