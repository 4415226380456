// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { BookingFee, BookingBudget, ArtistRate, ArtistTier, BookingStatusOption, Booking, DateTypeOption, Date, Artist, Area, Region, Venue, TicketCategoryOptions, EventCategoryOptions, Event, Ticket, TicketSale, TicketSaleRedemption, TicketTier, EventsGroup, User, RoleGrant, Role, Price, Order, ImageURLs } = initSchema(schema);

export {
  BookingFee,
  BookingBudget,
  ArtistRate,
  ArtistTier,
  BookingStatusOption,
  Booking,
  DateTypeOption,
  Date,
  Artist,
  Area,
  Region,
  Venue,
  TicketCategoryOptions,
  EventCategoryOptions,
  Event,
  Ticket,
  TicketSale,
  TicketSaleRedemption,
  TicketTier,
  EventsGroup,
  User,
  RoleGrant,
  Role,
  Price,
  Order,
  ImageURLs
};