import React, { useEffect, useState, useCallback } from 'react';
import { Auth, DataStore, Hub, Predicates, syncExpression } from 'aws-amplify';
import { addDays, subDays } from 'date-fns';

import { dataStoreReducer } from './dataStoreReducer';
import { Date as ApiDate, Area, Artist, ArtistRate, ArtistTier, Booking, BookingBudget, BookingFee, BookingStatusOption, DateTypeOption, Event, EventCategoryOptions, EventsGroup, Order, Price, Ticket, TicketCategoryOptions, TicketSale, TicketSaleRedemption, TicketTier } from '../models';
import { useRouter } from 'next/router';

export interface DataStoreState {
  eventsBaseDays: number;
  eventsDaysFromNow: number;
  activeEventID: number | null;
  activeEventGroupID: string | null;
  isSearchingTicketSales: boolean;
  isShowingAllEvents: boolean;
  isCheckinEventsPageActive: number[];
  isReadyToClearDataStore: boolean;
}

export const dataStoreInitialState: DataStoreState = {
  eventsBaseDays: 4,
  eventsDaysFromNow: 6  ,
  activeEventID: null,
  activeEventGroupID: null,
  isSearchingTicketSales: false,
  isShowingAllEvents: false,
  isCheckinEventsPageActive: [],
  isReadyToClearDataStore: false,
};

export interface DataStoreContextProps {
  dataStoreState: DataStoreState;
  clearDataStore: () => Promise<boolean>;
  restartDataStore: () => Promise<boolean>;
  setActiveEventID: (eventID: number | null) => void;
  setActiveEventGroupID: (eventID: string | null) => void;
  setIsSearchingTicketSales: (isSearchingTicketSales: boolean) => void;
  setIsShowingAllEvents: (isShowingAllEvents: boolean) => void;
  setIsCheckinEventsPageActive: (venueDriverEventIDs: number[]) => void;
  setIsReadyToClearDataStore: (isReadyToClearDataStore: boolean) => void;
  logout: () => Promise<void>;
}

export const DataStoreContext = React.createContext({} as DataStoreContextProps);

export const DataStoreProvider = ({ children }: any) => {
  const router = useRouter();
  const [dataStoreState, dataStoreDispatch] = React.useReducer(dataStoreReducer, dataStoreInitialState);

  const {
    eventsBaseDays,
    eventsDaysFromNow,
    activeEventID,
    activeEventGroupID,
    isSearchingTicketSales,
    isShowingAllEvents,
    isCheckinEventsPageActive,
    isReadyToClearDataStore,
  } = dataStoreState;

  const baseDate = subDays(new Date(), eventsBaseDays).toISOString();
  const daysFromNow = addDays(new Date(), eventsDaysFromNow).toISOString();

  const setActiveEventID = (eventID: number | null) => {
    dataStoreDispatch({ type: 'SET_ACTIVE_EVENT_ID', payload: eventID });
  };

  const setActiveEventGroupID = (eventGroupID: string | null) => {
    dataStoreDispatch({ type: 'SET_ACTIVE_EVENT_GROUP_ID', payload: eventGroupID });
  };

  const setIsSearchingTicketSales = useCallback((value: boolean) => {
    dataStoreDispatch({ type: 'SET_IS_SEARCHING_TICKET_SALES', payload: value });
  }, [])

  const setIsShowingAllEvents = useCallback((value: boolean) => {
    dataStoreDispatch({ type: 'SET_IS_SHOWING_ALL_EVENTS', payload: value });
  }, [])

  const setIsCheckinEventsPageActive = useCallback((venueDriverEventIDs: number[]) => {
    dataStoreDispatch({ type: 'SET_IS_CHECKIN_EVENTS_PAGE_ACTIVE', payload: venueDriverEventIDs });
  }, [])

  const setIsReadyToClearDataStore = useCallback((value: boolean) => {
    dataStoreDispatch({ type: 'SET_IS_READY_TO_CLEAR_DATA_STORE', payload: value });
  }, [])

  useEffect(() => {
    DataStore.configure({
      syncExpressions: [
        syncExpression(ApiDate, () => {
          // return Predicates.ALL;
          // It's impractical to load "ALL" bookings.  We can't ever do that.
          // TODO: Add logic for making this sync expression context-based.
          return model => model.id('eq', '0');
        }),
        syncExpression(Area, () => {
          return model => model.id('eq', '0');
        }),
        syncExpression(Artist, () => {
          return model => model.id('eq', '0');
        }),
        syncExpression(ArtistRate, () => {
          return model => model.id('eq', '0');
        }),
        syncExpression(ArtistTier, () => {
          return model => model.id('eq', '0');
        }),
        syncExpression(Booking, () => {
          // return Predicates.ALL;
          // It's impractical to load "ALL" bookings.  We can't ever do that.
          // TODO: Add logic for making this sync expression context-based.
          return model => model.id('eq', '0');
        }),
        syncExpression(BookingFee, () => {
          // return Predicates.ALL;
          // It's impractical to load "ALL".  We can't ever do that.
          // TODO: Add logic for making this sync expression context-based.
          return model => model.id('eq', '0');
        }),
        syncExpression(BookingBudget, () => {
          // return Predicates.ALL;
          // It's impractical to load "ALL".  We can't ever do that.
          // TODO: Add logic for making this sync expression context-based.
          return model => model.id('eq', '0');
        }),
        syncExpression(BookingStatusOption, () => {
          return model => model.id('eq', '0');
        }),
        syncExpression(DateTypeOption, () => {
          return model => model.id('eq', '0');
        }),
        syncExpression(Event, () => {
          if (isShowingAllEvents) {
            // return Predicates.ALL;
            // It's impractical to load "ALL" events.  We can't ever do that.
            // TODO: Add logic for making this sync expression context-based.
            return model => model.id('eq', '0');
          }

          console.log("baseDate", baseDate)
          console.log("daysFromNow", daysFromNow)

          return event => event.date_string('gt', baseDate).date_string('lt', daysFromNow);
        }),
        syncExpression(EventCategoryOptions, () => {
          return model => model.id('eq', '0');
        }),
        syncExpression(EventsGroup, () => {
          return model => model.id('eq', '0');
        }),
        syncExpression(Order, () => {
          // return Predicates.ALL;
          // It's impractical to load "ALL".  We can't ever do that.
          // TODO: Add logic for making this sync expression context-based.
          return model => model.id('eq', '0');
        }),
        syncExpression(Price, () => {
          return model => model.id('eq', '0');
        }),
        syncExpression(Ticket, () => {
          // return Predicates.ALL;
          // It's impractical to load "ALL".  We can't ever do that.
          // TODO: Add logic for making this sync expression context-based.
          return model => model.id('eq', '0');
        }),
        syncExpression(TicketCategoryOptions, () => {
          return model => model.id('eq', '0');
        }),
        syncExpression(TicketTier, () => {
          // return Predicates.ALL;
          // It's impractical to load "ALL".  We can't ever do that.
          // TODO: Add logic for making this sync expression context-based.
          return model => model.id('eq', '0');
        }),
        syncExpression(TicketSale, () => {
          if (isSearchingTicketSales) {
            // return Predicates.ALL;
            // TODO: The search will need to use a GraphQL 'search' query to do the search instead ot trying to load all ticket sales and then filtering them.
            return model => model.id('eq', '0');
          }

          if (isCheckinEventsPageActive.length > 0) {
            // Don't return any TS on the main checkin page
            return ticketSale => ticketSale.id('eq', '0');
          }

          console.log("PREDICATE activeEventID", activeEventID)
          console.log("PREDICATE activeEventGroupID", activeEventGroupID)
          if (activeEventID != null) {
            if (activeEventGroupID) {
              // @ts-expect-error
              return ticketSale => ticketSale.or((ts) => [
                ts.eventGroupId("eq", activeEventGroupID),
                ts.venueDriverEventID("eq", parseInt(activeEventID))
              ])
            } else {
              return ticketSale => ticketSale.venueDriverEventID('eq', activeEventID);
            }
          }
          // If there isn't enough context then don't load any ticket sales yet.
          return ticketSale => ticketSale.id('eq', '0');
        }),
        syncExpression(TicketSaleRedemption, () => {
          if (activeEventID != null) {
            return ticketSaleRedemption => ticketSaleRedemption.venueDriverEventID('eq', activeEventID);
          }

          // If there isn't enough context then don't load any ticket sales redemptions yet.
          return model => model.id('eq', '0');
        }),
      ],
    });
  }, [isShowingAllEvents, isSearchingTicketSales, activeEventID, isCheckinEventsPageActive, baseDate, daysFromNow, activeEventGroupID]);

  useEffect(() => {
    let isMounted = true;
    DataStore.start();

    const listener = Hub.listen('datastore', async hubData => {
      const { event, data } = hubData.payload;

      if (isMounted) {
        if (event === 'modelSynced' && data.isFullSync || event === 'ready') {
          setIsReadyToClearDataStore(true);
        } else {
          setIsReadyToClearDataStore(false);
        }
      }
      // console.log('datastore hub event', { event, data });
    })

    return () => {
      isMounted = false;
      listener();
    };
  }, [setIsReadyToClearDataStore]);


  const clearDataStore = useCallback(async (): Promise<boolean> => {
    try {
      if (isReadyToClearDataStore) {
        console.log('clearing data store');
        await DataStore.clear();
        await DataStore.start();

        console.log('data store cleared');
        return Promise.resolve(true);
      } else {
        console.log('data store not ready to clear so not clearing');
      }
    } catch (error) {
      console.log('error clearing data store:', error);
    }

    return Promise.resolve(false);
  }, [isReadyToClearDataStore]);

  const restartDataStore = async () => {
    // console.log('restarting data store');
    // await DataStore.stop();
    // await DataStore.start();
    return Promise.resolve(true);
  };

  const logout = async () => {
    try {
      await clearDataStore();
      await Auth.signOut();
      router.push("/auth/login");
    } catch (error) {
      console.log("error logging out: ", error);
    }
  };

  return (
    <DataStoreContext.Provider value={{
      dataStoreState,
      clearDataStore,
      setActiveEventID,
      setActiveEventGroupID,
      setIsSearchingTicketSales,
      setIsShowingAllEvents,
      restartDataStore,
      setIsCheckinEventsPageActive,
      setIsReadyToClearDataStore,
      logout,
    }}>
      {children}
    </DataStoreContext.Provider>
  );
}
