import React, { createContext, useMemo, useReducer } from 'react';
import { authReducer } from './authReducer';
import { Permissions } from '../shared/Interfaces';
import { convertStringWithCommasToArray } from '../shared/GlobalHelper';
import { EventsGroup, Region, Venue } from '../API';

export interface AuthState {
  isAuthenticated: boolean;
  user: string;
  userID: string;
  role: 'admin' | 'staff' | null;
  permissions: Permissions;
}

export const defaultPermissions = (): Permissions => {
  return {
    canActivateEvent: false,
    canActivateTicketSales: false,
    canManageBookingStatusOption: false,
    canManageEventOnEventsPage: false,
    canViewBookingFinancial: false,
    hasAccessToBookingsManagement: false,
    hasAccessToEventsManagement: false,
    hasAccessToTicketsManagement: false,
    hasAccessToUsersManagement: false,
    hasAccessToVenueSettings: false,
    hasAccessToRegionSettings: false,
    hasAllPermissions: false,
    perEventsGroups: {
      bookings: null,
      events: null,
      tickets: null
    },
    perRegions: {
      bookings: null,
      events: null,
      tickets: null
    },
    perVenues: {
      bookings: null,
      events: null,
      tickets: null
    }
  };
};

export const authInitialState: AuthState = {
  isAuthenticated: false,
  user: '',
  userID: '',
  role: null,
  permissions: defaultPermissions()
};

export interface PermissionsChecks {
  isStaff: () => boolean;
  isAdmin: () => boolean;
  hasAccessToRolesManagement: () => boolean;

  hasAccessToTheVenueSettings: () => boolean;
  hasAccessToTheRegionSettings: () => boolean;

  hasAccessToBookings: () => boolean;
  hasAccessToViewBookingFinancial: () => boolean;
  hasAccessToManageBookingStatusOption: () => boolean;

  hasAccessToEvents: () => boolean;
  hasAccessToManageEventOnEventsPage: () => boolean;

  hasAccessToTickets: () => boolean;
  hasAccessToActivateEvent: () => boolean;
  hasAccessToActivateTicketSales: () => boolean;

  hasAccessBookingsPerRegions: (regions: Region[]) => Region[];
  hasAccessEventsPerRegions: (regions: Region[]) => Region[];
  hasAccessTicketsPerRegions: (regions: Region[]) => Region[];

  hasAccessBookingsPerVenues: (venues: Venue[]) => Venue[];
  hasAccessEventsPerVenues: (venues: Venue[]) => Venue[];
  hasAccessTicketsPerVenues: (venues: Venue[]) => Venue[];
  hasAccessGlobalPerVenues: (venues: Venue[]) => Venue[];

  hasAccessBookingsPerEventsGroups: (eventsGroups: EventsGroup[]) => EventsGroup[];
  hasAccessEventsPerEventsGroups: (eventsGroups: EventsGroup[]) => EventsGroup[];
  hasAccessTicketsPerEventsGroups: (eventsGroups: EventsGroup[]) => EventsGroup[];
}

export interface AuthContextProps {
  authState: AuthState;
  signIn: (user: string, userID: string, role: 'admin' | 'staff',  permissions: Permissions) => void;
  updatePermissions: (permissions: Permissions) => void;
  signOut: () => void;
  permissionsChecks: PermissionsChecks;

}

export const AuthContext = createContext({} as AuthContextProps);
AuthContext.displayName = 'AuthContext';

export const AuthProvider = ({ children }: any) => {
  const [authState, dispatch] = useReducer(authReducer, authInitialState);

  const {
    hasAllPermissions,
    hasAccessToUsersManagement,
    hasAccessToVenueSettings, hasAccessToRegionSettings,
    hasAccessToBookingsManagement, canViewBookingFinancial, canManageBookingStatusOption,
    hasAccessToEventsManagement, canManageEventOnEventsPage,
    hasAccessToTicketsManagement, canActivateEvent, canActivateTicketSales,
    perEventsGroups, perRegions, perVenues
  } = authState.permissions;

  const signIn = (user: string, userID: string, role: 'admin' | 'staff', permissions: Permissions) => {
    dispatch({ type: 'SIGN_IN', payload: { user, role, permissions } });
  };

  const updatePermissions = (permissions: Permissions) => {
    dispatch({ type: 'UPDATE_PERMISSIONS', payload: { permissions } });
  };

  const signOut = () => dispatch({ type: 'SIGN_OUT' });

  const isStaff = () => authState.role === 'staff';
  const isAdmin = () => authState.role === 'admin';

  const hasAccessToRolesManagement = () => {
    return hasAllPermissions || hasAccessToUsersManagement;
  }

  const hasAccessToTheVenueSettings = () => hasAllPermissions || hasAccessToVenueSettings;
  const hasAccessToTheRegionSettings = () => hasAllPermissions || hasAccessToRegionSettings;

  const hasAccessToBookings = () => hasAllPermissions || hasAccessToBookingsManagement;
  const hasAccessToViewBookingFinancial = () => hasAllPermissions || (hasAccessToBookingsManagement && canViewBookingFinancial);
  const hasAccessToManageBookingStatusOption = () => hasAllPermissions || (hasAccessToBookingsManagement && canManageBookingStatusOption);

  const hasAccessToEvents = () => hasAllPermissions || hasAccessToEventsManagement;
  const hasAccessToManageEventOnEventsPage = () => hasAllPermissions || (hasAccessToEventsManagement && canManageEventOnEventsPage);

  const hasAccessToTickets = () => hasAllPermissions || hasAccessToTicketsManagement;
  const hasAccessToActivateEvent = () => hasAllPermissions || (hasAccessToTicketsManagement && canActivateEvent);
  const hasAccessToActivateTicketSales = () => hasAllPermissions || (hasAccessToTicketsManagement && canActivateTicketSales);

  const hasAccessBookingsPerRegions = (regions: Region[]): Region[] => {
    if (hasAllPermissions || !perRegions.bookings || perRegions.bookings.length === 0) return regions;
    const ids = convertStringWithCommasToArray(perRegions.bookings);
    return regions.filter(region => ids.includes(region.id));
  };
  const hasAccessEventsPerRegions = (regions: Region[]): Region[] => {
    if (hasAllPermissions || !perRegions.events || perRegions.events.length === 0) return regions;
    const ids = convertStringWithCommasToArray(perRegions.events);
    return regions.filter(region => ids.includes(region.id));
  };
  const hasAccessTicketsPerRegions = (regions: Region[]): Region[] => {
    if (hasAllPermissions || !perRegions.tickets || perRegions.tickets.length === 0) return regions;
    const ids = convertStringWithCommasToArray(perRegions.tickets);
    return regions.filter(region => ids.includes(region.id));
  };

  const hasAccessBookingsPerVenues = (venues: Venue[]): Venue[] => {
    if (hasAllPermissions || !perVenues.bookings || perVenues.bookings.length === 0) return venues;
    const ids = convertStringWithCommasToArray(perVenues.bookings);
    return venues.filter(venue => ids.includes(venue.id));
  };
  const hasAccessEventsPerVenues = (venues: Venue[]): Venue[] => {
    if (hasAllPermissions || !perVenues.events || perVenues.events.length === 0) return venues;
    const ids = convertStringWithCommasToArray(perVenues.events);
    return venues.filter(venue => ids.includes(venue.id));
  };
  const hasAccessTicketsPerVenues = (venues: Venue[]): Venue[] => {
    if (hasAllPermissions || !perVenues.tickets || perVenues.tickets.length === 0) return venues;
    const ids = convertStringWithCommasToArray(perVenues.tickets);
    return venues.filter(venue => ids.includes(venue.id));
  };

  const hasAccessGlobalPerVenues = (venues: Venue[]): Venue[] => {
    if (hasAllPermissions) return venues;

    if (( !perVenues.bookings || perVenues.bookings.length === 0) &&
      (!perVenues.events || perVenues.events.length === 0) &&
      (!perVenues.tickets || perVenues.tickets.length === 0)
    ) return venues;

    const bookingsIds = perVenues.bookings ? convertStringWithCommasToArray(perVenues.bookings) : [];
    const eventsIds = perVenues.events ? convertStringWithCommasToArray(perVenues.events) : [];
    const ticketsIds = perVenues.tickets ? convertStringWithCommasToArray(perVenues.tickets) : [];

    return venues.filter(venue =>  bookingsIds.includes(venue.id) || eventsIds.includes(venue.id) || ticketsIds.includes(venue.id));
  };

  const hasAccessBookingsPerEventsGroups = (eventsGroups: EventsGroup[]): EventsGroup[] => {
    if (hasAllPermissions || !perEventsGroups.bookings || perEventsGroups.bookings.length === 0) return eventsGroups;
    const ids = convertStringWithCommasToArray(perEventsGroups.bookings);
    return eventsGroups.filter(item => ids.includes(item.id));
  };
  const hasAccessEventsPerEventsGroups = (eventsGroups: EventsGroup[]): EventsGroup[] => {
    if (hasAllPermissions || !perEventsGroups.events || perEventsGroups.events.length === 0) return eventsGroups;
    const ids = convertStringWithCommasToArray(perEventsGroups.events);
    return eventsGroups.filter(item => ids.includes(item.id));
  };
  const hasAccessTicketsPerEventsGroups = (eventsGroups: EventsGroup[]): EventsGroup[] => {
    if (hasAllPermissions || !perEventsGroups.tickets || perEventsGroups.tickets.length === 0) return eventsGroups;
    const ids = convertStringWithCommasToArray(perEventsGroups.tickets);
    return eventsGroups.filter(item => ids.includes(item.id));
  };

  const value = useMemo(() => ({ authState,
    signIn,
    updatePermissions,
    signOut,
    permissionsChecks: {
      isStaff,
      isAdmin,

      hasAccessToRolesManagement,  // +

      hasAccessToTheRegionSettings, // TODO: need to add this check to the dropdowns
      hasAccessToTheVenueSettings, // ODO: need to add this check to the dropdowns

      hasAccessToBookings, // +
      hasAccessToViewBookingFinancial, // +
      hasAccessToManageBookingStatusOption, // +

      hasAccessToEvents, // +
      hasAccessToManageEventOnEventsPage, // +

      hasAccessToTickets, // +
      hasAccessToActivateEvent, // +
      hasAccessToActivateTicketSales, // TODO: need to add to the table in the EventForDateModal (active checkbox)

      hasAccessBookingsPerRegions,
      hasAccessEventsPerRegions,
      hasAccessTicketsPerRegions,

      hasAccessBookingsPerVenues,
      hasAccessEventsPerVenues,
      hasAccessTicketsPerVenues,
      hasAccessGlobalPerVenues,

      hasAccessBookingsPerEventsGroups,
      hasAccessEventsPerEventsGroups,
      hasAccessTicketsPerEventsGroups,

      // hasAccessPerVenues,

    }
  }), [authState])

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};