import { useEffect, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { listVenues } from '../../graphql/queries';
import { Venue } from '../../API';
import { onUpdateVenue } from '../../graphql/subscriptions';

function VenuesSubscription() {
  const [venues, updateVenues] = useState<Venue[]>([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const responseData: any = await API.graphql(graphqlOperation(listVenues));
        if (responseData.data.listVenues && responseData.data.listVenues.items && responseData.data.listVenues.items.length > 0)
          updateVenues(responseData.data.listVenues.items);
        // console.log('fetch => listVenues', responseData.data.listVenues.items);
      } catch {(error: any) => console.log('venues error', error);}
    }

    fetchData();
  }, []);

  useEffect(() => {
    const subscription = (API.graphql(graphqlOperation(onUpdateVenue)) as any)
      .subscribe({
        next: (data: any) => {
          const { value: { data: { onUpdateVenue } } } = data;
          const newVenues = venues.filter(d => d.id !== onUpdateVenue.id);
          const updatedData: any = [...newVenues, onUpdateVenue];
          updateVenues(updatedData);
          // console.log('update action');
        }
      });

    return () => subscription.unsubscribe();
  }, [venues]);

  return venues;
}

export default VenuesSubscription;