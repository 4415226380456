/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateBookingFee = /* GraphQL */ `
  subscription OnCreateBookingFee(
    $filter: ModelSubscriptionBookingFeeFilterInput
  ) {
    onCreateBookingFee(filter: $filter) {
      id
      guaranteedFee
      bonusFee
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateBookingFee = /* GraphQL */ `
  subscription OnUpdateBookingFee(
    $filter: ModelSubscriptionBookingFeeFilterInput
  ) {
    onUpdateBookingFee(filter: $filter) {
      id
      guaranteedFee
      bonusFee
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteBookingFee = /* GraphQL */ `
  subscription OnDeleteBookingFee(
    $filter: ModelSubscriptionBookingFeeFilterInput
  ) {
    onDeleteBookingFee(filter: $filter) {
      id
      guaranteedFee
      bonusFee
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateBookingBudget = /* GraphQL */ `
  subscription OnCreateBookingBudget(
    $filter: ModelSubscriptionBookingBudgetFilterInput
  ) {
    onCreateBookingBudget(filter: $filter) {
      id
      venueID
      calendarMonth
      cost
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateBookingBudget = /* GraphQL */ `
  subscription OnUpdateBookingBudget(
    $filter: ModelSubscriptionBookingBudgetFilterInput
  ) {
    onUpdateBookingBudget(filter: $filter) {
      id
      venueID
      calendarMonth
      cost
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteBookingBudget = /* GraphQL */ `
  subscription OnDeleteBookingBudget(
    $filter: ModelSubscriptionBookingBudgetFilterInput
  ) {
    onDeleteBookingBudget(filter: $filter) {
      id
      venueID
      calendarMonth
      cost
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateArtistRate = /* GraphQL */ `
  subscription OnCreateArtistRate(
    $filter: ModelSubscriptionArtistRateFilterInput
  ) {
    onCreateArtistRate(filter: $filter) {
      id
      artistID
      name
      fee
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateArtistRate = /* GraphQL */ `
  subscription OnUpdateArtistRate(
    $filter: ModelSubscriptionArtistRateFilterInput
  ) {
    onUpdateArtistRate(filter: $filter) {
      id
      artistID
      name
      fee
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteArtistRate = /* GraphQL */ `
  subscription OnDeleteArtistRate(
    $filter: ModelSubscriptionArtistRateFilterInput
  ) {
    onDeleteArtistRate(filter: $filter) {
      id
      artistID
      name
      fee
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateArtistTier = /* GraphQL */ `
  subscription OnCreateArtistTier(
    $filter: ModelSubscriptionArtistTierFilterInput
  ) {
    onCreateArtistTier(filter: $filter) {
      id
      name
      minimumFee
      venueID
      Dates {
        items {
          id
          venueID
          calendarDate
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          artistTierDatesId
          dateTypeOptionDatesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateArtistTier = /* GraphQL */ `
  subscription OnUpdateArtistTier(
    $filter: ModelSubscriptionArtistTierFilterInput
  ) {
    onUpdateArtistTier(filter: $filter) {
      id
      name
      minimumFee
      venueID
      Dates {
        items {
          id
          venueID
          calendarDate
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          artistTierDatesId
          dateTypeOptionDatesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteArtistTier = /* GraphQL */ `
  subscription OnDeleteArtistTier(
    $filter: ModelSubscriptionArtistTierFilterInput
  ) {
    onDeleteArtistTier(filter: $filter) {
      id
      name
      minimumFee
      venueID
      Dates {
        items {
          id
          venueID
          calendarDate
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          artistTierDatesId
          dateTypeOptionDatesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateBookingStatusOption = /* GraphQL */ `
  subscription OnCreateBookingStatusOption(
    $filter: ModelSubscriptionBookingStatusOptionFilterInput
  ) {
    onCreateBookingStatusOption(filter: $filter) {
      id
      name
      default
      Booking {
        items {
          id
          dateID
          artistID
          eventID
          startTime
          endTime
          public
          announcementDate
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          bookingStatusOptionBookingId
          bookingBookingFeeId
          bookingAreaId
          bookingArtistTierId
          bookingArtistRateId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateBookingStatusOption = /* GraphQL */ `
  subscription OnUpdateBookingStatusOption(
    $filter: ModelSubscriptionBookingStatusOptionFilterInput
  ) {
    onUpdateBookingStatusOption(filter: $filter) {
      id
      name
      default
      Booking {
        items {
          id
          dateID
          artistID
          eventID
          startTime
          endTime
          public
          announcementDate
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          bookingStatusOptionBookingId
          bookingBookingFeeId
          bookingAreaId
          bookingArtistTierId
          bookingArtistRateId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteBookingStatusOption = /* GraphQL */ `
  subscription OnDeleteBookingStatusOption(
    $filter: ModelSubscriptionBookingStatusOptionFilterInput
  ) {
    onDeleteBookingStatusOption(filter: $filter) {
      id
      name
      default
      Booking {
        items {
          id
          dateID
          artistID
          eventID
          startTime
          endTime
          public
          announcementDate
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          bookingStatusOptionBookingId
          bookingBookingFeeId
          bookingAreaId
          bookingArtistTierId
          bookingArtistRateId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateBooking = /* GraphQL */ `
  subscription OnCreateBooking($filter: ModelSubscriptionBookingFilterInput) {
    onCreateBooking(filter: $filter) {
      id
      dateID
      artistID
      eventID
      startTime
      endTime
      public
      announcementDate
      notes
      BookingFee {
        id
        guaranteedFee
        bonusFee
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Area {
        id
        venueID
        name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      ArtistTier {
        id
        name
        minimumFee
        venueID
        Dates {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      ArtistRate {
        id
        artistID
        name
        fee
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      status {
        id
        name
        default
        Booking {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      bookingStatusOptionBookingId
      bookingBookingFeeId
      bookingAreaId
      bookingArtistTierId
      bookingArtistRateId
      __typename
    }
  }
`;
export const onUpdateBooking = /* GraphQL */ `
  subscription OnUpdateBooking($filter: ModelSubscriptionBookingFilterInput) {
    onUpdateBooking(filter: $filter) {
      id
      dateID
      artistID
      eventID
      startTime
      endTime
      public
      announcementDate
      notes
      BookingFee {
        id
        guaranteedFee
        bonusFee
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Area {
        id
        venueID
        name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      ArtistTier {
        id
        name
        minimumFee
        venueID
        Dates {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      ArtistRate {
        id
        artistID
        name
        fee
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      status {
        id
        name
        default
        Booking {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      bookingStatusOptionBookingId
      bookingBookingFeeId
      bookingAreaId
      bookingArtistTierId
      bookingArtistRateId
      __typename
    }
  }
`;
export const onDeleteBooking = /* GraphQL */ `
  subscription OnDeleteBooking($filter: ModelSubscriptionBookingFilterInput) {
    onDeleteBooking(filter: $filter) {
      id
      dateID
      artistID
      eventID
      startTime
      endTime
      public
      announcementDate
      notes
      BookingFee {
        id
        guaranteedFee
        bonusFee
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Area {
        id
        venueID
        name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      ArtistTier {
        id
        name
        minimumFee
        venueID
        Dates {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      ArtistRate {
        id
        artistID
        name
        fee
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      status {
        id
        name
        default
        Booking {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      bookingStatusOptionBookingId
      bookingBookingFeeId
      bookingAreaId
      bookingArtistTierId
      bookingArtistRateId
      __typename
    }
  }
`;
export const onCreateDateTypeOption = /* GraphQL */ `
  subscription OnCreateDateTypeOption(
    $filter: ModelSubscriptionDateTypeOptionFilterInput
  ) {
    onCreateDateTypeOption(filter: $filter) {
      id
      name
      default
      Dates {
        items {
          id
          venueID
          calendarDate
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          artistTierDatesId
          dateTypeOptionDatesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateDateTypeOption = /* GraphQL */ `
  subscription OnUpdateDateTypeOption(
    $filter: ModelSubscriptionDateTypeOptionFilterInput
  ) {
    onUpdateDateTypeOption(filter: $filter) {
      id
      name
      default
      Dates {
        items {
          id
          venueID
          calendarDate
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          artistTierDatesId
          dateTypeOptionDatesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteDateTypeOption = /* GraphQL */ `
  subscription OnDeleteDateTypeOption(
    $filter: ModelSubscriptionDateTypeOptionFilterInput
  ) {
    onDeleteDateTypeOption(filter: $filter) {
      id
      name
      default
      Dates {
        items {
          id
          venueID
          calendarDate
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          artistTierDatesId
          dateTypeOptionDatesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateDate = /* GraphQL */ `
  subscription OnCreateDate($filter: ModelSubscriptionDateFilterInput) {
    onCreateDate(filter: $filter) {
      id
      venueID
      calendarDate
      notes
      Bookings {
        items {
          id
          dateID
          artistID
          eventID
          startTime
          endTime
          public
          announcementDate
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          bookingStatusOptionBookingId
          bookingBookingFeeId
          bookingAreaId
          bookingArtistTierId
          bookingArtistRateId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      DateTypeOption {
        id
        name
        default
        Dates {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      artistTier {
        id
        name
        minimumFee
        venueID
        Dates {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      artistTierDatesId
      dateTypeOptionDatesId
      __typename
    }
  }
`;
export const onUpdateDate = /* GraphQL */ `
  subscription OnUpdateDate($filter: ModelSubscriptionDateFilterInput) {
    onUpdateDate(filter: $filter) {
      id
      venueID
      calendarDate
      notes
      Bookings {
        items {
          id
          dateID
          artistID
          eventID
          startTime
          endTime
          public
          announcementDate
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          bookingStatusOptionBookingId
          bookingBookingFeeId
          bookingAreaId
          bookingArtistTierId
          bookingArtistRateId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      DateTypeOption {
        id
        name
        default
        Dates {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      artistTier {
        id
        name
        minimumFee
        venueID
        Dates {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      artistTierDatesId
      dateTypeOptionDatesId
      __typename
    }
  }
`;
export const onDeleteDate = /* GraphQL */ `
  subscription OnDeleteDate($filter: ModelSubscriptionDateFilterInput) {
    onDeleteDate(filter: $filter) {
      id
      venueID
      calendarDate
      notes
      Bookings {
        items {
          id
          dateID
          artistID
          eventID
          startTime
          endTime
          public
          announcementDate
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          bookingStatusOptionBookingId
          bookingBookingFeeId
          bookingAreaId
          bookingArtistTierId
          bookingArtistRateId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      DateTypeOption {
        id
        name
        default
        Dates {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      artistTier {
        id
        name
        minimumFee
        venueID
        Dates {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      artistTierDatesId
      dateTypeOptionDatesId
      __typename
    }
  }
`;
export const onCreateArtist = /* GraphQL */ `
  subscription OnCreateArtist($filter: ModelSubscriptionArtistFilterInput) {
    onCreateArtist(filter: $filter) {
      id
      venueDriverArtistID
      name
      friendlyID
      Bookings {
        items {
          id
          dateID
          artistID
          eventID
          startTime
          endTime
          public
          announcementDate
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          bookingStatusOptionBookingId
          bookingBookingFeeId
          bookingAreaId
          bookingArtistTierId
          bookingArtistRateId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ArtistRates {
        items {
          id
          artistID
          name
          fee
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      eventVenueDriverSupportingArtistsId
      __typename
    }
  }
`;
export const onUpdateArtist = /* GraphQL */ `
  subscription OnUpdateArtist($filter: ModelSubscriptionArtistFilterInput) {
    onUpdateArtist(filter: $filter) {
      id
      venueDriverArtistID
      name
      friendlyID
      Bookings {
        items {
          id
          dateID
          artistID
          eventID
          startTime
          endTime
          public
          announcementDate
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          bookingStatusOptionBookingId
          bookingBookingFeeId
          bookingAreaId
          bookingArtistTierId
          bookingArtistRateId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ArtistRates {
        items {
          id
          artistID
          name
          fee
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      eventVenueDriverSupportingArtistsId
      __typename
    }
  }
`;
export const onDeleteArtist = /* GraphQL */ `
  subscription OnDeleteArtist($filter: ModelSubscriptionArtistFilterInput) {
    onDeleteArtist(filter: $filter) {
      id
      venueDriverArtistID
      name
      friendlyID
      Bookings {
        items {
          id
          dateID
          artistID
          eventID
          startTime
          endTime
          public
          announcementDate
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          bookingStatusOptionBookingId
          bookingBookingFeeId
          bookingAreaId
          bookingArtistTierId
          bookingArtistRateId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ArtistRates {
        items {
          id
          artistID
          name
          fee
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      eventVenueDriverSupportingArtistsId
      __typename
    }
  }
`;
export const onCreateArea = /* GraphQL */ `
  subscription OnCreateArea($filter: ModelSubscriptionAreaFilterInput) {
    onCreateArea(filter: $filter) {
      id
      venueID
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateArea = /* GraphQL */ `
  subscription OnUpdateArea($filter: ModelSubscriptionAreaFilterInput) {
    onUpdateArea(filter: $filter) {
      id
      venueID
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteArea = /* GraphQL */ `
  subscription OnDeleteArea($filter: ModelSubscriptionAreaFilterInput) {
    onDeleteArea(filter: $filter) {
      id
      venueID
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateRegion = /* GraphQL */ `
  subscription OnCreateRegion($filter: ModelSubscriptionRegionFilterInput) {
    onCreateRegion(filter: $filter) {
      id
      venueDriverRegionID
      Venues {
        items {
          id
          regionID
          venueDriverVenueID
          venueDriverRegionID
          name
          image_URL
          address
          city
          friendlyID
          position
          type
          time_zone
          color
          hide_party_passes
          timeRanges
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      name
      slug
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateRegion = /* GraphQL */ `
  subscription OnUpdateRegion($filter: ModelSubscriptionRegionFilterInput) {
    onUpdateRegion(filter: $filter) {
      id
      venueDriverRegionID
      Venues {
        items {
          id
          regionID
          venueDriverVenueID
          venueDriverRegionID
          name
          image_URL
          address
          city
          friendlyID
          position
          type
          time_zone
          color
          hide_party_passes
          timeRanges
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      name
      slug
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteRegion = /* GraphQL */ `
  subscription OnDeleteRegion($filter: ModelSubscriptionRegionFilterInput) {
    onDeleteRegion(filter: $filter) {
      id
      venueDriverRegionID
      Venues {
        items {
          id
          regionID
          venueDriverVenueID
          venueDriverRegionID
          name
          image_URL
          address
          city
          friendlyID
          position
          type
          time_zone
          color
          hide_party_passes
          timeRanges
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      name
      slug
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateVenue = /* GraphQL */ `
  subscription OnCreateVenue($filter: ModelSubscriptionVenueFilterInput) {
    onCreateVenue(filter: $filter) {
      id
      regionID
      venueDriverVenueID
      venueDriverRegionID
      Dates {
        items {
          id
          venueID
          calendarDate
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          artistTierDatesId
          dateTypeOptionDatesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Areas {
        items {
          id
          venueID
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      BookingBudgets {
        items {
          id
          venueID
          calendarMonth
          cost
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ArtistTiers {
        items {
          id
          name
          minimumFee
          venueID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Events {
        items {
          id
          venueID
          venueDriverEventID
          venueDriverVenueID
          title
          description
          time_zone
          date
          date_string
          open
          open_string
          close
          close_string
          closed_message
          VIP_URL
          currentTicketTierID
          ticketTierIDs
          stopSellingTicketsDate
          stopSellingTicketsTime
          tag
          website
          isEnableLoyaltyProgram
          isAllowedDollarsReward
          isAllowedPointsReward
          categories
          supportEmail
          organizerEmail
          servicePhone
          teaser
          isHideEndTime
          isHideStartTime
          template
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          eventsGroupEventsId
          eventVenueDriverHeadlinerArtistId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      name
      image_URL
      address
      city
      friendlyID
      position
      type
      time_zone
      color
      hide_party_passes
      timeRanges
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateVenue = /* GraphQL */ `
  subscription OnUpdateVenue($filter: ModelSubscriptionVenueFilterInput) {
    onUpdateVenue(filter: $filter) {
      id
      regionID
      venueDriverVenueID
      venueDriverRegionID
      Dates {
        items {
          id
          venueID
          calendarDate
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          artistTierDatesId
          dateTypeOptionDatesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Areas {
        items {
          id
          venueID
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      BookingBudgets {
        items {
          id
          venueID
          calendarMonth
          cost
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ArtistTiers {
        items {
          id
          name
          minimumFee
          venueID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Events {
        items {
          id
          venueID
          venueDriverEventID
          venueDriverVenueID
          title
          description
          time_zone
          date
          date_string
          open
          open_string
          close
          close_string
          closed_message
          VIP_URL
          currentTicketTierID
          ticketTierIDs
          stopSellingTicketsDate
          stopSellingTicketsTime
          tag
          website
          isEnableLoyaltyProgram
          isAllowedDollarsReward
          isAllowedPointsReward
          categories
          supportEmail
          organizerEmail
          servicePhone
          teaser
          isHideEndTime
          isHideStartTime
          template
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          eventsGroupEventsId
          eventVenueDriverHeadlinerArtistId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      name
      image_URL
      address
      city
      friendlyID
      position
      type
      time_zone
      color
      hide_party_passes
      timeRanges
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteVenue = /* GraphQL */ `
  subscription OnDeleteVenue($filter: ModelSubscriptionVenueFilterInput) {
    onDeleteVenue(filter: $filter) {
      id
      regionID
      venueDriverVenueID
      venueDriverRegionID
      Dates {
        items {
          id
          venueID
          calendarDate
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          artistTierDatesId
          dateTypeOptionDatesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Areas {
        items {
          id
          venueID
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      BookingBudgets {
        items {
          id
          venueID
          calendarMonth
          cost
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ArtistTiers {
        items {
          id
          name
          minimumFee
          venueID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Events {
        items {
          id
          venueID
          venueDriverEventID
          venueDriverVenueID
          title
          description
          time_zone
          date
          date_string
          open
          open_string
          close
          close_string
          closed_message
          VIP_URL
          currentTicketTierID
          ticketTierIDs
          stopSellingTicketsDate
          stopSellingTicketsTime
          tag
          website
          isEnableLoyaltyProgram
          isAllowedDollarsReward
          isAllowedPointsReward
          categories
          supportEmail
          organizerEmail
          servicePhone
          teaser
          isHideEndTime
          isHideStartTime
          template
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          eventsGroupEventsId
          eventVenueDriverHeadlinerArtistId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      name
      image_URL
      address
      city
      friendlyID
      position
      type
      time_zone
      color
      hide_party_passes
      timeRanges
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateTicketCategoryOptions = /* GraphQL */ `
  subscription OnCreateTicketCategoryOptions(
    $filter: ModelSubscriptionTicketCategoryOptionsFilterInput
  ) {
    onCreateTicketCategoryOptions(filter: $filter) {
      id
      title
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateTicketCategoryOptions = /* GraphQL */ `
  subscription OnUpdateTicketCategoryOptions(
    $filter: ModelSubscriptionTicketCategoryOptionsFilterInput
  ) {
    onUpdateTicketCategoryOptions(filter: $filter) {
      id
      title
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteTicketCategoryOptions = /* GraphQL */ `
  subscription OnDeleteTicketCategoryOptions(
    $filter: ModelSubscriptionTicketCategoryOptionsFilterInput
  ) {
    onDeleteTicketCategoryOptions(filter: $filter) {
      id
      title
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateEventCategoryOptions = /* GraphQL */ `
  subscription OnCreateEventCategoryOptions(
    $filter: ModelSubscriptionEventCategoryOptionsFilterInput
  ) {
    onCreateEventCategoryOptions(filter: $filter) {
      id
      title
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateEventCategoryOptions = /* GraphQL */ `
  subscription OnUpdateEventCategoryOptions(
    $filter: ModelSubscriptionEventCategoryOptionsFilterInput
  ) {
    onUpdateEventCategoryOptions(filter: $filter) {
      id
      title
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteEventCategoryOptions = /* GraphQL */ `
  subscription OnDeleteEventCategoryOptions(
    $filter: ModelSubscriptionEventCategoryOptionsFilterInput
  ) {
    onDeleteEventCategoryOptions(filter: $filter) {
      id
      title
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateEvent = /* GraphQL */ `
  subscription OnCreateEvent($filter: ModelSubscriptionEventFilterInput) {
    onCreateEvent(filter: $filter) {
      id
      venueID
      venueDriverEventID
      venueDriverVenueID
      title
      description
      time_zone
      date
      date_string
      open
      open_string
      close
      close_string
      closed_message
      VIP_URL
      eventsGroup {
        id
        name
        events {
          nextToken
          startedAt
          __typename
        }
        venueID
        venueDriverPackageTicketID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      currentTicketTierID
      ticketTierIDs
      stopSellingTicketsDate
      stopSellingTicketsTime
      tag
      website
      isEnableLoyaltyProgram
      isAllowedDollarsReward
      isAllowedPointsReward
      categories
      supportEmail
      organizerEmail
      servicePhone
      teaser
      isHideEndTime
      isHideStartTime
      images {
        social
        calendar
        __typename
      }
      template
      Bookings {
        items {
          id
          dateID
          artistID
          eventID
          startTime
          endTime
          public
          announcementDate
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          bookingStatusOptionBookingId
          bookingBookingFeeId
          bookingAreaId
          bookingArtistTierId
          bookingArtistRateId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      VenueDriverTickets {
        items {
          id
          eventID
          ticketPackageEventIDs
          regionID
          venueDriverTicketID
          venueDriverParentTicketID
          venueDriverEventID
          title
          activeForSales
          wasActivatedForSales
          type
          description
          price
          priceID
          number_available
          price_description
          category
          is_wristband_ticket
          is_template
          is_reusable
          onSaleStartDate
          onSaleStartTime
          onSaleEndDate
          onSaleEndTime
          ticketTiers
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      VenueDriverTicketSales {
        items {
          id
          ticketID
          eventID
          venueDriverTicketSaleID
          venueDriverTicketID
          venueDriverEventID
          venueDriverOrderID
          final
          email
          quantity
          first
          last
          code
          order_id
          access_token
          category
          subtotal_currency
          let_tax_amount_currency
          sales_tax_currency
          surcharge_currency
          total_currency
          service_charge_currency
          ticket_title
          event_date
          event_time
          event_name
          venue_title
          price
          is_package_ticket
          let_tax_disclaimer_msg
          ticket_type_label
          is_wristband_sale
          type
          venueDriverParentTicketSaleID
          venueDriverParentTicketSaleCode
          wristband_pickup_at
          wristband_pickup_venue_id
          wristband_pickup_event_id
          original_code
          isAssigned
          void
          voidedAt
          voidedReason
          refunded
          refundedSurcharge
          refundedAt
          refundedReason
          eventGroupId
          sourceSystem
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      VenueDriverTicketSaleRedemption {
        items {
          id
          ticketID
          ticketSaleID
          eventID
          venueDriverTicketSaleRedemptionID
          venueDriverTicketID
          venueDriverTicketSaleID
          venueDriverEventID
          void
          redeemedAt
          sourceSystem
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userTicketSaleRedemptionsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      venueDriverHeadlinerArtist {
        id
        venueDriverArtistID
        name
        friendlyID
        Bookings {
          nextToken
          startedAt
          __typename
        }
        ArtistRates {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        eventVenueDriverSupportingArtistsId
        __typename
      }
      venueDriverSupportingArtists {
        items {
          id
          venueDriverArtistID
          name
          friendlyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          eventVenueDriverSupportingArtistsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      eventsGroupEventsId
      eventVenueDriverHeadlinerArtistId
      __typename
    }
  }
`;
export const onUpdateEvent = /* GraphQL */ `
  subscription OnUpdateEvent($filter: ModelSubscriptionEventFilterInput) {
    onUpdateEvent(filter: $filter) {
      id
      venueID
      venueDriverEventID
      venueDriverVenueID
      title
      description
      time_zone
      date
      date_string
      open
      open_string
      close
      close_string
      closed_message
      VIP_URL
      eventsGroup {
        id
        name
        events {
          nextToken
          startedAt
          __typename
        }
        venueID
        venueDriverPackageTicketID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      currentTicketTierID
      ticketTierIDs
      stopSellingTicketsDate
      stopSellingTicketsTime
      tag
      website
      isEnableLoyaltyProgram
      isAllowedDollarsReward
      isAllowedPointsReward
      categories
      supportEmail
      organizerEmail
      servicePhone
      teaser
      isHideEndTime
      isHideStartTime
      images {
        social
        calendar
        __typename
      }
      template
      Bookings {
        items {
          id
          dateID
          artistID
          eventID
          startTime
          endTime
          public
          announcementDate
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          bookingStatusOptionBookingId
          bookingBookingFeeId
          bookingAreaId
          bookingArtistTierId
          bookingArtistRateId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      VenueDriverTickets {
        items {
          id
          eventID
          ticketPackageEventIDs
          regionID
          venueDriverTicketID
          venueDriverParentTicketID
          venueDriverEventID
          title
          activeForSales
          wasActivatedForSales
          type
          description
          price
          priceID
          number_available
          price_description
          category
          is_wristband_ticket
          is_template
          is_reusable
          onSaleStartDate
          onSaleStartTime
          onSaleEndDate
          onSaleEndTime
          ticketTiers
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      VenueDriverTicketSales {
        items {
          id
          ticketID
          eventID
          venueDriverTicketSaleID
          venueDriverTicketID
          venueDriverEventID
          venueDriverOrderID
          final
          email
          quantity
          first
          last
          code
          order_id
          access_token
          category
          subtotal_currency
          let_tax_amount_currency
          sales_tax_currency
          surcharge_currency
          total_currency
          service_charge_currency
          ticket_title
          event_date
          event_time
          event_name
          venue_title
          price
          is_package_ticket
          let_tax_disclaimer_msg
          ticket_type_label
          is_wristband_sale
          type
          venueDriverParentTicketSaleID
          venueDriverParentTicketSaleCode
          wristband_pickup_at
          wristband_pickup_venue_id
          wristband_pickup_event_id
          original_code
          isAssigned
          void
          voidedAt
          voidedReason
          refunded
          refundedSurcharge
          refundedAt
          refundedReason
          eventGroupId
          sourceSystem
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      VenueDriverTicketSaleRedemption {
        items {
          id
          ticketID
          ticketSaleID
          eventID
          venueDriverTicketSaleRedemptionID
          venueDriverTicketID
          venueDriverTicketSaleID
          venueDriverEventID
          void
          redeemedAt
          sourceSystem
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userTicketSaleRedemptionsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      venueDriverHeadlinerArtist {
        id
        venueDriverArtistID
        name
        friendlyID
        Bookings {
          nextToken
          startedAt
          __typename
        }
        ArtistRates {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        eventVenueDriverSupportingArtistsId
        __typename
      }
      venueDriverSupportingArtists {
        items {
          id
          venueDriverArtistID
          name
          friendlyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          eventVenueDriverSupportingArtistsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      eventsGroupEventsId
      eventVenueDriverHeadlinerArtistId
      __typename
    }
  }
`;
export const onDeleteEvent = /* GraphQL */ `
  subscription OnDeleteEvent($filter: ModelSubscriptionEventFilterInput) {
    onDeleteEvent(filter: $filter) {
      id
      venueID
      venueDriverEventID
      venueDriverVenueID
      title
      description
      time_zone
      date
      date_string
      open
      open_string
      close
      close_string
      closed_message
      VIP_URL
      eventsGroup {
        id
        name
        events {
          nextToken
          startedAt
          __typename
        }
        venueID
        venueDriverPackageTicketID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      currentTicketTierID
      ticketTierIDs
      stopSellingTicketsDate
      stopSellingTicketsTime
      tag
      website
      isEnableLoyaltyProgram
      isAllowedDollarsReward
      isAllowedPointsReward
      categories
      supportEmail
      organizerEmail
      servicePhone
      teaser
      isHideEndTime
      isHideStartTime
      images {
        social
        calendar
        __typename
      }
      template
      Bookings {
        items {
          id
          dateID
          artistID
          eventID
          startTime
          endTime
          public
          announcementDate
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          bookingStatusOptionBookingId
          bookingBookingFeeId
          bookingAreaId
          bookingArtistTierId
          bookingArtistRateId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      VenueDriverTickets {
        items {
          id
          eventID
          ticketPackageEventIDs
          regionID
          venueDriverTicketID
          venueDriverParentTicketID
          venueDriverEventID
          title
          activeForSales
          wasActivatedForSales
          type
          description
          price
          priceID
          number_available
          price_description
          category
          is_wristband_ticket
          is_template
          is_reusable
          onSaleStartDate
          onSaleStartTime
          onSaleEndDate
          onSaleEndTime
          ticketTiers
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      VenueDriverTicketSales {
        items {
          id
          ticketID
          eventID
          venueDriverTicketSaleID
          venueDriverTicketID
          venueDriverEventID
          venueDriverOrderID
          final
          email
          quantity
          first
          last
          code
          order_id
          access_token
          category
          subtotal_currency
          let_tax_amount_currency
          sales_tax_currency
          surcharge_currency
          total_currency
          service_charge_currency
          ticket_title
          event_date
          event_time
          event_name
          venue_title
          price
          is_package_ticket
          let_tax_disclaimer_msg
          ticket_type_label
          is_wristband_sale
          type
          venueDriverParentTicketSaleID
          venueDriverParentTicketSaleCode
          wristband_pickup_at
          wristband_pickup_venue_id
          wristband_pickup_event_id
          original_code
          isAssigned
          void
          voidedAt
          voidedReason
          refunded
          refundedSurcharge
          refundedAt
          refundedReason
          eventGroupId
          sourceSystem
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      VenueDriverTicketSaleRedemption {
        items {
          id
          ticketID
          ticketSaleID
          eventID
          venueDriverTicketSaleRedemptionID
          venueDriverTicketID
          venueDriverTicketSaleID
          venueDriverEventID
          void
          redeemedAt
          sourceSystem
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userTicketSaleRedemptionsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      venueDriverHeadlinerArtist {
        id
        venueDriverArtistID
        name
        friendlyID
        Bookings {
          nextToken
          startedAt
          __typename
        }
        ArtistRates {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        eventVenueDriverSupportingArtistsId
        __typename
      }
      venueDriverSupportingArtists {
        items {
          id
          venueDriverArtistID
          name
          friendlyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          eventVenueDriverSupportingArtistsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      eventsGroupEventsId
      eventVenueDriverHeadlinerArtistId
      __typename
    }
  }
`;
export const onCreateTicket = /* GraphQL */ `
  subscription OnCreateTicket($filter: ModelSubscriptionTicketFilterInput) {
    onCreateTicket(filter: $filter) {
      id
      eventID
      ticketPackageEventIDs
      regionID
      venueDriverTicketID
      venueDriverParentTicketID
      venueDriverEventID
      title
      activeForSales
      wasActivatedForSales
      type
      description
      price
      priceID
      number_available
      price_description
      category
      is_wristband_ticket
      is_template
      is_reusable
      onSaleStartDate
      onSaleStartTime
      onSaleEndDate
      onSaleEndTime
      ticketTiers
      VenueDriverTicketSales {
        items {
          id
          ticketID
          eventID
          venueDriverTicketSaleID
          venueDriverTicketID
          venueDriverEventID
          venueDriverOrderID
          final
          email
          quantity
          first
          last
          code
          order_id
          access_token
          category
          subtotal_currency
          let_tax_amount_currency
          sales_tax_currency
          surcharge_currency
          total_currency
          service_charge_currency
          ticket_title
          event_date
          event_time
          event_name
          venue_title
          price
          is_package_ticket
          let_tax_disclaimer_msg
          ticket_type_label
          is_wristband_sale
          type
          venueDriverParentTicketSaleID
          venueDriverParentTicketSaleCode
          wristband_pickup_at
          wristband_pickup_venue_id
          wristband_pickup_event_id
          original_code
          isAssigned
          void
          voidedAt
          voidedReason
          refunded
          refundedSurcharge
          refundedAt
          refundedReason
          eventGroupId
          sourceSystem
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      VenueDriverTicketSaleRedemption {
        items {
          id
          ticketID
          ticketSaleID
          eventID
          venueDriverTicketSaleRedemptionID
          venueDriverTicketID
          venueDriverTicketSaleID
          venueDriverEventID
          void
          redeemedAt
          sourceSystem
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userTicketSaleRedemptionsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateTicket = /* GraphQL */ `
  subscription OnUpdateTicket($filter: ModelSubscriptionTicketFilterInput) {
    onUpdateTicket(filter: $filter) {
      id
      eventID
      ticketPackageEventIDs
      regionID
      venueDriverTicketID
      venueDriverParentTicketID
      venueDriverEventID
      title
      activeForSales
      wasActivatedForSales
      type
      description
      price
      priceID
      number_available
      price_description
      category
      is_wristband_ticket
      is_template
      is_reusable
      onSaleStartDate
      onSaleStartTime
      onSaleEndDate
      onSaleEndTime
      ticketTiers
      VenueDriverTicketSales {
        items {
          id
          ticketID
          eventID
          venueDriverTicketSaleID
          venueDriverTicketID
          venueDriverEventID
          venueDriverOrderID
          final
          email
          quantity
          first
          last
          code
          order_id
          access_token
          category
          subtotal_currency
          let_tax_amount_currency
          sales_tax_currency
          surcharge_currency
          total_currency
          service_charge_currency
          ticket_title
          event_date
          event_time
          event_name
          venue_title
          price
          is_package_ticket
          let_tax_disclaimer_msg
          ticket_type_label
          is_wristband_sale
          type
          venueDriverParentTicketSaleID
          venueDriverParentTicketSaleCode
          wristband_pickup_at
          wristband_pickup_venue_id
          wristband_pickup_event_id
          original_code
          isAssigned
          void
          voidedAt
          voidedReason
          refunded
          refundedSurcharge
          refundedAt
          refundedReason
          eventGroupId
          sourceSystem
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      VenueDriverTicketSaleRedemption {
        items {
          id
          ticketID
          ticketSaleID
          eventID
          venueDriverTicketSaleRedemptionID
          venueDriverTicketID
          venueDriverTicketSaleID
          venueDriverEventID
          void
          redeemedAt
          sourceSystem
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userTicketSaleRedemptionsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteTicket = /* GraphQL */ `
  subscription OnDeleteTicket($filter: ModelSubscriptionTicketFilterInput) {
    onDeleteTicket(filter: $filter) {
      id
      eventID
      ticketPackageEventIDs
      regionID
      venueDriverTicketID
      venueDriverParentTicketID
      venueDriverEventID
      title
      activeForSales
      wasActivatedForSales
      type
      description
      price
      priceID
      number_available
      price_description
      category
      is_wristband_ticket
      is_template
      is_reusable
      onSaleStartDate
      onSaleStartTime
      onSaleEndDate
      onSaleEndTime
      ticketTiers
      VenueDriverTicketSales {
        items {
          id
          ticketID
          eventID
          venueDriverTicketSaleID
          venueDriverTicketID
          venueDriverEventID
          venueDriverOrderID
          final
          email
          quantity
          first
          last
          code
          order_id
          access_token
          category
          subtotal_currency
          let_tax_amount_currency
          sales_tax_currency
          surcharge_currency
          total_currency
          service_charge_currency
          ticket_title
          event_date
          event_time
          event_name
          venue_title
          price
          is_package_ticket
          let_tax_disclaimer_msg
          ticket_type_label
          is_wristband_sale
          type
          venueDriverParentTicketSaleID
          venueDriverParentTicketSaleCode
          wristband_pickup_at
          wristband_pickup_venue_id
          wristband_pickup_event_id
          original_code
          isAssigned
          void
          voidedAt
          voidedReason
          refunded
          refundedSurcharge
          refundedAt
          refundedReason
          eventGroupId
          sourceSystem
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      VenueDriverTicketSaleRedemption {
        items {
          id
          ticketID
          ticketSaleID
          eventID
          venueDriverTicketSaleRedemptionID
          venueDriverTicketID
          venueDriverTicketSaleID
          venueDriverEventID
          void
          redeemedAt
          sourceSystem
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userTicketSaleRedemptionsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateTicketSale = /* GraphQL */ `
  subscription OnCreateTicketSale(
    $filter: ModelSubscriptionTicketSaleFilterInput
  ) {
    onCreateTicketSale(filter: $filter) {
      id
      ticketID
      eventID
      venueDriverTicketSaleID
      venueDriverTicketID
      venueDriverEventID
      venueDriverOrderID
      final
      email
      quantity
      first
      last
      code
      order_id
      access_token
      category
      subtotal_currency
      let_tax_amount_currency
      sales_tax_currency
      surcharge_currency
      total_currency
      service_charge_currency
      ticket_title
      event_date
      event_time
      event_name
      venue_title
      price
      is_package_ticket
      let_tax_disclaimer_msg
      ticket_type_label
      is_wristband_sale
      type
      venueDriverParentTicketSaleID
      venueDriverParentTicketSaleCode
      VenueDriverTicketSales {
        items {
          id
          ticketID
          eventID
          venueDriverTicketSaleID
          venueDriverTicketID
          venueDriverEventID
          venueDriverOrderID
          final
          email
          quantity
          first
          last
          code
          order_id
          access_token
          category
          subtotal_currency
          let_tax_amount_currency
          sales_tax_currency
          surcharge_currency
          total_currency
          service_charge_currency
          ticket_title
          event_date
          event_time
          event_name
          venue_title
          price
          is_package_ticket
          let_tax_disclaimer_msg
          ticket_type_label
          is_wristband_sale
          type
          venueDriverParentTicketSaleID
          venueDriverParentTicketSaleCode
          wristband_pickup_at
          wristband_pickup_venue_id
          wristband_pickup_event_id
          original_code
          isAssigned
          void
          voidedAt
          voidedReason
          refunded
          refundedSurcharge
          refundedAt
          refundedReason
          eventGroupId
          sourceSystem
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      VenueDriverTicketSaleRedemption {
        items {
          id
          ticketID
          ticketSaleID
          eventID
          venueDriverTicketSaleRedemptionID
          venueDriverTicketID
          venueDriverTicketSaleID
          venueDriverEventID
          void
          redeemedAt
          sourceSystem
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userTicketSaleRedemptionsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      wristband_pickup_at
      wristband_pickup_venue_id
      wristband_pickup_event_id
      original_code
      isAssigned
      void
      voidedAt
      voidedReason
      refunded
      refundedSurcharge
      refundedAt
      refundedReason
      eventGroupId
      sourceSystem
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateTicketSale = /* GraphQL */ `
  subscription OnUpdateTicketSale(
    $filter: ModelSubscriptionTicketSaleFilterInput
  ) {
    onUpdateTicketSale(filter: $filter) {
      id
      ticketID
      eventID
      venueDriverTicketSaleID
      venueDriverTicketID
      venueDriverEventID
      venueDriverOrderID
      final
      email
      quantity
      first
      last
      code
      order_id
      access_token
      category
      subtotal_currency
      let_tax_amount_currency
      sales_tax_currency
      surcharge_currency
      total_currency
      service_charge_currency
      ticket_title
      event_date
      event_time
      event_name
      venue_title
      price
      is_package_ticket
      let_tax_disclaimer_msg
      ticket_type_label
      is_wristband_sale
      type
      venueDriverParentTicketSaleID
      venueDriverParentTicketSaleCode
      VenueDriverTicketSales {
        items {
          id
          ticketID
          eventID
          venueDriverTicketSaleID
          venueDriverTicketID
          venueDriverEventID
          venueDriverOrderID
          final
          email
          quantity
          first
          last
          code
          order_id
          access_token
          category
          subtotal_currency
          let_tax_amount_currency
          sales_tax_currency
          surcharge_currency
          total_currency
          service_charge_currency
          ticket_title
          event_date
          event_time
          event_name
          venue_title
          price
          is_package_ticket
          let_tax_disclaimer_msg
          ticket_type_label
          is_wristband_sale
          type
          venueDriverParentTicketSaleID
          venueDriverParentTicketSaleCode
          wristband_pickup_at
          wristband_pickup_venue_id
          wristband_pickup_event_id
          original_code
          isAssigned
          void
          voidedAt
          voidedReason
          refunded
          refundedSurcharge
          refundedAt
          refundedReason
          eventGroupId
          sourceSystem
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      VenueDriverTicketSaleRedemption {
        items {
          id
          ticketID
          ticketSaleID
          eventID
          venueDriverTicketSaleRedemptionID
          venueDriverTicketID
          venueDriverTicketSaleID
          venueDriverEventID
          void
          redeemedAt
          sourceSystem
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userTicketSaleRedemptionsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      wristband_pickup_at
      wristband_pickup_venue_id
      wristband_pickup_event_id
      original_code
      isAssigned
      void
      voidedAt
      voidedReason
      refunded
      refundedSurcharge
      refundedAt
      refundedReason
      eventGroupId
      sourceSystem
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteTicketSale = /* GraphQL */ `
  subscription OnDeleteTicketSale(
    $filter: ModelSubscriptionTicketSaleFilterInput
  ) {
    onDeleteTicketSale(filter: $filter) {
      id
      ticketID
      eventID
      venueDriverTicketSaleID
      venueDriverTicketID
      venueDriverEventID
      venueDriverOrderID
      final
      email
      quantity
      first
      last
      code
      order_id
      access_token
      category
      subtotal_currency
      let_tax_amount_currency
      sales_tax_currency
      surcharge_currency
      total_currency
      service_charge_currency
      ticket_title
      event_date
      event_time
      event_name
      venue_title
      price
      is_package_ticket
      let_tax_disclaimer_msg
      ticket_type_label
      is_wristband_sale
      type
      venueDriverParentTicketSaleID
      venueDriverParentTicketSaleCode
      VenueDriverTicketSales {
        items {
          id
          ticketID
          eventID
          venueDriverTicketSaleID
          venueDriverTicketID
          venueDriverEventID
          venueDriverOrderID
          final
          email
          quantity
          first
          last
          code
          order_id
          access_token
          category
          subtotal_currency
          let_tax_amount_currency
          sales_tax_currency
          surcharge_currency
          total_currency
          service_charge_currency
          ticket_title
          event_date
          event_time
          event_name
          venue_title
          price
          is_package_ticket
          let_tax_disclaimer_msg
          ticket_type_label
          is_wristband_sale
          type
          venueDriverParentTicketSaleID
          venueDriverParentTicketSaleCode
          wristband_pickup_at
          wristband_pickup_venue_id
          wristband_pickup_event_id
          original_code
          isAssigned
          void
          voidedAt
          voidedReason
          refunded
          refundedSurcharge
          refundedAt
          refundedReason
          eventGroupId
          sourceSystem
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      VenueDriverTicketSaleRedemption {
        items {
          id
          ticketID
          ticketSaleID
          eventID
          venueDriverTicketSaleRedemptionID
          venueDriverTicketID
          venueDriverTicketSaleID
          venueDriverEventID
          void
          redeemedAt
          sourceSystem
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userTicketSaleRedemptionsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      wristband_pickup_at
      wristband_pickup_venue_id
      wristband_pickup_event_id
      original_code
      isAssigned
      void
      voidedAt
      voidedReason
      refunded
      refundedSurcharge
      refundedAt
      refundedReason
      eventGroupId
      sourceSystem
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateTicketSaleRedemption = /* GraphQL */ `
  subscription OnCreateTicketSaleRedemption(
    $filter: ModelSubscriptionTicketSaleRedemptionFilterInput
  ) {
    onCreateTicketSaleRedemption(filter: $filter) {
      id
      ticketID
      ticketSaleID
      eventID
      user {
        id
        cognitoID
        name
        email
        active
        settings
        roleGrants {
          nextToken
          startedAt
          __typename
        }
        ticketSaleRedemptions {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      venueDriverTicketSaleRedemptionID
      venueDriverTicketID
      venueDriverTicketSaleID
      venueDriverEventID
      void
      redeemedAt
      sourceSystem
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userTicketSaleRedemptionsId
      __typename
    }
  }
`;
export const onUpdateTicketSaleRedemption = /* GraphQL */ `
  subscription OnUpdateTicketSaleRedemption(
    $filter: ModelSubscriptionTicketSaleRedemptionFilterInput
  ) {
    onUpdateTicketSaleRedemption(filter: $filter) {
      id
      ticketID
      ticketSaleID
      eventID
      user {
        id
        cognitoID
        name
        email
        active
        settings
        roleGrants {
          nextToken
          startedAt
          __typename
        }
        ticketSaleRedemptions {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      venueDriverTicketSaleRedemptionID
      venueDriverTicketID
      venueDriverTicketSaleID
      venueDriverEventID
      void
      redeemedAt
      sourceSystem
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userTicketSaleRedemptionsId
      __typename
    }
  }
`;
export const onDeleteTicketSaleRedemption = /* GraphQL */ `
  subscription OnDeleteTicketSaleRedemption(
    $filter: ModelSubscriptionTicketSaleRedemptionFilterInput
  ) {
    onDeleteTicketSaleRedemption(filter: $filter) {
      id
      ticketID
      ticketSaleID
      eventID
      user {
        id
        cognitoID
        name
        email
        active
        settings
        roleGrants {
          nextToken
          startedAt
          __typename
        }
        ticketSaleRedemptions {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      venueDriverTicketSaleRedemptionID
      venueDriverTicketID
      venueDriverTicketSaleID
      venueDriverEventID
      void
      redeemedAt
      sourceSystem
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userTicketSaleRedemptionsId
      __typename
    }
  }
`;
export const onCreateTicketTier = /* GraphQL */ `
  subscription OnCreateTicketTier(
    $filter: ModelSubscriptionTicketTierFilterInput
  ) {
    onCreateTicketTier(filter: $filter) {
      id
      name
      tickets
      eventID
      venueID
      ticketSoldCountCondition
      ticketSoldInOneDayCondition
      ticketCategoryCondition
      daysBeforeEvent
      escalationTime
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateTicketTier = /* GraphQL */ `
  subscription OnUpdateTicketTier(
    $filter: ModelSubscriptionTicketTierFilterInput
  ) {
    onUpdateTicketTier(filter: $filter) {
      id
      name
      tickets
      eventID
      venueID
      ticketSoldCountCondition
      ticketSoldInOneDayCondition
      ticketCategoryCondition
      daysBeforeEvent
      escalationTime
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteTicketTier = /* GraphQL */ `
  subscription OnDeleteTicketTier(
    $filter: ModelSubscriptionTicketTierFilterInput
  ) {
    onDeleteTicketTier(filter: $filter) {
      id
      name
      tickets
      eventID
      venueID
      ticketSoldCountCondition
      ticketSoldInOneDayCondition
      ticketCategoryCondition
      daysBeforeEvent
      escalationTime
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateEventsGroup = /* GraphQL */ `
  subscription OnCreateEventsGroup(
    $filter: ModelSubscriptionEventsGroupFilterInput
  ) {
    onCreateEventsGroup(filter: $filter) {
      id
      name
      events {
        items {
          id
          venueID
          venueDriverEventID
          venueDriverVenueID
          title
          description
          time_zone
          date
          date_string
          open
          open_string
          close
          close_string
          closed_message
          VIP_URL
          currentTicketTierID
          ticketTierIDs
          stopSellingTicketsDate
          stopSellingTicketsTime
          tag
          website
          isEnableLoyaltyProgram
          isAllowedDollarsReward
          isAllowedPointsReward
          categories
          supportEmail
          organizerEmail
          servicePhone
          teaser
          isHideEndTime
          isHideStartTime
          template
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          eventsGroupEventsId
          eventVenueDriverHeadlinerArtistId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      venueID
      venueDriverPackageTicketID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateEventsGroup = /* GraphQL */ `
  subscription OnUpdateEventsGroup(
    $filter: ModelSubscriptionEventsGroupFilterInput
  ) {
    onUpdateEventsGroup(filter: $filter) {
      id
      name
      events {
        items {
          id
          venueID
          venueDriverEventID
          venueDriverVenueID
          title
          description
          time_zone
          date
          date_string
          open
          open_string
          close
          close_string
          closed_message
          VIP_URL
          currentTicketTierID
          ticketTierIDs
          stopSellingTicketsDate
          stopSellingTicketsTime
          tag
          website
          isEnableLoyaltyProgram
          isAllowedDollarsReward
          isAllowedPointsReward
          categories
          supportEmail
          organizerEmail
          servicePhone
          teaser
          isHideEndTime
          isHideStartTime
          template
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          eventsGroupEventsId
          eventVenueDriverHeadlinerArtistId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      venueID
      venueDriverPackageTicketID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteEventsGroup = /* GraphQL */ `
  subscription OnDeleteEventsGroup(
    $filter: ModelSubscriptionEventsGroupFilterInput
  ) {
    onDeleteEventsGroup(filter: $filter) {
      id
      name
      events {
        items {
          id
          venueID
          venueDriverEventID
          venueDriverVenueID
          title
          description
          time_zone
          date
          date_string
          open
          open_string
          close
          close_string
          closed_message
          VIP_URL
          currentTicketTierID
          ticketTierIDs
          stopSellingTicketsDate
          stopSellingTicketsTime
          tag
          website
          isEnableLoyaltyProgram
          isAllowedDollarsReward
          isAllowedPointsReward
          categories
          supportEmail
          organizerEmail
          servicePhone
          teaser
          isHideEndTime
          isHideStartTime
          template
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          eventsGroupEventsId
          eventVenueDriverHeadlinerArtistId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      venueID
      venueDriverPackageTicketID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser($filter: ModelSubscriptionUserFilterInput) {
    onCreateUser(filter: $filter) {
      id
      cognitoID
      name
      email
      active
      settings
      roleGrants {
        items {
          id
          userID
          roleID
          eventsGroups
          venues
          regions
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ticketSaleRedemptions {
        items {
          id
          ticketID
          ticketSaleID
          eventID
          venueDriverTicketSaleRedemptionID
          venueDriverTicketID
          venueDriverTicketSaleID
          venueDriverEventID
          void
          redeemedAt
          sourceSystem
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userTicketSaleRedemptionsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser($filter: ModelSubscriptionUserFilterInput) {
    onUpdateUser(filter: $filter) {
      id
      cognitoID
      name
      email
      active
      settings
      roleGrants {
        items {
          id
          userID
          roleID
          eventsGroups
          venues
          regions
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ticketSaleRedemptions {
        items {
          id
          ticketID
          ticketSaleID
          eventID
          venueDriverTicketSaleRedemptionID
          venueDriverTicketID
          venueDriverTicketSaleID
          venueDriverEventID
          void
          redeemedAt
          sourceSystem
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userTicketSaleRedemptionsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser($filter: ModelSubscriptionUserFilterInput) {
    onDeleteUser(filter: $filter) {
      id
      cognitoID
      name
      email
      active
      settings
      roleGrants {
        items {
          id
          userID
          roleID
          eventsGroups
          venues
          regions
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ticketSaleRedemptions {
        items {
          id
          ticketID
          ticketSaleID
          eventID
          venueDriverTicketSaleRedemptionID
          venueDriverTicketID
          venueDriverTicketSaleID
          venueDriverEventID
          void
          redeemedAt
          sourceSystem
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userTicketSaleRedemptionsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateRoleGrant = /* GraphQL */ `
  subscription OnCreateRoleGrant(
    $filter: ModelSubscriptionRoleGrantFilterInput
  ) {
    onCreateRoleGrant(filter: $filter) {
      id
      userID
      roleID
      role {
        id
        name
        perRegions
        perVenues
        perEventsGroups
        hasAllPermissions
        hasAccessToUsersManagement
        hasAccessToBookingsManagement
        canViewBookingFinancial
        canManageBookingStatusOption
        hasAccessToEventsManagement
        canManageEventOnEventsPage
        hasAccessToTicketsManagement
        canActivateEvent
        canActivateTicketSales
        hasAccessToVenueSettings
        hasAccessToRegionSettings
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      eventsGroups
      venues
      regions
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateRoleGrant = /* GraphQL */ `
  subscription OnUpdateRoleGrant(
    $filter: ModelSubscriptionRoleGrantFilterInput
  ) {
    onUpdateRoleGrant(filter: $filter) {
      id
      userID
      roleID
      role {
        id
        name
        perRegions
        perVenues
        perEventsGroups
        hasAllPermissions
        hasAccessToUsersManagement
        hasAccessToBookingsManagement
        canViewBookingFinancial
        canManageBookingStatusOption
        hasAccessToEventsManagement
        canManageEventOnEventsPage
        hasAccessToTicketsManagement
        canActivateEvent
        canActivateTicketSales
        hasAccessToVenueSettings
        hasAccessToRegionSettings
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      eventsGroups
      venues
      regions
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteRoleGrant = /* GraphQL */ `
  subscription OnDeleteRoleGrant(
    $filter: ModelSubscriptionRoleGrantFilterInput
  ) {
    onDeleteRoleGrant(filter: $filter) {
      id
      userID
      roleID
      role {
        id
        name
        perRegions
        perVenues
        perEventsGroups
        hasAllPermissions
        hasAccessToUsersManagement
        hasAccessToBookingsManagement
        canViewBookingFinancial
        canManageBookingStatusOption
        hasAccessToEventsManagement
        canManageEventOnEventsPage
        hasAccessToTicketsManagement
        canActivateEvent
        canActivateTicketSales
        hasAccessToVenueSettings
        hasAccessToRegionSettings
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      eventsGroups
      venues
      regions
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateRole = /* GraphQL */ `
  subscription OnCreateRole($filter: ModelSubscriptionRoleFilterInput) {
    onCreateRole(filter: $filter) {
      id
      name
      perRegions
      perVenues
      perEventsGroups
      hasAllPermissions
      hasAccessToUsersManagement
      hasAccessToBookingsManagement
      canViewBookingFinancial
      canManageBookingStatusOption
      hasAccessToEventsManagement
      canManageEventOnEventsPage
      hasAccessToTicketsManagement
      canActivateEvent
      canActivateTicketSales
      hasAccessToVenueSettings
      hasAccessToRegionSettings
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateRole = /* GraphQL */ `
  subscription OnUpdateRole($filter: ModelSubscriptionRoleFilterInput) {
    onUpdateRole(filter: $filter) {
      id
      name
      perRegions
      perVenues
      perEventsGroups
      hasAllPermissions
      hasAccessToUsersManagement
      hasAccessToBookingsManagement
      canViewBookingFinancial
      canManageBookingStatusOption
      hasAccessToEventsManagement
      canManageEventOnEventsPage
      hasAccessToTicketsManagement
      canActivateEvent
      canActivateTicketSales
      hasAccessToVenueSettings
      hasAccessToRegionSettings
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteRole = /* GraphQL */ `
  subscription OnDeleteRole($filter: ModelSubscriptionRoleFilterInput) {
    onDeleteRole(filter: $filter) {
      id
      name
      perRegions
      perVenues
      perEventsGroups
      hasAllPermissions
      hasAccessToUsersManagement
      hasAccessToBookingsManagement
      canViewBookingFinancial
      canManageBookingStatusOption
      hasAccessToEventsManagement
      canManageEventOnEventsPage
      hasAccessToTicketsManagement
      canActivateEvent
      canActivateTicketSales
      hasAccessToVenueSettings
      hasAccessToRegionSettings
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreatePrice = /* GraphQL */ `
  subscription OnCreatePrice($filter: ModelSubscriptionPriceFilterInput) {
    onCreatePrice(filter: $filter) {
      id
      name
      regionID
      price
      letTaxable
      letTaxAmount
      surcharge
      serviceCharge
      venueFee
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdatePrice = /* GraphQL */ `
  subscription OnUpdatePrice($filter: ModelSubscriptionPriceFilterInput) {
    onUpdatePrice(filter: $filter) {
      id
      name
      regionID
      price
      letTaxable
      letTaxAmount
      surcharge
      serviceCharge
      venueFee
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeletePrice = /* GraphQL */ `
  subscription OnDeletePrice($filter: ModelSubscriptionPriceFilterInput) {
    onDeletePrice(filter: $filter) {
      id
      name
      regionID
      price
      letTaxable
      letTaxAmount
      surcharge
      serviceCharge
      venueFee
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateOrder = /* GraphQL */ `
  subscription OnCreateOrder($filter: ModelSubscriptionOrderFilterInput) {
    onCreateOrder(filter: $filter) {
      id
      venueDriverOrderID
      VenueDriverTicketSales {
        items {
          id
          ticketID
          eventID
          venueDriverTicketSaleID
          venueDriverTicketID
          venueDriverEventID
          venueDriverOrderID
          final
          email
          quantity
          first
          last
          code
          order_id
          access_token
          category
          subtotal_currency
          let_tax_amount_currency
          sales_tax_currency
          surcharge_currency
          total_currency
          service_charge_currency
          ticket_title
          event_date
          event_time
          event_name
          venue_title
          price
          is_package_ticket
          let_tax_disclaimer_msg
          ticket_type_label
          is_wristband_sale
          type
          venueDriverParentTicketSaleID
          venueDriverParentTicketSaleCode
          wristband_pickup_at
          wristband_pickup_venue_id
          wristband_pickup_event_id
          original_code
          isAssigned
          void
          voidedAt
          voidedReason
          refunded
          refundedSurcharge
          refundedAt
          refundedReason
          eventGroupId
          sourceSystem
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ticketSales {
        items {
          id
          ticketID
          eventID
          venueDriverTicketSaleID
          venueDriverTicketID
          venueDriverEventID
          venueDriverOrderID
          final
          email
          quantity
          first
          last
          code
          order_id
          access_token
          category
          subtotal_currency
          let_tax_amount_currency
          sales_tax_currency
          surcharge_currency
          total_currency
          service_charge_currency
          ticket_title
          event_date
          event_time
          event_name
          venue_title
          price
          is_package_ticket
          let_tax_disclaimer_msg
          ticket_type_label
          is_wristband_sale
          type
          venueDriverParentTicketSaleID
          venueDriverParentTicketSaleCode
          wristband_pickup_at
          wristband_pickup_venue_id
          wristband_pickup_event_id
          original_code
          isAssigned
          void
          voidedAt
          voidedReason
          refunded
          refundedSurcharge
          refundedAt
          refundedReason
          eventGroupId
          sourceSystem
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      total
      order_id
      final
      final_at
      call_notes
      first
      last
      phone
      email
      call_clasification
      learned_from
      card_last_four_digits
      staff_id
      lastChangeStatusAt
      status
      address
      surcharge_currency
      sales_tax_currency
      service_charge_currency
      let_tax_amount_currency
      subtotal_currency
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateOrder = /* GraphQL */ `
  subscription OnUpdateOrder($filter: ModelSubscriptionOrderFilterInput) {
    onUpdateOrder(filter: $filter) {
      id
      venueDriverOrderID
      VenueDriverTicketSales {
        items {
          id
          ticketID
          eventID
          venueDriverTicketSaleID
          venueDriverTicketID
          venueDriverEventID
          venueDriverOrderID
          final
          email
          quantity
          first
          last
          code
          order_id
          access_token
          category
          subtotal_currency
          let_tax_amount_currency
          sales_tax_currency
          surcharge_currency
          total_currency
          service_charge_currency
          ticket_title
          event_date
          event_time
          event_name
          venue_title
          price
          is_package_ticket
          let_tax_disclaimer_msg
          ticket_type_label
          is_wristband_sale
          type
          venueDriverParentTicketSaleID
          venueDriverParentTicketSaleCode
          wristband_pickup_at
          wristband_pickup_venue_id
          wristband_pickup_event_id
          original_code
          isAssigned
          void
          voidedAt
          voidedReason
          refunded
          refundedSurcharge
          refundedAt
          refundedReason
          eventGroupId
          sourceSystem
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ticketSales {
        items {
          id
          ticketID
          eventID
          venueDriverTicketSaleID
          venueDriverTicketID
          venueDriverEventID
          venueDriverOrderID
          final
          email
          quantity
          first
          last
          code
          order_id
          access_token
          category
          subtotal_currency
          let_tax_amount_currency
          sales_tax_currency
          surcharge_currency
          total_currency
          service_charge_currency
          ticket_title
          event_date
          event_time
          event_name
          venue_title
          price
          is_package_ticket
          let_tax_disclaimer_msg
          ticket_type_label
          is_wristband_sale
          type
          venueDriverParentTicketSaleID
          venueDriverParentTicketSaleCode
          wristband_pickup_at
          wristband_pickup_venue_id
          wristband_pickup_event_id
          original_code
          isAssigned
          void
          voidedAt
          voidedReason
          refunded
          refundedSurcharge
          refundedAt
          refundedReason
          eventGroupId
          sourceSystem
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      total
      order_id
      final
      final_at
      call_notes
      first
      last
      phone
      email
      call_clasification
      learned_from
      card_last_four_digits
      staff_id
      lastChangeStatusAt
      status
      address
      surcharge_currency
      sales_tax_currency
      service_charge_currency
      let_tax_amount_currency
      subtotal_currency
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteOrder = /* GraphQL */ `
  subscription OnDeleteOrder($filter: ModelSubscriptionOrderFilterInput) {
    onDeleteOrder(filter: $filter) {
      id
      venueDriverOrderID
      VenueDriverTicketSales {
        items {
          id
          ticketID
          eventID
          venueDriverTicketSaleID
          venueDriverTicketID
          venueDriverEventID
          venueDriverOrderID
          final
          email
          quantity
          first
          last
          code
          order_id
          access_token
          category
          subtotal_currency
          let_tax_amount_currency
          sales_tax_currency
          surcharge_currency
          total_currency
          service_charge_currency
          ticket_title
          event_date
          event_time
          event_name
          venue_title
          price
          is_package_ticket
          let_tax_disclaimer_msg
          ticket_type_label
          is_wristband_sale
          type
          venueDriverParentTicketSaleID
          venueDriverParentTicketSaleCode
          wristband_pickup_at
          wristband_pickup_venue_id
          wristband_pickup_event_id
          original_code
          isAssigned
          void
          voidedAt
          voidedReason
          refunded
          refundedSurcharge
          refundedAt
          refundedReason
          eventGroupId
          sourceSystem
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ticketSales {
        items {
          id
          ticketID
          eventID
          venueDriverTicketSaleID
          venueDriverTicketID
          venueDriverEventID
          venueDriverOrderID
          final
          email
          quantity
          first
          last
          code
          order_id
          access_token
          category
          subtotal_currency
          let_tax_amount_currency
          sales_tax_currency
          surcharge_currency
          total_currency
          service_charge_currency
          ticket_title
          event_date
          event_time
          event_name
          venue_title
          price
          is_package_ticket
          let_tax_disclaimer_msg
          ticket_type_label
          is_wristband_sale
          type
          venueDriverParentTicketSaleID
          venueDriverParentTicketSaleCode
          wristband_pickup_at
          wristband_pickup_venue_id
          wristband_pickup_event_id
          original_code
          isAssigned
          void
          voidedAt
          voidedReason
          refunded
          refundedSurcharge
          refundedAt
          refundedReason
          eventGroupId
          sourceSystem
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      total
      order_id
      final
      final_at
      call_notes
      first
      last
      phone
      email
      call_clasification
      learned_from
      card_last_four_digits
      staff_id
      lastChangeStatusAt
      status
      address
      surcharge_currency
      sales_tax_currency
      service_charge_currency
      let_tax_amount_currency
      subtotal_currency
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
