import { useEffect, useState } from 'react';
import { Auth, Amplify } from 'aws-amplify';

import { mapErrorsToShowRequestFailedToast } from '../../components/Toasts';

import { useAuthenticate } from './useAuthenticate';
import { useSettings } from './useSettings';
import { defaultPermissions } from '../../context/AuthContext';
import { mapPermissionsAccordingToRoles } from '../../modelsServices/UserService';

import { getCurrentUserWithPermissions } from '../GraphQLHooks/fetchRequests/getCurrentUser';
import { useRouter } from "next/router";
import awsmobile from './../../aws-exports';

export const useAuth = () => {
  const { signIn, signOut } = useAuthenticate();
  const { onFiltersUpdate } = useSettings();
  const [user, setUser] = useState(null);

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const logout = () => {
      setIsAuthenticated(false);
      setIsLoading(false);
      signOut();
    };

    // if use azure ad is set to true, then we need to use the azure ad auth flow
    if (process.env.USE_AZURE_AD === 'true') {

      const updatedAwsConfig = {
        ...awsmobile,
        oauth: {
          region: 'us-east-1',
          domain: process.env.OAUTH_DOMAIN,
          scope: ['email','openid','aws.cognito.signin.user.admin'],
          redirectSignIn: process.env.OAUTH_REDIRECT_SIGN_IN_URL,
          redirectSignOut: process.env.OAUTH_REDIRECT_SIGN_OUT_URL,
          responseType: 'code'
        }
      }

      Amplify.configure(updatedAwsConfig);
    }

    const auth = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        console.log('current user', user)
        setUser(user);

        if (user && user.username)
          if (process.env.USE_AZURE_AD === 'false') {
            // Need a way to work locally without needing permissions.
            setIsAuthenticated(true);
            setIsLoading(false);
            signIn(user.attributes.name, user.id, 'admin', defaultPermissions());
          } else {
            getCurrentUserWithPermissions().then((res: any) => {
              if (res.errors) return mapErrorsToShowRequestFailedToast(res.errors);
              if (res.active) {
                const filters = JSON.parse(res?.settings)?.filters;
                if (filters) onFiltersUpdate(filters);

                const roles = res?.roleGrants.items.map((rg: any) => rg.role);
                const permissions = mapPermissionsAccordingToRoles(roles, defaultPermissions(), res?.roleGrants.items);

                setIsAuthenticated(true);
                setIsLoading(false);
                signIn(user.attributes.name, res.id, 'admin', permissions || defaultPermissions());
              } else logout();
            });
          }
      } catch (error) { logout() }
    };
    auth();
  }, []);

  return { isAuthenticated, isLoading, user };
};
