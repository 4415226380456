import React, { useCallback, useContext, useEffect } from 'react';
import styles from './VenuesModal.module.css';
import {Context} from '../../context/GlobalContext';
import { CustomModal, Typography, VenueCard, IVenueCardProps } from '@venuedriver/ui-driver';
import { IVenueModal } from '../../interfaces/global';
import { Venue } from '../../API';

export interface IVenuesModalProps extends IVenueModal {
  // eslint-disable-next-line no-unused-vars
  setOpened: (opened: boolean) => void;
  opened: boolean;
}

export const VenuesModal = (props: IVenuesModalProps) => {
  const {opened, setOpened, title, description, cards} = props;
  const {venue, updateVenue} = useContext(Context);

  const modalPadding = (scope = 'container') => {
    if(window.innerWidth > 1200) {
      return(scope === 'container' ? 40 : 80)
    } else if(window.innerWidth < 576) {
      return 16;
    } else {
      return 32;
    }
  }

  const handleVenueSelected = (name: string, id: string, venueDriverVenueID: number) => {
    updateVenue({name, id, venueDriverVenueID});
    setOpened(false);
  }

  return (
    <div className={styles.container}>
      <CustomModal
        opened={opened}
        setOpened={setOpened}
        withCloseButton={true}
        padding={modalPadding('container')}
        outerPadding={modalPadding('outer')}
      >
        <Typography
          element="h2"
          text={title}
          textAlign='center'
          css={{marginBottom: '12px'}}
          className={styles.title}
        />
        <Typography
          element="h5"
          text={description}
          textAlign='center'
          css={{marginBottom: '32px'}}
          className={styles.description}
        />

        <div className={styles.cards}>
          {/* Default card for All Venues */}
          <VenueCard
            currentVenue={venue.id === ''}
            id=''
            name="All Venues"
            venueDriverVenueID="0"
            onClick={handleVenueSelected}
          />
          {
            cards.map((venueCard: Venue) => (
              <VenueCard
                currentVenue={venue.id === venueCard.id}
                key={venueCard.id}
                id={venueCard.id}
                venueDriverVenueID={venueCard.venueDriverVenueID}
                city={venueCard.city}
                name={venueCard.name}
                image_URL={venueCard.image_URL}
                onClick={handleVenueSelected} />
            ))
          }
        </div>
      </CustomModal>
    </ div>
  );
};