import React, { createContext, useState, useEffect } from "react"
import { Venue } from "../API";

export interface props {
  children?: React.ReactNode;
}

export type IVenue = {
  name: string,
  id: string,
  venueDriverVenueID: number
}

export interface IContext {
  venue: IVenue;
  venues: number[];
  updateVenue: (e: any) => void;
  updateVenues: (e: any) => void;
}

export const emptyVenue: IVenue = {
  name: '',
  id: '',
  venueDriverVenueID: 0
}

const Context = createContext({
  venue: emptyVenue,
  venues: [] as Venue[], // this array is used to set venues by regions
  updateVenue: (e: any) => {},
  updateVenues: (e: any) => {}
});

function GlobalProvider({children}: props) {
  const [venue, setVenue] = useState<IVenue>(emptyVenue)
  const [venues, setVenues] = useState<Venue[]>([])

  const checkIfThereIsAVenue = () => {
    const storedVenueData = localStorage.getItem('storedVenue');
    const storedVenue = storedVenueData ? JSON.parse(storedVenueData) : {}

    if (storedVenue.venueDriverVenueID) {
      setVenue(storedVenue)
    }
  }

  useEffect(() => {
    checkIfThereIsAVenue()
  }, [])

  useEffect(() => {
    const { venueDriverVenueID } = venue;
    const isEmptyVenue = venueDriverVenueID === 0;

    if (isEmptyVenue) {
      checkIfThereIsAVenue()
      return
    }

    localStorage.setItem('storedVenue', JSON.stringify(venue))
  }, [venue])

  const updateVenue = (e:any) => {
    return setVenue(e);
  }

  const updateVenues = (e:any) => {
    return setVenues(e);
  }

  return (
    <Context.Provider value={{ venue, updateVenue, venues, updateVenues }}>
      {children}
    </Context.Provider>
  )
}

export { GlobalProvider, Context }
