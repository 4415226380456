import React from 'react';

import { AuthProvider } from './AuthContext';
import { DataStoreProvider } from './DataStoreContext';
import { GlobalProvider } from './GlobalContext';
import { SettingsProvider } from './SettingsContext';
import OrderContext from './OrderContext';

export const ContextProvider = ({ children }: any) => {
  return (
    <GlobalProvider>
      <AuthProvider>
        <DataStoreProvider>
          <SettingsProvider>
            <OrderContext>
              {children}
            </OrderContext>
          </SettingsProvider>
        </DataStoreProvider>
      </AuthProvider>
    </GlobalProvider>
  )
}
