import { useContext } from "react";
import { DataStoreContext, DataStoreContextProps } from '../../context/DataStoreContext';

export const useDataStore = (): DataStoreContextProps => {
  const context = useContext(DataStoreContext);
  if (!context) {
    throw new Error("useDataStore must be used within a DataStoreContext Provider");
  }
  return context;
}
