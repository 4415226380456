import React from 'react';
import { TicketDriver } from '../src/pages/Initial/TicketDriver';
import '../styles/globals.css';
import '../styles/calendar.scss';

import { Amplify, AuthModeStrategyType } from 'aws-amplify';
import { Authenticator } from "@aws-amplify/ui-react";
import '@aws-amplify/ui-react/styles.css';

import { AppProps } from 'next/app';
import { ContextProvider } from '../src/context/ContextProvider';

import { Settings, DateTime } from 'luxon';

// Here we set the default timezone
Settings.defaultZone = 'US/Pacific';
DateTime.local().zone;

// Amplify.Logger.LOG_LEVEL = "DEBUG"

// Cognito imports
import awsExports from './../src/aws-exports.js';
import Head from 'next/head';

Amplify.configure({
  ...awsExports, ssr: true,
  maxRecordsToSync: 150000,
  syncPageSize: 20000
});

function MyApp({ Component, pageProps }: AppProps) {

  const authProps = {
    hideDefault: true,
    amplifyConfig: awsExports,
  };

  return (
    <>
      <Head>
        <meta name="viewport" content = "width=100vh, initial-scale=1.0, minimum-scale=1, maximum-scale=1, user-scalable=no, minimal-ui" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <link rel="manifest" href="/manifest.json"></link>
      </Head>
      <Authenticator.Provider {...authProps}>
        <ContextProvider>
          <TicketDriver>
              <Component {...pageProps} />
          </TicketDriver>
        </ContextProvider>
      </Authenticator.Provider>
    </>
  )
}


export default MyApp;
