import { AuthState, defaultPermissions } from './AuthContext';
import { Permissions } from '../shared/Interfaces';

type AuthAction =
  | { type: "UPDATE_PERMISSIONS", payload: { permissions: Permissions } }
  | { type: "SIGN_IN", payload: { user: string; role: "admin" | "staff", permissions: Permissions } }
  | { type: "SIGN_OUT" };

export const authReducer = (state: AuthState, action: AuthAction): AuthState => {

  switch (action.type) {
    case "SIGN_IN":
      return {
        ...state,
        isAuthenticated: true,
        role: action.payload.role,
        user: action.payload.user,
        permissions: action.payload.permissions,
      };
    case "UPDATE_PERMISSIONS":
      return {
        ...state,
        permissions: action.payload.permissions,
      };
    case "SIGN_OUT":
      return {
        ...state,
        isAuthenticated: false,
        role: null,
        permissions: defaultPermissions(),
        user: '',
      }

    default:
      return state;
  }
}
