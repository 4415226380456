export const listVenuesByEventId = /* GraphQL */ `
  query ListVenuesByEventId(
    $filter: ModelVenueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVenues(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        venueDriverVenueID
        name
        address
        city
      }
      nextToken
      startedAt
    }
  }
`;

export const listTicketSaleByTerm = /* GraphQL */ `
  query ListTicketSaleByTerm(
    $filter: ModelTicketSaleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTicketSales(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id,
        code,
        email,
        first,
        last,
        ticketID,
        eventID,
        venueDriverTicketSaleID,
        venueDriverTicketID,
        venueDriverEventID,
        quantity,
        category,
        access_token,
        order_id,
        subtotal_currency,
        let_tax_amount_currency,
        sales_tax_currency,
        surcharge_currency,
        total_currency,
        service_charge_currency,
        ticket_title,
        event_date,
        event_time,
        event_name,
        venue_title,
        price,
        is_package_ticket,
        let_tax_disclaimer_msg,
        ticket_type_label,
        is_wristband_sale
      }
      nextToken
      startedAt
    }
  }
`;

export const searchTicketsSaleCheckinCount = /* GraphQL */ `
  query SearchTicketSaleCounter(
    $filter: SearchableTicketSaleFilterInput
    $limit: Int
  ) {
    searchTicketSales(filter: $filter, limit: $limit) {
      nextToken
      total
      items {
        venueDriverParentTicketSaleID
      }
    }
  }
`;

export const searchTicketsSaleRedemptionsCheckinCount = /* GraphQL */ `
  query SearchTicketSaleRedemptionsCheckinCount(
    $filter: SearchableTicketSaleRedemptionFilterInput
    $limit: Int
  ) {
    searchTicketSaleRedemptions(filter: $filter, limit: $limit) {
      nextToken
      total
      items {
        void
      }
    }
  }
`;

export const listRegionsAvatar = /* GraphQL */ `
  query ListRegionsAvatar {
    listRegions {
      items {
        id
        venueDriverRegionID
        name
        _deleted
      }
    }
  }
`;

export const getCurrentUser = /* GraphQL */ `
  query GetCurrentUser {
    getCurrentUser {
      id
      cognitoID
      name
      email
      active
      settings
      roleGrants (filter: { _deleted: { ne: true } }) {
        nextToken
        startedAt
        items {
          roleID
          role {
            _version
            canActivateEvent
            canActivateTicketSales
            _lastChangedAt
            _deleted
            canManageBookingStatusOption
            canManageEventOnEventsPage
            canViewBookingFinancial
            createdAt
            hasAccessToBookingsManagement
            hasAccessToEventsManagement
            hasAccessToRegionSettings
            hasAccessToTicketsManagement
            hasAccessToUsersManagement
            hasAccessToVenueSettings
            hasAllPermissions
            id
            name
            perEventsGroups
            perRegions
            perVenues
            updatedAt
          }
          regions
          id
          eventsGroups
          createdAt
          updatedAt
          userID
          venues
          _version
          _lastChangedAt
          _deleted
        }
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const listEvents = /* GraphQL */ `
  query ListEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        venueID
        venueDriverEventID
        venueDriverVenueID
        title
        description
        time_zone
        date
        date_string
        open
        open_string
        close
        close_string
        closed_message
        VIP_URL
        currentTicketTierID
        ticketTierIDs
        stopSellingTicketsDate
        stopSellingTicketsTime
        tag
        website
        isEnableLoyaltyProgram
        isAllowedDollarsReward
        isAllowedPointsReward
        categories
        supportEmail
        organizerEmail
        servicePhone
        teaser
        isHideEndTime
        isHideStartTime
        images {
          social
          calendar
        }
        template
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        eventsGroupEventsId
        eventVenueDriverHeadlinerArtistId
        __typename
        Bookings {
          items {
            startTime
            public
            id
            eventID
            endTime
            dateID
            announcementDate
            _version
            _deleted
            status {
              name
            }
          }
        }
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
