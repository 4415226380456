import React, { ReactNode, useContext, useEffect, useState } from "react";
import { Menu, Header, useMediaQuery } from "@venuedriver/ui-driver";
import styles from "./AdminTemplate.module.css";
import classNames from "classnames";

import { useRouter } from "next/router";
import { VenuesModal } from "../../../components/VenuesFilterModal/VenuesModal";

// Data
import { useMenuItems } from "./useMenuItems";
import { Context } from "../../../context/GlobalContext";
import { useVenueByRegion } from "../../../hooks/GraphQLHooks/useVenueByRegion";
import { useDataStore } from "../../../hooks/contextHooks/useDataStore";
import { useAuthenticate } from '../../../hooks/contextHooks/useAuthenticate';
import { getInitialsFromEmail } from "../../../utils/generalUtils";

interface AdminTemplateProps {
  children: ReactNode;
  user?: any;
}

const AdminTemplate = ({ children, user }: AdminTemplateProps) => {
  const router = useRouter();
  const { logout } = useDataStore()
  const { permissionsChecks: { hasAccessGlobalPerVenues } } = useAuthenticate();
  const { venue: { name: selectedVenue } } = useContext(Context);

  const initialsFromEmail = getInitialsFromEmail(user?.attributes?.email);

  const isTablet = useMediaQuery("(min-width: 576px) and (max-width: 1200px)");
  const isMobile = useMediaQuery("(max-width: 575px)");

  const [isMenuCollapsed, setIsMenuCollapsed] = useState<boolean>(false);
  const [isMenuShowed, setIsMenuShowed] = useState<boolean>(true);
  const [selectedPage, setSelectedPage] = useState<string>("Ticket Driver");
  const [selectedPath, setSelectedPath] = useState<string>("/");

  const { menuItems, supportItems } = useMenuItems();
  const listVenues = useVenueByRegion();

  // Venues Filter button
  const [showModal, setShowModal] = useState<boolean>(false);

  const { pathname } = router;

  useEffect(() => {
    if (isMobile) {
      setIsMenuShowed(false);
    } else {
      setIsMenuShowed(true);
    }
  }, [isMobile]);

  const handleCollapseMenu = (): void => {
    setIsMenuCollapsed(!isMenuCollapsed);
  };

  const handleShowMenu = (): void => {
    setIsMenuShowed(!isMenuShowed);
  };

  const handleModal = (): void => {
    setShowModal(!showModal);
  };

  const handleSignOut = async (): Promise<void> => {
    logout();
  };

  useEffect(() => {
    setSelectedPath(pathname);
  }, [pathname]);

  useEffect(() => {
    isTablet ? setIsMenuCollapsed(true) : setIsMenuCollapsed(false);
  }, [isTablet]);

  const appContainerClasses = classNames({
    [styles.appContainer]: true,
    [styles["with-menu-expanded"]]: isMenuShowed && !isMenuCollapsed,
    [styles["with-menu-collapsed"]]: isMenuShowed && isMenuCollapsed,
    [styles["without-menu"]]: !isMenuShowed,
    [styles["header-mobile"]]: isMobile,
    [styles["header-tablet"]]: isTablet,
    [styles["header-desktop"]]: !isMobile && !isTablet,
  });

  return (
    <div className={styles.container}>
      <div className={styles.menuAndAppContainer}>
        <div className={styles.menu}>
          <Menu
            collapsed={isMenuCollapsed}
            setCollapsed={handleCollapseMenu}
            isMenuShowed={isMenuShowed}
            setIsMenuShowed={handleShowMenu}
            menuItems={menuItems}
            supportItems={supportItems}
            selectedItem={selectedPath}
            selectPage={setSelectedPage}
          />
        </div>
        <div className={styles.headerAndAppContainer}>
          <div className={styles.header}>
            <Header
              initialsName={initialsFromEmail}
              isMenuShowed={isMenuShowed}
              title={selectedPage}
              setIsMenuShowed={handleShowMenu}
              selectedVenuesFilter={selectedVenue || "All Venues"}
              onVenuesFilterClick={handleModal}
              onLogOutClick={handleSignOut}
            />
          </div>
          <div id="app-container" className={appContainerClasses}>
            {children}
          </div>
        </div>
      </div>
      <div className={showModal ? styles.modal : styles["modal-not-shown"]}>
        <VenuesModal
          title="Choose a Venue"
          description="You will see the events of the selected venue"
          cards={hasAccessGlobalPerVenues(listVenues)}
          setOpened={setShowModal}
          opened={showModal}
        />
      </div>
    </div>
  );
};

export default AdminTemplate;
