export const getInitialsFromEmail = (email: string): string => {
  if (!email) return '';

  const [fullName, domain] = email.split('@');
  if (!fullName || !domain) return '';

  const nameParts = fullName.split('.');

  const initials = nameParts.filter(namePart => !!namePart)
    .map(namePart => namePart[0].toUpperCase())
    .join('');

  return initials;
};
